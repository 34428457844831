import { checkRolesWithLevel } from "components/Common/Helpers";
import { checkRoles } from "components/Common/Helpers";
import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import InspectionCheck from "./InspectionCheck";

const Commentary = ({ onData, otherData, propWaivedResult }) => {
    let [commentaryData, setCommentaryData] = useState([]);

    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "commentary") {
                handleCommentaryData(onData.data);
            }
        }
        //eslint-disable-next-line
    }, [onData]);

    let handleCommentaryData = (data) => {
        let other_details = JSON.parse(data.other_details);
        if (other_details) {
            setCommentaryData(other_details);
        }
        otherData.category_type = onData.type;
    };

    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
    }

    return (
        <>
            {otherData.category_type === "commentary" &&
                <Col xl="12">
                    <p className="h3 float-left w-100"><u>Commentary</u></p>
                    <InspectionCheck otherData={otherData} />

                    {commentaryData && (commentaryData.my_commentary || commentaryData.final_commentary) ? (
                        <>
                            <Row>
                                <Col lg="6">
                                    <p className="h3">My Commentary</p>
                                </Col>
                                <Col lg="6">
                                    <p className="h3">Final Commentary</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <label>{commentaryData.my_commentary}</label>
                                </Col>
                                <Col lg="6">
                                    <label>{commentaryData.final_commentary}</label>
                                </Col>
                            </Row>
                        </>
                    ) : commentaryData && (commentaryData.my_market_commentary || commentaryData.my_subject_property_commentary || commentaryData.my_budget_commentary || commentaryData.my_valuation_commentary || commentaryData.my_recommendations_commentary || commentaryData.final_market_commentary || commentaryData.final_subject_property_commentary || commentaryData.final_budget_commentary || commentaryData.final_valuation_commentary || commentaryData.final_recommendations_commentary) ? (
                        <>
                            <Row>
                                <Col lg="3">
                                    <p className="h3"></p>
                                </Col>
                                <Col lg="4">
                                    <p className="h3">My Commentary</p>
                                </Col>
                                <Col lg="4">
                                    <p className="h3">Reviewer Commentry</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="3">
                                    <p className="h3">Market :</p>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.my_market_commentary}</label>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.final_market_commentary}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="3">
                                    <p className="h3">Subject Property :</p>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.my_subject_property_commentary}</label>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.final_subject_property_commentary}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="3">
                                    <p className="h3">Budget :</p>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.my_budget_commentary}</label>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.final_budget_commentary}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="3">
                                    <p className="h3">Valuation :</p>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.my_valuation_commentary}</label>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.final_valuation_commentary}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="3">
                                    <p className="h3">Recommendations :</p>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.my_recommendations_commentary}</label>
                                </Col>
                                <Col lg="4">
                                    <label>{commentaryData.final_recommendations_commentary}</label>
                                </Col>
                            </Row>
                        </>
                    ) :
                        (
                            <h2 className="not-authorized-msg">Commentary not submitted</h2>
                        )}
                    <hr />
                    <p className="h3 float-left w-100"><u>Internal Notes</u></p>
                    {commentaryData && (commentaryData.my_comment || commentaryData.final_comment) ? (
                        <>
                            <Row>
                                <Col lg="6">
                                    <p className="h3">My Comments</p>
                                </Col>
                                <Col lg="6">
                                    <p className="h3">Reviewer Comments</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <label>{commentaryData.my_comment}</label>
                                </Col>
                                <Col lg="6">
                                    <label>{commentaryData.final_comment}</label>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <h2 className="not-authorized-msg">Comment not submitted</h2>
                    )}
                    {(checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col>
            }
        </>
    );
}

export default Commentary;