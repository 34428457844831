import { createSlice } from '@reduxjs/toolkit'
import { defaultValues } from './ValDetailsAPIHelper';

const initialStates = {
    bestAIVComps: {},
    conditionLevels: [
        {
            "min": 212,
            "median": 252,
            "max": 272,
            "minLabel": '$212k',
            "medianLabel": '$252k',
            "maxLabel": '$272k',
        },
        {
            "min": 194,
            "median": 248,
            "max": 286,
            "minLabel": '$194k',
            "medianLabel": '$248k',
            "maxLabel": '$286k',
        },
        {
            "min": 0,
            "median": 0,
            "max": 0,
            "minLabel": '',
            "medianLabel": '',
            "maxLabel": '',
        },
        {
            "min": 175,
            "median": 200,
            "max": 215,
            "minLabel": '$175k',
            "medianLabel": '$200k',
            "maxLabel": '$215k',
        }, {
            "min": 0,
            "median": 0,
            "max": 0,
            "minLabel": '',
            "medianLabel": '',
            "maxLabel": '',
        }, {
            "min": 175,
            "median": 200,
            "max": 215,
            "minLabel": '$175k',
            "medianLabel": '$200k',
            "maxLabel": '$215k',
        },
        {
            "min": 175,
            "median": 200,
            "max": 215,
            "minLabel": '$175k',
            "medianLabel": '$200k',
            "maxLabel": '$215k',
        },
        {
            "min": 175,
            "median": 200,
            "max": 215,
            "minLabel": '$175k',
            "medianLabel": '$200k',
            "maxLabel": '$215k',
        }
    ],
    type: 'aiv',

    compoListErr: false,
    compoListFetching: true,
    comps: [],
    subjectPropertyComp: {},
    compMarkers: [],

    systemValuesErr: false,
    systemValuesFetching: true,
    systemValues: [],

    compLocationHistoryErr: false,
    compLocationHistoryFetching: true,
    compLocationHistory: [],

    propertyFilesFetchingErr: false,
    propertyFilesFetchingFetching: true,
    propertyFilesFetching: [],

    confidenceScoreDataErr: false,
    confidenceScoreDataFetching: true,
    confidenceScoreData: {},

    propertyComparisonDataErr: false,
    propertyComparisonDataFetching: true,
    propertyComparisonData: {},

    compFilterParams: { ...defaultValues().compFilterParams },
    filteredComps: [],
    noOfFilterApplied: 0,
    noOfTotalComps: 0,
    noOfFilteredComps: 0,

    adjustmentFactors: {
        sliderValue: defaultValues().adjustmentFactorSliderValue,
        adjustmentValue: {}
    }
};

export const ValDetailsReducer = createSlice({
    name: 'ShowHideSpinner',
    initialState: { ...initialStates },
    reducers: {
        showSpinner: (state, params) => {
            state.currentStat = params.payload;
        },
        setValDetailsData: (state, data) => {
            data?.payload?.forEach(element => {
                state[element.key] = element.value
            });
        },
        setValDetailsFilterParams: (state, data) => {
            data?.payload?.forEach(element => {
                state.compFilterParams[element.key] = element.value
            });
        },
        resetForm: (state) => {
            for (const key in { ...initialStates }) {
                if (initialStates.hasOwnProperty(key)) {
                    state[key] = initialStates[key]
                }
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { showSpinner, setValDetailsData, setValDetailsFilterParams, resetForm } = ValDetailsReducer.actions

export default ValDetailsReducer.reducer