import { number_format } from "components/Common/Helpers";
import React, { useEffect, useState } from "react";
import { Table, Row, Col } from "reactstrap";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import ColumnChartComp from "./ColumnChart";
import LineChartComp from "./LineChart";
import { handleTableHeader } from "./AnalysisComparisonHelper";
import InspectionCheck from "./InspectionCheck";
import { checkRoles } from "components/Common/Helpers";
import { checkRolesWithLevel } from "components/Common/Helpers";
import * as moment from "moment";
import { getConditionRatingColor } from "components/Common/Helpers";

const Values = ({ onData, otherData, propWaivedResult }) => {
    const [valueCurveData, setValueCurveData] = useState(null);
    const [asIsValue, setAsIsValue] = useState(null);
    const [targetArv, setTargetArv] = useState(null);
    const [recommendedArv, setRecommendedArv] = useState(null);
    const [targetARVData, setTargetARVData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [extraColumns, setExtraColumns] = useState({});
    let [update, setUpdate] = useState(moment());

    let tmpTargetData = [];

    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "values") {
                let valuesData = onData.data ?? {};
                handleValueCurveData(valuesData.valueCurve);
                handleAsIsValue(valuesData.asIsValue);
                handleTargetArv(valuesData.targetArv);
                handleRecommendedArv(valuesData.recommendedArv);
            }
        }
        //eslint-disable-next-line   
    }, [onData]);

    useEffect(() => {

        //eslint-disable-next-line   
    }, [update]);

    let handleValueCurveData = (data) => {
        let details = JSON.parse(data.details);
        let other_details = JSON.parse(data.other_details);
        let min_diffrence = 0;
        let partial_diffrence = 0;
        let full_diffrence = 0;
        let valueAdd_diffrence = 0;
        let min_percentage = 0;
        let partial_percentage = 0;
        let full_percentage = 0;
        let valueAdd_percentage = 0;
        let condition_rating = other_details.condition_rating;
        let condition_rating_result = getConditionRatingColor(condition_rating);
        let original_condition_rating = condition_rating_result.condition;

        if (details) {
            min_diffrence = Math.abs(details.final_ending_value_min - details.my_ending_value_min)
            partial_diffrence = Math.abs(details.final_ending_value_partial - details.my_ending_value_partial)
            full_diffrence = Math.abs(details.final_ending_value_full - details.my_ending_value_full)
            valueAdd_diffrence = Math.abs(details.final_ending_value_value_add - details.my_ending_value_value_add)

            details.min_diffrence = number_format(min_diffrence);
            details.partial_diffrence = number_format(partial_diffrence);
            details.full_diffrence = number_format(full_diffrence);
            details.valueAdd_diffrence = number_format(valueAdd_diffrence);

            min_percentage = (min_diffrence / details.final_ending_value_min) * 100;
            partial_percentage = (partial_diffrence / details.final_ending_value_partial) * 100;
            full_percentage = (full_diffrence / details.final_ending_value_full) * 100;
            valueAdd_percentage = (valueAdd_diffrence / details.final_ending_value_value_add) * 100;

            details.min_percentage = number_format(min_percentage, 2);
            details.partial_percentage = number_format(partial_percentage, 2);
            details.full_percentage = number_format(full_percentage, 2);
            details.valueAdd_percentage = number_format(valueAdd_percentage, 2);
        }
        details.grade = data.grade;
        details.condition_rating = condition_rating;
        details.waived = parseInt(data.waived) === 1 ? "Yes" : "No";
        setValueCurveData(details);
        otherData.category_type = onData.type;

        // Prepare Chart Data
        let chartDataArray = [
            {
                name: original_condition_rating !== 1 ? "Min ARV" : "Low",
                final: details.final_ending_value_min,
                submitted: details.my_ending_value_min,
            },
            {
                name: original_condition_rating !== 1 ? "Partial ARV" : "Med",
                final: details.final_ending_value_partial,
                submitted: details.my_ending_value_partial,
            },
            {
                name: original_condition_rating !== 1 ? "Full ARV" : "High",
                final: details.final_ending_value_full,
                submitted: details.my_ending_value_full,
            }
        ];

        if (details.final_ending_value_value_add && details.my_ending_value_value_add) {
            setExtraColumns(extraColumns => ({ ...extraColumns, valueAdd: true }));
            chartDataArray.push({
                name: "Value Add",
                final: details.final_ending_value_value_add,
                submitted: details.my_ending_value_value_add,
            });
        }
        setChartData(chartDataArray);
    }

    let handleAsIsValue = (data) => {
        let details = JSON.parse(data.details);
        let asIs_diffrence = Math.abs(details.final_ending_value_as_is - details.my_ending_value_as_is)
        details.asIs_diffrence = number_format(asIs_diffrence);

        let asIs_percentage = (asIs_diffrence / details.final_ending_value_as_is) * 100;
        details.asIs_percentage = number_format(asIs_percentage, 2);

        details.grade = data.grade;
        details.weight = data.weight;
        details.waived = parseInt(data.waived) === 1 ? "Yes" : "No";
        setAsIsValue(details);

        // Prepare the Column chart data
        tmpTargetData.push({
            name: "As Is Value",
            final: details.final_ending_value_as_is,
            submitted: details.my_ending_value_as_is,
        });
        setTargetARVData(tmpTargetData);
    }
    let handleRecommendedArv = (data) => {
        let details = JSON.parse(data.details);
        let other_details = JSON.parse(data.other_details);
        let exclude = other_details.exclude;
        let recommendExclude = parseInt(exclude) === 1 ? false : true;
        if ((details.my_recommended_arv || details.final_recommended_arv) && recommendExclude) {
            setExtraColumns(extraColumns => ({ ...extraColumns, recommendedArv: recommendExclude }));

            let recommendedArv_diffrence = Math.abs(details.final_recommended_arv - details.my_recommended_arv)
            details.recommendedArv_diffrence = number_format(recommendedArv_diffrence);

            let recommendedArv_percentage = (recommendedArv_diffrence / details.final_recommended_arv) * 100;
            details.recommendedArv_percentage = number_format(recommendedArv_percentage, 2);

            details.grade = data.grade;
            details.waived_exclude = other_details.exclude;
            details.waived = parseInt(data.waived) === 1 ? "Yes" : "No";
            details.weight = data.weight;
            setRecommendedArv(details);

            // Prepare the Column chart data
            tmpTargetData.push({
                name: "Recommended ARV",
                final: details.final_recommended_arv,
                submitted: details.my_recommended_arv,
            });
            setTargetARVData(tmpTargetData);
        }
    }
    let handleTargetArv = (data) => {
        let details = JSON.parse(data.details);
        let other_details = JSON.parse(data.other_details);
        let exclude = other_details.exclude;
        let targetArvExclude = parseInt(exclude) === 1 ? false : true;
        if ((details.my_target_arv || details.final_target_arv) && targetArvExclude) {

            setExtraColumns(extraColumns => ({ ...extraColumns, targetArv: targetArvExclude }));

            let targetArv_diffrence = Math.abs(details.final_target_arv - details.my_target_arv)
            details.targetArv_diffrence = number_format(targetArv_diffrence);

            let targetArv_percentage = (targetArv_diffrence / details.final_target_arv) * 100;
            details.targetArv_percentage = number_format(targetArv_percentage, 2);

            details.grade = data.grade;
            details.waived_exclude = other_details.exclude;
            details.waived = parseInt(data.waived) === 1 ? "Yes" : "No";
            details.weight = data.weight;
            setTargetArv(details);
            tmpTargetData.push({
                name: "Target ARV",
                final: details.final_target_arv,
                submitted: details.my_target_arv,
            });
            setTargetARVData(tmpTargetData);
        }
    }
    let calculateAvgGrade = () => {
        let totalWeight = 0;
        let valueCurveGrades = 0;
        if (asIsValue && asIsValue?.waived !== "Yes" && parseFloat(asIsValue?.grade) > 0) {
            valueCurveGrades += (parseFloat(asIsValue?.grade) * asIsValue.weight);
            totalWeight += asIsValue.weight;
        }
        if (recommendedArv && recommendedArv?.waived_exclude !== 1 && parseFloat(recommendedArv?.grade) > 0) {
            valueCurveGrades += (parseFloat(recommendedArv?.grade) * recommendedArv.weight);
            totalWeight += recommendedArv.weight;
        }
        if (targetArv && targetArv?.waived_exclude !== 1 && parseFloat(targetArv?.grade) > 0) {
            valueCurveGrades += (parseFloat(targetArv?.grade) * targetArv.weight);
            totalWeight += targetArv.weight;
        }
        let avg = (valueCurveGrades / totalWeight); // average(gradeArray);
        return number_format(Math.abs(avg), 2);
    }
    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
        let performance_categories = data.performance_categories;
        var valuesResult = performance_categories.filter(obj => {
            return obj.slug === 'values';
        })
        valuesResult = valuesResult[0];
        let waived = parseInt(valuesResult.waived) === 1 ? "Yes" : "No";
        asIsValue.waived = waived;
        setAsIsValue(asIsValue);
        if (targetArv) {
            targetArv.waived = waived;
            setTargetArv(targetArv);
        }
        if (recommendedArv) {
            recommendedArv.waived = waived;
            setTargetArv(recommendedArv);
        }
        if (valueCurveData) {
            valueCurveData.waived = waived;
            setValueCurveData(valueCurveData);
        }
        setUpdate(moment());
    }

    const valueCurveTable = (
        <Table striped>
            <thead>
                <tr>
                    <th>Value Curve</th>
                    {valueCurveData && handleTableHeader(valueCurveData.condition_rating)}
                    {extraColumns.valueAdd &&
                        <th>Value Add</th>
                    }
                </tr>
            </thead>
            <tbody>
                {valueCurveData ? (
                    <>
                        <tr>
                            <td>My Values</td>
                            <td>${number_format(valueCurveData.my_ending_value_min)}</td>
                            <td>${number_format(valueCurveData.my_ending_value_partial)}</td>
                            <td>${number_format(valueCurveData.my_ending_value_full)}</td>
                            {extraColumns.valueAdd &&
                                <td>${number_format(valueCurveData.my_ending_value_value_add)}</td>
                            }
                        </tr>
                        <tr>
                            <td>Final Values</td>
                            <td>${number_format(valueCurveData.final_ending_value_min)}</td>
                            <td>${number_format(valueCurveData.final_ending_value_partial)}</td>
                            <td>${number_format(valueCurveData.final_ending_value_full)}</td>
                            {extraColumns.valueAdd &&
                                <td>${number_format(valueCurveData.final_ending_value_value_add)}</td>
                            }
                        </tr>
                        <tr>
                            <td>Difference</td>
                            <td>${valueCurveData.min_diffrence}</td>
                            <td>${valueCurveData.partial_diffrence}</td>
                            <td>${valueCurveData.full_diffrence}</td>
                            {extraColumns.valueAdd &&
                                <td>${valueCurveData.valueAdd_diffrence}</td>
                            }
                        </tr>
                        <tr>
                            <td>Percentage</td>
                            <td>{valueCurveData.min_percentage}%</td>
                            <td>{valueCurveData.partial_percentage}%</td>
                            <td>{valueCurveData.full_percentage}%</td>
                            {extraColumns.valueAdd &&
                                <td>{valueCurveData.valueAdd_percentage}%</td>
                            }
                        </tr>
                        <tr>
                            <td>Grade</td>
                            <td>{valueCurveData.min_grade}</td>
                            <td>{valueCurveData.partial_grade}</td>
                            <td>{valueCurveData.full_grade}</td>
                            {extraColumns.valueAdd &&
                                <td>{valueCurveData.valueAdd_grade}</td>
                            }
                        </tr>
                        <tr>
                            <td>Waived</td>
                            <td>{valueCurveData.waived}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            {extraColumns.valueAdd &&
                                <td>&nbsp;</td>
                            }
                        </tr>
                        <tr>
                            <td>Final Grade</td>
                            <td>{number_format(valueCurveData.grade, 2)}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            {extraColumns.valueAdd &&
                                <td>&nbsp;</td>
                            }
                        </tr>
                    </>
                ) : (
                    <tr>
                        <td colSpan={5}>Insufficient data</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );

    const targetARVTable = (
        <Table striped>
            <thead>
                <tr>
                    <th>Key Values</th>
                    <th>AIV</th>
                    {extraColumns.targetArv &&
                        <th>Target ARV</th>
                    }
                    {extraColumns.recommendedArv &&
                        <th>Recommended</th>
                    }
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>My Values</td>
                    <td>${asIsValue && number_format(asIsValue.my_ending_value_as_is, 2)}</td>
                    {extraColumns.targetArv &&
                        <td>${targetArv && number_format(targetArv.my_target_arv, 2)}</td>
                    }
                    {extraColumns.recommendedArv &&
                        <td>${recommendedArv && number_format(recommendedArv.my_recommended_arv, 2)}</td>
                    }
                </tr>
                <tr>
                    <td>Final Values</td>
                    <td>${asIsValue && number_format(asIsValue.final_ending_value_as_is, 2)}</td>
                    {extraColumns.targetArv &&
                        <td>${targetArv && number_format(targetArv.final_target_arv, 2)}</td>
                    }
                    {extraColumns.recommendedArv &&
                        <td>${recommendedArv && number_format(recommendedArv.final_recommended_arv, 2)}</td>
                    }
                </tr>
                <tr>
                    <td>Difference</td>
                    <td>${asIsValue && asIsValue.asIs_diffrence}</td>
                    {extraColumns.targetArv &&
                        <td>${targetArv && targetArv.targetArv_diffrence}</td>
                    }
                    {extraColumns.recommendedArv &&
                        <td>${recommendedArv && recommendedArv.recommendedArv_diffrence}</td>
                    }
                </tr>
                <tr>
                    <td>Percentage</td>
                    <td>{asIsValue && asIsValue.asIs_percentage}%</td>
                    {extraColumns.targetArv &&
                        <td>{targetArv && targetArv.targetArv_percentage}%</td>
                    }
                    {extraColumns.recommendedArv &&
                        <td>{recommendedArv && recommendedArv.recommendedArv_percentage}%</td>
                    }
                </tr>
                <tr>
                    <td>Grade</td>
                    <td>{asIsValue && number_format(asIsValue.grade, 2)}</td>
                    {extraColumns.targetArv &&
                        <td>{targetArv && number_format(targetArv.grade, 2)}</td>
                    }
                    {extraColumns.recommendedArv &&
                        <td>{recommendedArv && number_format(recommendedArv.grade, 2)}</td>
                    }
                </tr>
                <tr>
                    <td>Waived</td>
                    <td>{asIsValue && asIsValue.waived}</td>
                    {extraColumns.targetArv &&
                        <td>{targetArv && targetArv.waived}</td>
                    }
                    {extraColumns.recommendedArv &&
                        <td>{recommendedArv && recommendedArv.waived}</td>
                    }
                </tr>
                <tr>
                    <td>Final Grade</td>
                    <td>{calculateAvgGrade()}</td>
                    {extraColumns.targetArv &&
                        <td>&nbsp;</td>
                    }
                    {extraColumns.recommendedArv &&
                        <td>&nbsp;</td>
                    }
                </tr>

            </tbody>
        </Table>
    );
    return (
        <>
            {otherData.category_type === "values" &&
                <Col xl="12" className="pl-0 value-curve-section" >
                    <Row>
                        <p className="h3 float-left w-100"><u>Values</u></p>
                        <InspectionCheck otherData={otherData} />
                    </Row>
                    {targetARVData.length > 0 &&
                        <Row>
                            <Col lg="5" xl="5" className="chart-col">
                                <ColumnChartComp chartData={targetARVData} />
                            </Col>
                            <Col xl="7">
                                {targetARVTable}
                            </Col>
                        </Row>
                    }
                    <hr />
                    <Row>
                        <Col lg="5" xl="5" className="chart-col" >
                            <LineChartComp chartData={chartData} />
                        </Col>
                        <Col xl="7">
                            {valueCurveTable}
                        </Col>
                    </Row>
                    {(checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col >
            }
        </>
    );

}
export default Values;