
import React, { useEffect, useState } from "react";

import Header from "components/Headers/Header.js";

import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { AvForm, AvGroup } from "availity-reactstrap-validation";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as moment from "moment";
import { PostData } from "services/Api.service";
import { toast } from "react-toastify";
import { sendExceptionEmail } from "components/Common/Helpers";
import "../FloodCertsRevenue/revenue.css";
import { priceNumber } from "components/Common/Helpers";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import TableSkeleton from "components/SkeletonUI/TableSkeleton";
moment.tz.setDefault("America/phoenix");

const FloodCertsRevenue = () => {

    const [isProcess, setProcess] = useState(false);
    let [tableData, setTableData] = useState([]);
    let [field, setFields] = useState({ year: new Date() });

    useEffect(() => {
        getRevenueData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    let getRevenueData = async () => {
        try {
            setProcess(true);
            let data = { date: field.year }
            const response = await PostData(`accounting/get_flood_certs_revenue`, data);
            setProcess(false)
            const reportTypesData = response.data.reportTypesData;
            const revenueData = response.data.revenueData;
            let tableDataTemp = [];
            let lastRowRevenueObj = {};
            let [janTotal, febTotal, marTotal, aprTotal, mayTotal, junTotal, julTotal, augTotal, sepTotal, octTotal, novTotal, decTotal] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            for (let i = 0; i < reportTypesData.length; i++) {
                let finalObj = {}
                const reportTypeObj = reportTypesData[i];
                finalObj.reportTypeNickname = reportTypeObj.nickname;
                finalObj.reportTypeId = reportTypeObj.id;

                for (let [index, elementData] of Object.entries(revenueData)) {
                    finalObj.index = index;
                    if (elementData.report_type_id === finalObj.reportTypeId) {
                        finalObj.jan = priceNumber(elementData.jan, 0);
                        finalObj.feb = priceNumber(elementData.feb, 0);
                        finalObj.mar = priceNumber(elementData.mar, 0);
                        finalObj.apr = priceNumber(elementData.apr, 0);
                        finalObj.may = priceNumber(elementData.may, 0);
                        finalObj.jun = priceNumber(elementData.jun, 0);
                        finalObj.jul = priceNumber(elementData.jul, 0);
                        finalObj.aug = priceNumber(elementData.aug, 0);
                        finalObj.sep = priceNumber(elementData.sep, 0);
                        finalObj.oct = priceNumber(elementData.oct, 0);
                        finalObj.nov = priceNumber(elementData.nov, 0);
                        finalObj.dec = priceNumber(elementData.dec, 0);

                        janTotal = janTotal + elementData.jan;
                        febTotal = febTotal + elementData.feb;
                        marTotal = marTotal + elementData.mar;
                        aprTotal = aprTotal + elementData.apr;
                        mayTotal = mayTotal + elementData.may;
                        junTotal = junTotal + elementData.jun;
                        julTotal = julTotal + elementData.jul;
                        augTotal = augTotal + elementData.aug;
                        sepTotal = sepTotal + elementData.sep;
                        octTotal = octTotal + elementData.oct;
                        novTotal = novTotal + elementData.nov;
                        decTotal = decTotal + elementData.dec;

                        let subTotal = elementData.jan + elementData.feb + elementData.mar + elementData.apr + elementData.may + elementData.jun + elementData.jul + elementData.aug + elementData.sep + elementData.oct + elementData.nov + elementData.dec;
                        finalObj.subTotal = priceNumber(subTotal, 0);
                    }
                }
                tableDataTemp.push(finalObj);
            }
            if (revenueData) {
                let yearTotal = janTotal + febTotal + marTotal + aprTotal + mayTotal + junTotal + julTotal + augTotal + sepTotal + octTotal + novTotal + decTotal;
                lastRowRevenueObj.reportTypeNickname = "Total";
                lastRowRevenueObj.jan = priceNumber(janTotal, 0);
                lastRowRevenueObj.feb = priceNumber(febTotal, 0);
                lastRowRevenueObj.mar = priceNumber(marTotal, 0);
                lastRowRevenueObj.apr = priceNumber(aprTotal, 0);
                lastRowRevenueObj.may = priceNumber(mayTotal, 0);
                lastRowRevenueObj.jun = priceNumber(junTotal, 0);
                lastRowRevenueObj.jul = priceNumber(julTotal, 0);
                lastRowRevenueObj.aug = priceNumber(augTotal, 0);
                lastRowRevenueObj.sep = priceNumber(sepTotal, 0);
                lastRowRevenueObj.oct = priceNumber(octTotal, 0);
                lastRowRevenueObj.nov = priceNumber(novTotal, 0);
                lastRowRevenueObj.dec = priceNumber(decTotal, 0);
                lastRowRevenueObj.subTotal = priceNumber(yearTotal, 0);
                tableDataTemp.push(lastRowRevenueObj);
            }
            setTableData(tableDataTemp);
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
        }
    }

    let onDateChange = (date) => {
        field['year'] = date;
        setFields(field);
        getRevenueData();
    }

    let columns = [
        { width: "", title: '#', dataField: 'reportTypeId', isKey: true, hidden: true },
        { width: "16", title: 'Report Type', dataField: 'reportTypeNickname', align: 'left' },
        { width: "7%", title: 'January', dataField: 'jan' },
        { width: "7%", title: 'February', dataField: 'feb' },
        { width: "7%", title: 'March', dataField: 'mar' },
        { width: "7%", title: 'April', dataField: 'apr' },
        { width: "7%", title: 'May', dataField: 'may' },
        { width: "7%", title: 'June', dataField: 'jun' },
        { width: "7%", title: 'July', dataField: 'jul' },
        { width: "7%", title: 'August', dataField: 'aug' },
        { width: "7%", title: 'September', dataField: 'sep' },
        { width: "7%", title: 'October', dataField: 'oct' },
        { width: "7%", title: 'November', dataField: 'nov' },
        { width: "7%", title: 'December', dataField: 'dec' },
        { width: "7%", title: 'Total', dataField: 'subTotal' },
    ];

    const tableParams = {
        tableData,
        columns,
        extraClassName: "revenue-table"
    };

    return (
        <>
            <Header />
            <Container className="mt--9 container-vh-100" fluid>
                <Card>
                    <AvForm>
                        <CardBody>
                            <>
                                <Row>
                                    <Col sm="6" md="4" lg="3" xl="2">
                                        <Label>Year</Label>
                                        <AvGroup>
                                            <DatePicker
                                                className="form-control"
                                                selected={field.year}
                                                onChange={(date) => onDateChange(date)}
                                                dateFormat="yyyy"
                                                showYearPicker
                                            />
                                        </AvGroup>
                                    </Col>
                                </Row>
                                {isProcess ?
                                    <TableSkeleton rows={15} cols={14} />
                                    :
                                    <RenderBootstrapTable pagination={false} tableParams={tableParams} />
                                }
                            </>
                        </CardBody>
                    </AvForm>
                </Card>
            </Container >
        </>
    );
}

export default FloodCertsRevenue;