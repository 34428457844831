import React, { useEffect, useState } from "react";

const InspectionCheck = ({ otherData }) => {
    const [inspectionAvailable, setInspectionAvailable] = useState(null);

    useEffect(() => {
        if (typeof otherData === 'object' && otherData !== null && Object.keys(otherData).length > 0) {
            if (otherData.inspectionAvailable === 1) {
                setInspectionAvailable(true);
            } else {
                setInspectionAvailable(false);
            }
        }
    }, [otherData]);

    return (
        <>
            <div className="form-check pl-0 pb-2">
                <label className="form-check-label">
                    Inspection Available at the time of the Analysis?
                </label>
                <input className="form-check-input ml-2 inspection-checkbox" type="checkbox" defaultChecked={inspectionAvailable} id="flexCheckDefault" />
            </div>

        </>
    );
}
export default InspectionCheck;