import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import React, { useState, useEffect } from "react";
import { moneyFormat } from "components/Common/Helpers";

const LineChartComp = ({ chartData }) => {
    const [isSameElements, setIsSameElements] = useState(false);

    useEffect(() => {
        if (Array.isArray(chartData) && chartData.length) {
            handleLineChartData(chartData);
        }
        //eslint-disable-next-line        
    }, [chartData]);

    let kFormatter = (num) => {
        return '$' + moneyFormat(num, 0);
    };

    async function checkChartData(chart_data) {
        let failCount = 0;
        for (var element of chart_data) {
            if (element.final !== element.submitted) {
                failCount++;
            }
        };
        return failCount;
    }

    let handleLineChartData = async (chart_data) => {
        let failCount = await checkChartData(chart_data);
        if (failCount === 0) {
            setIsSameElements(true);
        }
    };

    let msg = '';
    if (isSameElements) {
        msg = (<span className="line-chart-msg">Submitted and Final data are identical.</span>);
    }
    return (
        <>

            <p className="chart-heading h3">Value Curve</p>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    width={500}
                    height={350}
                    data={chartData}
                    margin={{
                        top: 30,
                        right: 7,
                        left: 0,
                        bottom: 0
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" className="xaxis" />
                    <YAxis tickFormatter={kFormatter} />
                    <Tooltip formatter={kFormatter} />
                    <Legend iconType="square" layout="horizontal" className="legend-label" verticalAlign="bottom" align="center" wrapperStyle={{ marginTop: "-528px" }} />
                    <Line
                        name="Submitted"
                        strokeWidth={3}
                        type="monotone"
                        dataKey="submitted"
                        stroke="#16291d"
                        activeDot={{ r: 8 }}
                    />
                    <Line name="Final" strokeWidth={3} type="monotone" dataKey="final" stroke="#f0b20a" />
                </LineChart>
            </ResponsiveContainer>
            {msg}
        </>
    );

}

export default LineChartComp;