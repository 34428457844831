import { number_format } from "components/Common/Helpers";
import React, { useEffect, useState } from "react";
import { Col, Modal, Table } from "reactstrap";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import { Link } from "react-router-dom";
import DiscrepancyValues from "./DiscrepancyValues";
import InspectionCheck from "./InspectionCheck";
import { checkRoles } from "components/Common/Helpers";
import { checkRolesWithLevel } from "components/Common/Helpers";

const Discrepancies = ({ onData, otherData, propWaivedResult }) => {
    const [discrepancyData, setDiscrepancyData] = useState({});
    const [isCompModal, setIsCompModal] = useState(false);
    const [compType, setCompType] = useState(null);

    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "discrepancies") {
                handleDiscrepancy(onData.data);
            }
        }
        //eslint-disable-next-line
    }, [onData]);

    let handleDiscrepancy = (data) => {
        let details = JSON.parse(data.details);
        let other_details = JSON.parse(data.other_details);
        let all_comp_per = (details.total_discrepancies / details.total_comps) * 100;
        all_comp_per = `${number_format(all_comp_per, 2)}%`;

        let selected_comp_per = (details.selected_discrepancies / details.selected_comps) * 100;
        selected_comp_per = `${number_format(selected_comp_per, 2)}%`;

        let dataArray = {
            total_comps: details.total_comps,
            selected_comps: details.selected_comps,
            total_differences: details.total_discrepancies,
            selected_differences: details.selected_discrepancies,
            all_comp_percentage: all_comp_per,
            selected_comp_percentage: selected_comp_per,
            all_comp_grade: details.average_discrepancy_grade,
            selected_comps_grade: details.average_selected_discrepancy_grade,
            grade: data.grade,
            allComps: JSON.parse(other_details.allComps),
            all_selected_comps: JSON.parse(other_details.selected_comps),
            allDifferentComps: JSON.parse(other_details.allDifferentComps),
            selectedDifferentComps: JSON.parse(other_details.selectedDifferentComps),
            discrepancy_acceptance_grade: details.discrepancy_acceptance_grade,
            selected_discrepancy_acceptance_grade: details.selected_discrepancy_acceptance_grade,
            adjusted_discrepancy_grade: details.adjusted_discrepancy_grade,
            selected_adjusted_discrepancy_grade: details.selected_adjusted_discrepancy_grade
        }

        setDiscrepancyData(dataArray);
        otherData.category_type = onData.type;
        otherData.discrepancyCompGrade = JSON.parse(other_details.discrepancyCompGrade);
        otherData.discrepancySelectedCompGrade = JSON.parse(other_details.discrepancySelectedCompGrade);
    }

    let handleCompModal = (state, compIds = [], type) => {
        setIsCompModal(state);
        setCompType(type);
    };

    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
    }

    const subjectPropertyTable = (
        <Table striped className="analysis-table">
            <thead>
                <tr>
                    <th>Metric</th>
                    <th>All Comps</th>
                    <th>Selected Comps</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Number of Comps</td>
                    <td>{discrepancyData.total_comps}</td>
                    <td>{discrepancyData.selected_comps}</td>
                </tr>
                <tr>
                    <td>Number w Differences</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, discrepancyData.allDifferentComps, 'allComps')} to="#" >{discrepancyData.total_differences}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, discrepancyData.selectedDifferentComps, 'selectedComps')} to="#" >{discrepancyData.selected_differences}</Link>
                    </td>
                </tr>
                <tr>
                    <td>Percentage</td>
                    <td>{discrepancyData.all_comp_percentage}</td>
                    <td>{discrepancyData.selected_comp_percentage}</td>
                </tr>
                <tr>
                    <td>Discrepancy Acceptance Grade</td>
                    <td>{discrepancyData.discrepancy_acceptance_grade}</td>
                    <td>{discrepancyData.selected_discrepancy_acceptance_grade}</td>
                </tr>
                <tr>
                    <td>Avg Reconciliation Grade Per Comp</td>
                    <td>{number_format(discrepancyData.all_comp_grade, 2)}</td>
                    <td>{number_format(discrepancyData.selected_comps_grade, 2)}</td>
                </tr>
                <tr>
                    <td>Adjusted Discrepancy Grade</td>
                    <td>{number_format(discrepancyData.adjusted_discrepancy_grade, 2)}</td>
                    <td>{number_format(discrepancyData.selected_adjusted_discrepancy_grade, 2)}</td>
                </tr>
                <tr>
                    <td>Final Grade</td>
                    <td>{number_format(discrepancyData.grade, 2)}</td>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        </Table>
    );

    return (
        <>
            {otherData.category_type === "discrepancies" &&
                <Col xl="12">
                    <p className="h3 float-left w-100"><u>Discrepancies</u></p>
                    <InspectionCheck otherData={otherData} />
                    {subjectPropertyTable}
                    {(checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col>
            }
            <Modal className="modal-dialog-centered modal-xl compensation-modal" style={{ maxWidth: "50%" }} isOpen={isCompModal} toggle={() => handleCompModal(false)}>
                <div className="modal-header">
                    <h2 className="modal-title w-100 text-center">Discrepancies Comps</h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleCompModal(false)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <DiscrepancyValues discData={otherData} compType={compType} />
                </div>
            </Modal>
        </>
    );

}

export default Discrepancies;