import { createLabelFromKey } from "components/Common/Helpers";
import { priceNumber } from "components/Common/Helpers";
import TR from "components/Ui/TR";
import { useEffect, useState } from "react";
import { Col, Modal, Row, } from "reactstrap";

const DueTotalInfo = (info) => {

    let [isOpenModal, setOpenModal] = useState(false);
    let [rowData, setRowData] = useState('');

    useEffect(() => {
        if (info.data.isModal.current) {
            setOpenModal(info.data.isModal.current);
        }

        let prices = info.data.dueInfo

        if (!prices?.discount) {
            delete prices.discount;
        }
        setRowData(prices)
    }, [info.data.isModal.current, info.data]);// eslint-disable-line react-hooks/exhaustive-deps

    let handleToggleModal = (state) => {
        state = !state;
        setOpenModal(state);
        info.data.isModal.current = false;
    };

    return (
        <>
            <Modal className="modal-dialog-centered" style={{ maxWidth: "500px" }} isOpen={isOpenModal} toggle={() => handleToggleModal(isOpenModal)}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title w-100 text-center">
                        Pricing Details
                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}            >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col className="col-md-12">
                            <table className="table table-striped due-info-table">
                                <tbody>
                                    {Object.entries(rowData).map(([key, value]) => (
                                        <TR
                                            key={key}
                                            propTdContent={[
                                                createLabelFromKey(key === 'total_price' ? "Total" : key === 'gla_surcharge' ? "GLA Surcharge" : key),
                                                key === 'discount' ? `-${priceNumber(value)}` : `${priceNumber(value)}`
                                            ]}
                                            customStyle={key === 'total_price' ? "font-weight-bold" : ""} />
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default DueTotalInfo;