import { createSlice } from '@reduxjs/toolkit'
import { confirmAlert } from 'react-confirm-alert';

export const inspectionOrderIntakeReducer = createSlice({
    name: 'inspectionOrderIntake',
    initialState: {
        intakeData: {
            orderInformation: {},
            propertyAccess: {},
            instruction: {},
        },
        customErrors: {
            orderInformation: [],
            propertyAccess: [],
            instruction: [],
        },
        otherData: {
            isOpenAddDrawItemModal: { current: false }
        },
        currentStep: 'order-information',
        errorIndexes: []
    },
    reducers: {
        setCurrentPage: (state, params) => {
            state.currentStep = params.payload.value;
        },
        setFullPayload: (state, params) => {
            state.intakeData = params.payload.value;
        },
        setIntakeData: (state, params) => {
            state.intakeData[params.payload.tab] = params.payload.value;
        },
        setCustomErrors: (state, params) => {
            state.customErrors[params.payload.tab] = params.payload.value;
        },
        setOtherData: (state, params) => {
            state.otherData[params.payload.tab] = params.payload.value;
        },
        handleCancel: () => {

            confirmAlert({
                title: 'Are you sure ?',
                message: 'You are about to cancel the order.',
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            // history.push("/admin/place-new-order");
                        },
                        className: "btn btn-primary btn-sm"
                    }, {
                        label: 'No',
                        onClick: () => { },
                        className: "btn btn-secondary btn-sm"
                    }
                ]
            });
        },
        resetForm: (state) => {
            state.intakeData = {
                orderInformation: {},
                propertyAccess: {},
                instruction: {},
            }

            state.customErrors = {
                orderInformation: [],
                propertyAccess: [],
                instruction: [],
            }

            state.currentStep = 'order-information'
        },
        setErrorIndexData: (state, params) => {
            state.errorIndexes = params.payload.value;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setFullPayload, setIntakeData, setCurrentPage, setCustomErrors, setOtherData, resetForm, setErrorIndexData } = inspectionOrderIntakeReducer.actions

export default inspectionOrderIntakeReducer.reducer
