import { checkRoles } from "components/Common/Helpers";
import { checkRolesWithLevel } from "components/Common/Helpers";
import { formatNumber } from "components/Common/Helpers";
import { number_format } from "components/Common/Helpers";
import Spinner from "components/Common/Spinner";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Modal, Col } from "reactstrap";
import { PostData } from "services/PostData";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import CompsList from "./CompsList";
import InspectionCheck from "./InspectionCheck";

const CompSelection = ({ onData, otherData, propWaivedResult }) => {
    const [compSelectionData, setCompSelectionData] = useState(null);
    const [isCompModal, setIsCompModal] = useState(false);
    const [isProcess, setProcess] = useState(false);
    const [compListState, setCompListState] = useState([]);

    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "comp-selection") {
                let resultData = onData.data ?? {};
                handleCompSelection(resultData);
            }
        }
        //eslint-disable-next-line
    }, [onData]);

    let handleCompSelection = (data) => {
        let compSelectionData = data.compSelection;
        let subjectPropertyStat = data.subjectPropertyStat;
        let details = JSON.parse(compSelectionData.details);
        let other_details = JSON.parse(compSelectionData.other_details);
        let bothCompsIds = other_details.bothCompsIds ? JSON.parse(other_details.bothCompsIds) : [];
        let myCompsIds = other_details.myCompsIds ? JSON.parse(other_details.myCompsIds) : [];
        let finalCompsIds = other_details.finalCompsIds ? JSON.parse(other_details.finalCompsIds) : [];
        let allComps = [...myCompsIds, ...finalCompsIds, ...bothCompsIds];

        // Handle Subject property final data
        let subject_prop_details = JSON.parse(subjectPropertyStat.other_details);
        let dataArray = {
            my: details.my,
            final: details.final,
            both: details.both,
            myTotal: other_details.myComps,
            finalTotal: other_details.finalComps,
            bothTotal: other_details.bothComps,
            allComps,
            subject_final_values: subject_prop_details.final
        }
        otherData.myCompsIds = myCompsIds;
        otherData.finalCompsIds = finalCompsIds;
        otherData.bothCompsIds = bothCompsIds;
        otherData.category_type = onData.type;
        setCompSelectionData(dataArray);
    }

    let handleCompModal = (state, compIds = []) => {
        setIsCompModal(state);
        if (state && compIds.length) {
            let sendData = {
                compsIds: compIds,
                final_comps_ids: otherData.finalCompsIds,
                my_comps_ids: otherData.myCompsIds,
                both_comps_ids: otherData.bothCompsIds,
                company_id: otherData.company_id,
                property_id: otherData.property_id,
                final_custom_analysis_ids: otherData.final_custom_analysis_ids,
                submitted_custom_analysis_ids: otherData.submitted_custom_analysis_ids
            }
            if (process.env.REACT_APP_SERVER_ENVIRONMENT === "training") {
                sendData.server_type = process.env.REACT_APP_SERVER_ENVIRONMENT
            }
            setProcess(true);
            PostData("analysis-comparison/comp-list", sendData).then(result => {
                let responseJson = result.data.data;

                otherData.searchParams = responseJson.searchParams;
                if (otherData.final_custom_analysis_ids) {
                    const compTypes = JSON.parse(otherData.final_custom_analysis_ids);
                    const filteredArray = compTypes.filter(item => typeof item === 'string' && item !== 'main_curv' && !/^\d+$/.test(item));
                    const finalSearchCompTypes = filteredArray ? filteredArray.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(', ') : "";
                    otherData.finalSearchCompTypes = finalSearchCompTypes;
                }
                if (otherData.submitted_custom_analysis_ids) {
                    const myCompTypes = JSON.parse(otherData.submitted_custom_analysis_ids);
                    const myFilteredArray = myCompTypes.filter(item => typeof item === 'string' && item !== 'main_curv' && !/^\d+$/.test(item));
                    const mySearchCompTypes = myFilteredArray ? myFilteredArray.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(', ') : "";
                    otherData.mySearchCompTypes = mySearchCompTypes;
                }
                setCompListState({ comps: responseJson.comps_list, subjectProperty: responseJson.subjectProperty, final_comps_ids: responseJson.final_comps_ids })
                setProcess(false);
            });
        }
    };

    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
    }

    const comSelectionTable = (
        <Table striped className="analysis-table">
            <thead>
                <tr>
                    <th>Metric</th>
                    <th>Subject Stats</th>
                    <th>Only Mine</th>
                    <th>Both Agree</th>
                    <th>Only Final</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>Number</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >-</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && compSelectionData.myTotal}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && compSelectionData.bothTotal}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && compSelectionData.finalTotal}</Link>
                    </td>
                </tr>
                <tr>
                    <td>Above Grade</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.subject_final_values.above_sqft, 2)}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.my.above_sqft, 0)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.both.above_sqft, 0)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.final.above_sqft, 0)}
                        </Link>
                    </td>
                </tr>
                <tr>
                    <td>Below Grade</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.subject_final_values.below_sqft, 2)}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.my.below_sqft, 0)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.both.below_sqft, 0)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.final.below_sqft, 0)}
                        </Link>
                    </td>
                </tr>

                <tr>
                    <td>Beds</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.subject_final_values.beds, 2)}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.my.beds, 2)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.both.beds, 2)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.final.beds, 2)}
                        </Link>
                    </td>
                </tr>
                <tr>
                    <td>Baths</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.subject_final_values.baths, 2)}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.my.baths, 2)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.both.baths, 2)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.final.baths, 2)}
                        </Link>
                    </td>
                </tr>
                <tr>
                    <td>Lot Size</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.subject_final_values.lot_size, 2)}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.my.lot_size, 0)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.both.lot_size, 0)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && number_format(compSelectionData.final.lot_size, 0)}
                        </Link>
                    </td>
                </tr>

                <tr>
                    <td>Year Built</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && compSelectionData.subject_final_values.year_built}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && formatNumber(compSelectionData.my.year_built ? parseInt(compSelectionData.my.year_built) : 0, 0)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && formatNumber(compSelectionData.both.year_built ? parseInt(compSelectionData.both.year_built) : 0, 0)}
                        </Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >
                            {compSelectionData && formatNumber(compSelectionData.final.year_built ? parseInt(compSelectionData.final.year_built) : 0, 0)}
                        </Link>
                    </td>
                </tr>
                <tr>
                    <td>Units</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && compSelectionData.subject_final_values.units}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.my.units, 2)}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.both.units, 2)}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.final.units, 2)}</Link>
                    </td>
                </tr>
                <tr>
                    <td>Comp Score</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && compSelectionData.subject_final_values.comp_score}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.my.comp_score, 2)}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.both.comp_score, 2)}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, compSelectionData.allComps)} to="#" >{compSelectionData && number_format(compSelectionData.final.comp_score, 2)}</Link>
                    </td>
                </tr>
            </tbody>
        </Table>
    );

    return (
        <>
            <Spinner isShow={isProcess} />
            {otherData.category_type === "comp-selection" &&
                <Col xl="12">
                    <p className="h3 float-left w-100"><u>Comp Selection</u></p>
                    <InspectionCheck otherData={otherData} />
                    {comSelectionTable}
                    {(checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col>
            }

            <Modal className="modal-dialog-centered modal-xl compensation-modal" style={{ maxWidth: "90%" }} isOpen={isCompModal} toggle={() => handleCompModal(false)}>
                <div className="modal-header">
                    <h2 className="modal-title w-100 text-center">Comp Selection</h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleCompModal(false)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <CompsList compList={compListState.comps} subjectPropertyData={compListState.subjectProperty} otherData={otherData} final_comps_ids={compListState.final_comps_ids} />
                </div>
            </Modal>
        </>
    );

}

export default CompSelection;