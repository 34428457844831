import { number_format } from "components/Common/Helpers";
import { handleFlags } from "components/Common/Helpers";
import { checkRolesWithLevel, checkRoles } from "components/Common/Helpers";
import React, { useEffect, useState } from "react";
import { Table, Col } from "reactstrap";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import InspectionCheck from "./InspectionCheck";

const SubjectPropertyStatistics = ({ onData, otherData, propWaivedResult }) => {
    const [subPropStatData, setSubPropStatData] = useState(null);
    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "subject-property-stats") {
                handleSubPropStat(onData.data);
            }
        }
        //eslint-disable-next-line
    }, [onData]);

    let handleSubPropStat = (data) => {
        let other_details = JSON.parse(data.other_details);
        let details = JSON.parse(data.details);
        let myData = other_details.my;
        let finalData = other_details.final;

        let dataArray = {
            grades: details,
            final_condition: other_details.final_condition,
            grade: data.grade,
            myData,
            finalData
        }
        otherData.category_type = onData.type;
        otherData.inspectionAvailable = other_details.inspectionAvailable ? parseInt(other_details.inspectionAvailable) : 0;
        setSubPropStatData(dataArray);
    }

    let handleFlagGrade = (flags) => {
        let flag = 0;
        if (flags.golf) {
            flag = flags.golf
        } else if (flags.water) {
            flag = flags.water
        }
        return flag;
    }

    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
    }

    const subjectPropertyTable = (
        <Table striped className="analysis-table">
            <thead>
                <tr>
                    <th>Metric</th>
                    <th>My Values</th>
                    <th>Final Values</th>
                    <th>Grade</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>Property Type</td>
                    <td>{subPropStatData && subPropStatData.myData.property_type_normalized}</td>
                    <td>{subPropStatData && subPropStatData.finalData.property_type_normalized}</td>
                    <td>{subPropStatData && number_format(subPropStatData.grades.property_type_normalized, 2)}</td>
                </tr>
                <tr>
                    <td>Above Grade</td>
                    <td>{subPropStatData && number_format(subPropStatData.myData.above_sqft, 2)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.finalData.above_sqft, 2)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.grades.above_sqft, 2)}</td>
                </tr>
                <tr>
                    <td>Below Grade</td>
                    <td>{subPropStatData && number_format(subPropStatData.myData.below_sqft)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.finalData.below_sqft)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.grades.below_sqft, 2)}</td>
                </tr>

                <tr>
                    <td>Beds</td>
                    <td>{subPropStatData && number_format(subPropStatData.myData.beds)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.finalData.beds)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.grades.beds, 2)}</td>
                </tr>
                <tr>
                    <td>Baths</td>
                    <td>{subPropStatData && number_format(subPropStatData.myData.baths)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.finalData.baths)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.grades.baths, 2)}</td>
                </tr>
                <tr>
                    <td>Lot Size</td>
                    <td>{subPropStatData && number_format(subPropStatData.myData.lot_size)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.finalData.lot_size)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.grades.lot_size, 2)}</td>
                </tr>

                <tr>
                    <td>Year Built</td>
                    <td>{subPropStatData && subPropStatData.myData.year_built}</td>
                    <td>{subPropStatData && subPropStatData.finalData.year_built}</td>
                    <td>{subPropStatData && number_format(subPropStatData.grades.year_built, 2)}</td>
                </tr>
                <tr>
                    <td>Stories</td>
                    <td>{subPropStatData && number_format(subPropStatData.myData.stories)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.finalData.stories)}</td>
                    <td>{subPropStatData && number_format(subPropStatData.grades.stories, 2)}</td>
                </tr>
                <tr>
                    <td>Flags</td>
                    <td>{subPropStatData && handleFlags({ golf: parseInt(subPropStatData.myData.key_flags_golf_course), water: parseInt(subPropStatData.myData.key_flags_water_front) })}</td>
                    <td>{subPropStatData && handleFlags({ golf: parseInt(subPropStatData.finalData.key_flags_golf_course), water: parseInt(subPropStatData.finalData.key_flags_water_front) })}</td>
                    <td>{subPropStatData && handleFlagGrade({ golf: parseFloat(subPropStatData.grades.key_flags_golf_course), water: parseFloat(subPropStatData.grades.key_flags_water_front) })}</td>
                </tr>
                <tr>
                    <td>Total Grade</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{subPropStatData && number_format(subPropStatData.grade, 2)}</td>
                </tr>
            </tbody>

        </Table>
    );

    return (
        <>
            {otherData.category_type === "subject-property-stats" &&
                <Col xl="12">
                    <p className="h3 float-left w-100"><u>Subject Property Statistics</u></p>
                    <InspectionCheck otherData={otherData} />
                    {subjectPropertyTable}

                    {(checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col>
            }
        </>
    );
}
export default SubjectPropertyStatistics;