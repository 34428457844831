
/*
### Example:

Import : import Spinner from "components/Common/Spinner.js";

### Component:

 <Spinner isShow={true} />

*/

import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/core";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #11cdef;
  margin-left: -20px !important;
  position:fixed;

`;
const Spinner = ({ isShow }) => {

  return (
    <>
      {isShow ? (
        <div className="loading" id="loader" >
          <ScaleLoader color={'#11cdef'} loading={true} css={override} size={200} />
        </div>
      ) : null}
    </>
  )
}

export default Spinner;