import React, { useState, useEffect } from "react";
import { Col, Table, Modal, Row, Label } from "reactstrap";
import TabsComponent from "components/Common/TabsComponent.js";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import { PostData } from "../../../services/PostData";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { number_format, loggedInUser } from "components/Common/Helpers.js";
import { GetData } from "../../../services/Api.service";
const userData = loggedInUser();

const DiscrepancyValues = (discData) => {
    let [discrepancyValues, setDiscrepancyValues] = useState([]);
    let [discrepancyResult, setDiscrepancyResult] = useState([]);
    const [isOpenDiscModal, setIsOpenDiscModal] = useState(false);
    let [myCompsValues, setMyCompsValues] = useState([]);
    let [finalCompsValues, setFinalCompsValues] = useState([]);
    const [isOpenCompModal, setIsOpenCompModal] = useState(false);
    let [carouselSlider, setCarouselSlider] = useState(null);
    let [otherSite, setOtherSite] = useState(null);
    let [google_prop, setGoogleProps] = useState(null);
    let [mapData, setMapData] = useState({ activeMarker: {}, selectedPlace: {}, showingInfoWindow: true });
    let [mapStyles] = useState({ width: '100%', height: '400px' });
    let [propertyComps, setPropertyComps] = useState([]);
    const [isEmptyDiscValue, setIsEmptyDiscValue] = useState(false);
    let [isLoadMap, setIsLoadMap] = useState(false);
    let [reportOrder, setReportOrder] = useState([]);
    let [propertyAddress, setPropertyAddress] = useState([]);
    let [discrepancyDiffrence, setDiscrepancyDiffrence] = useState([]);
    let [compsCategories, setCompsCategories] = useState([]);
    let [apexTypes, setApexTypes] = useState([]);
    let propertyCompData = {};

    useEffect(() => {
        if (discData) {
            handleDiscrepancyValue(discData);
        }
    }, [discData]);

    let handleDiscrepancyValue = (allData) => {
        let discrepancyData = allData.discData;
        let discrepancy_values = discrepancyData?.discrepancy_values;
        if (discrepancyData && discrepancyData.report_order_detail) {
            setReportOrder(discrepancyData.report_order_detail[0]);
        }

        if (discrepancy_values && Object.keys(discrepancy_values).length !== 0) {
            setIsEmptyDiscValue(false);
            if (discrepancy_values.final_comps.length === 0 && discrepancy_values.my_comps.length === 0) {
                setIsEmptyDiscValue(true);
                return false
            }
            let compsList = discrepancy_values.my_comps;
            if (allData.compType === "selectedComps") {
                let discrepancySelectedCompGrade = discrepancyData.discrepancySelectedCompGrade;
                if (discrepancySelectedCompGrade.length === 0) {
                    setIsEmptyDiscValue(true);
                }
                let tmpCompsList = [];
                for (const compData of compsList) {
                    let selectedComps = discrepancySelectedCompGrade.filter(obj => parseInt(obj.comp_id) === parseInt(compData.property_comp_id));
                    if (selectedComps.length) {
                        compData.grade = selectedComps[0].grade;
                        tmpCompsList.push(compData);
                    }
                }
                setDiscrepancyValues(tmpCompsList);
            } else {
                let discrepancyCompGrade = discrepancyData.discrepancyCompGrade;
                let tmpCompsList = [];
                for (const compData of compsList) {
                    let selectedComps = discrepancyCompGrade.filter(obj => parseInt(obj.comp_id) === parseInt(compData.property_comp_id));
                    if (selectedComps.length) {
                        compData.grade = selectedComps[0].grade;
                        tmpCompsList.push(compData);
                    }
                }
                setDiscrepancyValues(tmpCompsList);
            }
        } else {
            setIsEmptyDiscValue(true);
        }
        setDiscrepancyResult(discrepancy_values);
        setGoogleProps(allData.google);
    }

    let prepareAddress = (compDetail) => {
        return `${compDetail.property_comp.address}, ${compDetail.property_comp.city}, ${compDetail.property_comp.state}, ${compDetail.property_comp.postal_code}`;
    }

    let tableRow = discrepancyValues && discrepancyValues.map((val, i) => (
        <tr key={i}>
            <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{prepareAddress(val)}</span></td>
            <td>{number_format(val.grade, 2)}</td>
        </tr>
    ));

    /*
    | Handle Modal
    */
    let toggleDiscrepancyModal = async (e, row) => {
        let newState = !isOpenDiscModal;

        if (newState) {
            let subCategoriesResult = await GetData("performance-rating/get-performance-category");
            let subCategories = subCategoriesResult.data.categories;
            let apexTypes = subCategoriesResult.data.apexTypes;
            setApexTypes(apexTypes);

            var selectedMyComps = discrepancyResult.my_comps.filter(obj => obj.property_comp_id === row.property_comp_id);
            var selectedFinalComps = discrepancyResult.final_comps.filter(obj => obj.property_comp_id === row.property_comp_id);
            if (selectedMyComps) {
                let comp_stats = selectedMyComps[0].comp_stats;
                comp_stats = JSON.parse(comp_stats);
                comp_stats.property_comp_id = selectedMyComps[0].property_comp_id;
                comp_stats.property_id = selectedMyComps[0].property_comp.property_id;
                comp_stats.company_id = reportOrder.company_id;

                // Prepare Address
                let seletedProperty = selectedMyComps[0].property_comp;
                let propertyAddress = [seletedProperty.address, seletedProperty.city, seletedProperty.state, seletedProperty.postal_code].filter(Boolean).join(", ");
                setPropertyAddress(propertyAddress);

                let sbj_property_acceptable = 'agree';
                let default_accepatable = { above_sqft: sbj_property_acceptable, below_sqft: sbj_property_acceptable, beds: sbj_property_acceptable, baths: sbj_property_acceptable, year_built: sbj_property_acceptable, lot_size: sbj_property_acceptable };
                comp_stats.acceptable = default_accepatable;
                let submitted_acceptable_details = selectedMyComps[0].acceptable_details;

                if (submitted_acceptable_details) {
                    let acceptable_details = JSON.parse(submitted_acceptable_details);
                    if (acceptable_details) {
                        let row_accepatable = Object.assign(default_accepatable, acceptable_details);
                        if (row_accepatable) {
                            comp_stats.acceptable = row_accepatable;
                        }
                    }
                }
                // Calculate Discrepancy comps diffrence
                let diffrenceCalculation = {};
                if (selectedFinalComps) {
                    let final_comp_stats = selectedFinalComps[0].comp_stats;
                    final_comp_stats = JSON.parse(final_comp_stats);

                    diffrenceCalculation.above_diffrence = Math.abs(comp_stats.above_sqft - final_comp_stats.above_sqft);
                    diffrenceCalculation.below_diffrence = Math.abs(comp_stats.below_sqft - final_comp_stats.below_sqft);
                    diffrenceCalculation.beds_diffrence = Math.abs(comp_stats.beds - final_comp_stats.beds);
                    diffrenceCalculation.baths_diffrence = Math.abs(comp_stats.baths - final_comp_stats.baths);
                    diffrenceCalculation.year_diffrence = Math.abs(comp_stats.year_built - final_comp_stats.year_built);
                    diffrenceCalculation.lot_size_diffrence = Math.abs(comp_stats.lot_size - final_comp_stats.lot_size);
                    diffrenceCalculation.stories_diffrence = Math.abs(comp_stats.stories - final_comp_stats.stories);
                    diffrenceCalculation.key_flags_water_front_diffrence = Math.abs(comp_stats.key_flags_water_front - final_comp_stats.key_flags_water_front);
                    diffrenceCalculation.key_flags_golf_course_diffrence = Math.abs(comp_stats.key_flags_golf_course - final_comp_stats.key_flags_golf_course);

                    diffrenceCalculation.above_percentage = number_format((diffrenceCalculation.above_diffrence / final_comp_stats.above_sqft) * 100, 2);
                    diffrenceCalculation.below_percentage = number_format((diffrenceCalculation.below_diffrence / final_comp_stats?.below_sqft) * 100, 2);
                    diffrenceCalculation.beds_percentage = number_format((diffrenceCalculation.beds_diffrence / final_comp_stats.beds) * 100, 2);
                    diffrenceCalculation.baths_percentage = number_format((diffrenceCalculation.baths_diffrence / final_comp_stats.baths) * 100, 2);
                    diffrenceCalculation.year_percentage = number_format((diffrenceCalculation.year_diffrence / final_comp_stats.year_built) * 100, 2);
                    diffrenceCalculation.lot_size_percentage = number_format((diffrenceCalculation.lot_size_diffrence / final_comp_stats.lot_size) * 100, 2);
                    diffrenceCalculation.stories_percentage = number_format((diffrenceCalculation.stories_diffrence / final_comp_stats.stories) * 100, 2);
                    diffrenceCalculation.key_flags_water_front_percentage = number_format((diffrenceCalculation.key_flags_water_front_diffrence / final_comp_stats.key_flags_water_front) * 100, 2);
                    diffrenceCalculation.key_flags_golf_course_percentage = number_format((diffrenceCalculation.key_flags_golf_course_diffrence / final_comp_stats.key_flags_golf_course) * 100, 2);
                }
                setDiscrepancyDiffrence(diffrenceCalculation);
                setMyCompsValues(comp_stats);
            }

            if (selectedFinalComps) {
                let comp_stats = selectedFinalComps[0].comp_stats;
                comp_stats = JSON.parse(comp_stats);
                comp_stats.property_comp_id = selectedFinalComps[0].property_comp_id;
                comp_stats.property_id = selectedFinalComps[0].property_comp.property_id;
                comp_stats.company_id = reportOrder.company_id;
                setFinalCompsValues(comp_stats);
            }

            // Set performance categories
            let propTypeObj = subCategories.find(o => o.slug === 'subject-prop-stats-prop-type');
            let aboveObj = subCategories.find(o => o.slug === 'subject-prop-stats-above-grade');
            let belowObj = subCategories.find(o => o.slug === 'subject-prop-stats-below-grade');
            let bedsObj = subCategories.find(o => o.slug === 'subject-prop-stats-beds');
            let bathsObj = subCategories.find(o => o.slug === 'subject-prop-stats-baths');
            let lotsObj = subCategories.find(o => o.slug === 'subject-prop-stats-lot-size');
            let yearObj = subCategories.find(o => o.slug === 'subject-prop-stats-year-built');
            let storiesObj = subCategories.find(o => o.slug === 'subject-prop-stats-stories');
            let waterFrontObj = subCategories.find(o => o.slug === 'subject-prop-stats-waterfront');
            let golfCourseObj = subCategories.find(o => o.slug === 'subject-prop-stats-golfcourse');



            let subCategoriesObj = {
                above_sqft: aboveObj.weight,
                below_sqft: belowObj.weight,
                beds: bedsObj.weight,
                baths: bathsObj.weight,
                year_built: yearObj.weight,
                lot_size: lotsObj.weight,
                stories: storiesObj.weight,
                key_flags_water_front: waterFrontObj.weight,
                key_flags_golf_course: golfCourseObj.weight,
                property_type: propTypeObj.weight,
            }
            setCompsCategories(subCategoriesObj);
        }
        setIsOpenDiscModal(newState);
    };

    let calculatePropStatGrade = (myValue, finalValue) => {
        let calculatedGrade = 0;
        myValue = parseFloat(myValue);
        finalValue = parseFloat(finalValue);

        if (myValue === finalValue) {
            calculatedGrade = 1;
        }
        if (myValue && finalValue) {
            calculatedGrade = 1 - (Math.max(myValue, finalValue) - Math.min(myValue, finalValue)) / Math.max(myValue, finalValue);
        }
        calculatedGrade = number_format(calculatedGrade * 100, 2);
        return calculatedGrade;
    }

    let formatString = (string) => {
        if ((string && typeof string === 'string') || string instanceof String) {
            return string.trim().toLowerCase();
        }
        return false;
    }

    let calculateApexType = (myValue, finalValue) => {
        let myApexRecord = apexTypes.find(o => o.property_type_normalized === myValue);
        let finalApexRecord = apexTypes.find(o => o.property_type_normalized === finalValue);

        let propTypeGrade = 0;
        if (formatString(myValue) === formatString(finalValue)) {
            propTypeGrade = 1;
        } else if (formatString(myApexRecord?.apex_prop_type) === formatString(finalApexRecord?.apex_prop_type)) {
            propTypeGrade = 0.5;
        }
        return propTypeGrade * 100;
    }

    /*
    | Handle Modal Comp Detail
    */
    let toggleCompDetailModal = (e, row) => {
        let newState = !isOpenCompModal;
        if (newState) {
            let postData = { property_comp_id: row.property_comp_id };
            PostData("analysis-comparison/getCompDetails", postData).then(result => {
                let responseJson = result.data.data;
                propertyCompData.latitude = responseJson.latitude;
                propertyCompData.longitude = responseJson.longitude;
                propertyCompData.public_remarks = responseJson.public_remarks;
                propertyCompData.address = responseJson.address;
                propertyCompData.city = responseJson.city;
                propertyCompData.state = responseJson.state;
                propertyCompData.postal_code = responseJson.postal_code;

                let propertyImgs = responseJson.image_urls;
                if (propertyImgs) {
                    propertyImgs = propertyImgs.replace("[", "");
                    propertyImgs = propertyImgs.replace("]", "");
                    let isQoute = propertyImgs.indexOf('"');
                    if (isQoute !== -1) {
                        propertyImgs = JSON.parse("[" + propertyImgs + "]");
                    } else {
                        propertyImgs = propertyImgs.split(",");
                    }
                    propertyImgs = propertyImgs.filter(element => element !== "\"\"" && element !== "");

                    carouselSlider = (
                        <>
                            {propertyImgs.length > 0 ?
                                <Carousel >
                                    {propertyImgs.map((imageUrl, index) =>
                                        <div key={index}>
                                            <img src={imageUrl} alt={"img_" + index} loading="lazy" />
                                        </div>
                                    )}
                                </Carousel>
                                : <h2 className="not-authorized-msg">NO IMAGE DATA.</h2>}
                            {responseJson.public_remarks ? <p className="text-left">{responseJson.public_remarks}</p> : null}
                        </>
                    )
                } else {
                    carouselSlider = (
                        <>
                            <h2 className="not-authorized-msg">NO IMAGE DATA.</h2>
                            {responseJson.public_remarks ? <p className="text-left">{responseJson.public_remarks}</p> : null}
                        </>
                    );
                }

                if (propertyCompData.address) {
                    let zlinkData = propertyCompData.address + " " + propertyCompData.city + " " + propertyCompData.state + " " + propertyCompData.postal_code;
                    zlinkData = zlinkData.replace(/ /g, '-');
                    let zlink = "https://www.zillow.com/homes/" + zlinkData + "_rb/";
                    let redfin = "https://www.redfin.com/";
                    let realtor = "https://www.realtor.com/";
                    otherSite = (
                        <>
                            <div className="others-site">
                                <Row>
                                    <Col md="12">
                                        <a href={zlink} target="_blank" rel="noreferrer"> <img
                                            alt="Zillow"
                                            src={
                                                require("../../../assets/img/marketplace/zillow.png")
                                                    .default
                                            }
                                        /></a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <CopyToClipboard text={propertyCompData.address + ", " + propertyCompData.city + ", " + propertyCompData.state + ", " + propertyCompData.postal_code}>
                                            <a href={realtor} target="_blank" rel="noreferrer"> <img
                                                alt="realtor"
                                                src={
                                                    require("../../../assets/img/marketplace/realtor.png")
                                                        .default
                                                }
                                            /></a>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <CopyToClipboard text={propertyCompData.address + " " + propertyCompData.city + " " + propertyCompData.state + " " + propertyCompData.postal_code}>
                                            <a href={redfin} target="_blank" rel="noreferrer"> <img
                                                alt="realtor"
                                                src={
                                                    require("../../../assets/img/marketplace/redfin.png")
                                                        .default
                                                }
                                            /></a>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )
                    setOtherSite(otherSite);
                }
                setCarouselSlider(carouselSlider);
                setPropertyComps(propertyCompData);
                setIsLoadMap(true);

            });
        }
        setIsOpenCompModal(newState);
    };

    const comps_stats_table = (
        <Table striped className="analysis-table" >
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>My Values</th>
                    <th>Final Values</th>
                    <th>Diffrence</th>
                    <th>Percentage</th>
                    <th>Grade</th>
                    <th>Weight</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">Prop Type</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{myCompsValues.property_type_normalized}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{finalCompsValues.property_type_normalized}</span></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{calculateApexType(myCompsValues.property_type_normalized, finalCompsValues.property_type_normalized)}</td>
                    <td>{compsCategories.property_type}</td>
                </tr>
                <tr>
                    <th scope="row">Above Grade</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{number_format(myCompsValues.above_sqft)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(finalCompsValues.above_sqft)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.above_diffrence, 2)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.above_percentage, 2)}%</span></td>
                    <td>{calculatePropStatGrade(myCompsValues.above_sqft, finalCompsValues.above_sqft)}</td>
                    <td>{compsCategories.property_type}</td>
                </tr>
                <tr>
                    <th scope="row">Below Grade</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{number_format(myCompsValues.below_sqft)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(finalCompsValues.below_sqft)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.below_diffrence, 2)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.below_percentage, 2)}%</span></td>
                    <td>{calculatePropStatGrade(myCompsValues.below_sqft, finalCompsValues.below_sqft)}</td>
                    <td>{compsCategories.below_sqft}</td>
                </tr>
                <tr>
                    <th scope="row">Beds</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{parseInt(myCompsValues.beds)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{parseInt(finalCompsValues.beds)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.beds_diffrence, 2)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.beds_percentage, 2)}%</span></td>
                    <td>{calculatePropStatGrade(parseInt(myCompsValues.beds), parseInt(finalCompsValues.beds))}</td>
                    <td>{compsCategories.beds}</td>
                </tr>
                <tr>
                    <th scope="row">Baths</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{parseInt(myCompsValues.baths)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{parseInt(finalCompsValues.baths)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.baths_diffrence, 2)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.baths_percentage, 2)}%</span></td>
                    <td>{calculatePropStatGrade(parseInt(myCompsValues.baths), parseInt(finalCompsValues.baths))}</td>
                    <td>{compsCategories.baths}</td>
                </tr>
                <tr>
                    <th scope="row">Lot Size</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{number_format(myCompsValues?.lot_size)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(finalCompsValues?.lot_size)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.lot_size_diffrence, 2)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.lot_size_percentage, 2)}%</span></td>
                    <td>{calculatePropStatGrade(myCompsValues?.lot_size, finalCompsValues?.lot_size)}</td>
                    <td>{compsCategories.lot_size}</td>
                </tr>
                <tr>
                    <th scope="row">Stories</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{parseInt(myCompsValues.stories)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{parseInt(finalCompsValues.stories)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.stories_diffrence, 2)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.stories_percentage, 2)}%</span></td>
                    <td>{calculatePropStatGrade(parseInt(myCompsValues.stories), parseInt(finalCompsValues.stories))}</td>
                    <td>{compsCategories.stories}</td>
                </tr>
                <tr>
                    <th scope="row">Waterfront</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{parseInt(myCompsValues.key_flags_water_front)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{parseInt(finalCompsValues.key_flags_water_front)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.key_flags_water_front_diffrence, 2)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.key_flags_water_front_percentage, 2)}%</span></td>
                    <td>{calculatePropStatGrade(parseInt(myCompsValues.key_flags_water_front), parseInt(finalCompsValues.key_flags_water_front))}</td>
                    <td>{compsCategories.key_flags_water_front}</td>
                </tr>
                <tr>
                    <th scope="row">Golf course</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{parseInt(myCompsValues.key_flags_golf_course)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{parseInt(finalCompsValues.key_flags_golf_course)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.key_flags_golf_course_diffrence, 2)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.key_flags_golf_course_percentage, 2)}%</span></td>
                    <td>{calculatePropStatGrade(parseInt(myCompsValues.key_flags_golf_course), parseInt(finalCompsValues.key_flags_golf_course))}</td>
                    <td>{compsCategories.key_flags_golf_course}</td>
                </tr>
                <tr>
                    <th scope="row">Year Built</th>
                    <td onClick={(e) => toggleCompDetailModal(e, myCompsValues)}><span role="button">{myCompsValues.year_built}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{finalCompsValues.year_built}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.year_diffrence, 2)}</span></td>
                    <td onClick={(e) => toggleCompDetailModal(e, finalCompsValues)}><span role="button">{number_format(discrepancyDiffrence.year_percentage, 2)}%</span></td>
                    <td>{calculatePropStatGrade(myCompsValues.year_built, finalCompsValues.year_built)}</td>
                    <td>{compsCategories.year_built}</td>
                </tr>
            </tbody>
        </Table>
    );



    // Mapbox
    let onMarkerClick = (props, marker, e) => {
        setMapData({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    //  Map box close
    let onClose = props => {
        if (mapData.showingInfoWindow) {
            setMapData({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    return (
        <>
            <Col lg="12" xl="12">
                {!isEmptyDiscValue ? (
                    <Table striped className="analysis-table">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Grade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow}
                        </tbody>
                    </Table>
                ) : (
                    <h2 className="not-authorized-msg">No discrepancy information found</h2>
                )}
            </Col>

            {/* Open Order modal */}
            <Modal className="modal-dialog-centered modal-lg" isOpen={isOpenDiscModal} toggle={() => toggleDiscrepancyModal(isOpenDiscModal)}>
                <div className="modal-header">
                    <h2 className="modal-title text-left w-100" id="reassignModalLabel">
                        Discrepancy Values: {propertyAddress}
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleDiscrepancyModal(isOpenDiscModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body discrepancy-values-popup">
                    {comps_stats_table}
                </div>
            </Modal>

            <Modal className="modal-dialog-centered modal-lg discrepancy-comps discrepancy-property-model" isOpen={isOpenCompModal} toggle={() => toggleCompDetailModal(isOpenCompModal)}>
                <div className="modal-header">
                    <h2 className="modal-title text-left w-100" id="reassignModalLabel">
                        DISCREPANCY COMP: {propertyAddress}
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleCompDetailModal(isOpenCompModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-center">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="discrepancy-column-body">
                                <Table className="text-left property-comp-table analysis-table" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" width="45%">&nbsp;</th>
                                            <th scope="col">
                                                <div className="custom-control custom-control-alternative custom-radio">
                                                    Used by RicherValues
                                                </div>
                                            </th>

                                            <th scope="col" width="20%">
                                                <div className="custom-control custom-control-alternative custom-radio">
                                                    My Values
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><Label for="example">Prop Type</Label></td>
                                            <td>{finalCompsValues?.property_type_normalized}</td>
                                            <td>{myCompsValues?.property_type_normalized}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Above Grade Sqft</Label></td>
                                            <td>{number_format(finalCompsValues?.above_sqft)}</td>
                                            <td>{number_format(myCompsValues?.above_sqft)}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Below Grade Sqft</Label></td>
                                            <td>{number_format(finalCompsValues?.below_sqft)}</td>
                                            <td>{number_format(myCompsValues?.below_sqft)}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Beds</Label></td>
                                            <td>{parseInt(finalCompsValues?.beds)}</td>
                                            <td>{parseInt(myCompsValues?.beds)}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Baths</Label></td>
                                            <td>{parseInt(finalCompsValues?.baths)}</td>
                                            <td>{parseInt(myCompsValues?.baths)}</td>
                                        </tr>
                                        <tr>
                                            <td><Label>Year Built</Label></td>
                                            <td>{finalCompsValues?.year_built}</td>
                                            <td>{myCompsValues?.year_built}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Stories</Label></td>
                                            <td>{finalCompsValues?.stories}</td>
                                            <td>{myCompsValues?.stories}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Lot Size</Label></td>
                                            <td>{number_format(finalCompsValues?.lot_size)}</td>
                                            <td>{number_format(myCompsValues?.lot_size)}</td>
                                        </tr>
                                        <tr>
                                            <th><Label>Waterfront</Label></th>
                                            <td>{parseInt(finalCompsValues.key_flags_water_front)}</td>
                                            <td>{parseInt(myCompsValues.key_flags_water_front)}</td>
                                        </tr>
                                        <tr>
                                            <th><Label>Golf course</Label></th>
                                            <td>{parseInt(finalCompsValues.key_flags_golf_course)}</td>
                                            <td>{parseInt(myCompsValues.key_flags_golf_course)}</td>

                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="others-column-body">
                                {isLoadMap ? (
                                    <TabsComponent
                                        customTabsStyle={{}}
                                        customTabListStyle={{ padding: "6px 26px" }}
                                        tabs={['Listing', 'Google', 'Other Sites']}
                                        tabPanel={[
                                            carouselSlider,
                                            <Map
                                                className="custom-map-controls"
                                                contextMenu={true}
                                                google={google_prop}
                                                zoom={20}
                                                style={mapStyles}
                                                initialCenter={
                                                    {
                                                        lat: propertyComps.latitude || '',
                                                        lng: propertyComps.longitude || ''
                                                    }
                                                }
                                                zoomControlOptions={{
                                                    position: google_prop.maps.ControlPosition.BOTTOM_RIGHT
                                                }}
                                            >
                                                <Marker
                                                    onClick={onMarkerClick}
                                                    name={propertyComps.address + ", " + propertyComps.city + ", " + propertyComps.state + ", " + propertyComps.postal_code}
                                                />
                                                <InfoWindow
                                                    marker={mapData.activeMarker}
                                                    visible={mapData.showingInfoWindow}
                                                    onClose={onClose}
                                                >
                                                    <div className="map-link">
                                                        <a href={"https://www.google.com/maps/place/" + mapData.selectedPlace?.name + "/@" + propertyComps.latitude + "," + propertyComps.longitude + ",20z"} target="_blank" rel="noreferrer"><h2>{mapData.selectedPlace?.name}</h2></a>
                                                    </div>
                                                </InfoWindow>
                                            </Map>,
                                            otherSite
                                        ]}
                                    />
                                ) : (
                                    null
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(DiscrepancyValues);