import React, { useState, useEffect, useRef } from "react";
import { InputGroupAddon, InputGroupText, InputGroup, Button } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "./style.css";
const OrderManagementSearch = (props) => {

    let location = useLocation();
    let [searchText, setSearchText] = useState("");
    let [searchError, setSearchError] = useState(false);
    let history = useHistory();
    let focusPoint = useRef("");
    useEffect(() => {
        let searchString = new URLSearchParams(location.search);
        searchString = searchString.get('search');
        if (searchString) {
            setSearchText(searchString);
        }
    }, [location]);

    let handleSearch = (e) => {
        e.preventDefault();
        let searchText = focusPoint.current.props.value;
        if (searchText.length < 4) {
            setSearchError(true);
            return false;
        }
        setSearchText(searchText);
        history.push("/client/search/?search=" + searchText);

    }
    let searchTextHandle = (e) => {
        setSearchError(false);
        let searchText = e.target.value;
        setSearchText(searchText);
    }

    return (
        <>

            <AvForm className={props.className} onValidSubmit={(e) => handleSearch(e)}>
                <InputGroup className="input-group-alternative">

                    <InputGroupAddon addonType="prepend" className="input-group-alternative">
                        <InputGroupText>
                            <i className="fas fa-search" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <AvField
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Search cannot be an empty"
                            },
                            pattern: {
                                value: '/^[d,sA-Za-z0-9- ]+$/',
                                errorMessage: 'Special characters not allowed except coma(,) and dash(-)'
                            },
                            minLength: {
                                value: 4,
                                errorMessage: `Minimum 4 characters required`
                            },
                            maxLength: {
                                value: 60,
                                errorMessage: `Maximum 60 Characters Allowed`
                            }
                        }}
                        placeholder="Search here" autoComplete="off" name="search_here" ref={focusPoint} type="text" onChange={(e) => searchTextHandle(e)} value={searchText.trim()} />
                    {searchError ? <div className="invalid-feedback">Minimum 4 characters required</div> : null}
                    <Button className="clear-search">Search</Button>

                </InputGroup>
            </AvForm>
        </>
    );
};

export default OrderManagementSearch;
