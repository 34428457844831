import Header from "components/Headers/Header";
import Spinner from "components/Common/Spinner";
import { PostData as OMSPostData } from "../../../services/PostData";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import DrawLineItem from "./DrawLineItem";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { sendExceptionEmail } from "components/Common/Helpers";
import { AvForm } from "availity-reactstrap-validation";
import { useDispatch } from "react-redux";
import { setErrorIndexData } from "../InspectionOrderIntake/inspectionOrderIntake.reducer";

let myFormRef;

const ManageDrawLineItem = () => {
    const { inspection_order_id, type } = useParams();
    const [isProcess, setProcess] = useState(false);
    let [activeInspectionDrawItems, setActiveInspectionDrawItems] = useState([]);
    const dispatch = useDispatch();

    let onSetActiveLineItemData = (data) => {
        if (data) {
            setActiveInspectionDrawItems(data.activeInspectionDrawItems)
        }
    }

    let findErrorIndexes = (array) => {
        const errorIndexes = [];
        array.forEach((item, index) => {
            let our_completion_percent = item.new_our_completion_percent ? item.new_our_completion_percent : item.our_qc_completion_percent;
            if (parseFloat(item.previous_completion_percentage) > parseFloat(our_completion_percent)) {
                errorIndexes.push(index);
            }
        });
        return errorIndexes;
    }

    let updateCompletionPercentages = (items) => {
        return items.map(item => {
            if (item.new_our_completion_percent !== undefined) {
                if (item.new_our_completion_percent !== item.our_qc_completion_percent) {
                    item.our_qc_completion_percent = item.new_our_completion_percent;
                }
            }
            return item;
        });
    }



    let handleDuplicateLineItem = (lineItems = null) => {
        let duplicates = [];
        if (lineItems) {
            duplicates = findErrorIndexes(lineItems);
        } else {
            duplicates = findErrorIndexes(activeInspectionDrawItems);
        }
        if (duplicates.length) {
            toast['error']("Please review the highlighted row and rectify any errors");
        }
        dispatch(setErrorIndexData({ value: duplicates }))
        return duplicates.length;
    }

    let handleFinalize = async (saveOnly = false) => {
        try {
            let requestBody = {
                draw_items: activeInspectionDrawItems,
                inspection_order_id
            }
            let duplicatesLines = handleDuplicateLineItem(activeInspectionDrawItems);
            const values = myFormRef.getValues();
            let { isValid } = await myFormRef.validateAll(values, false);
            if (!isValid || duplicatesLines > 0) {
                return false
            }

            setProcess(true);
            const actionTypesResp = await OMSPostData("inspection/finalize-inspection-history-data", requestBody);

            let updatedItems = updateCompletionPercentages(activeInspectionDrawItems);
            setActiveInspectionDrawItems(updatedItems);
            toast['success'](actionTypesResp.data.message);
            setProcess(false);
        } catch (error) {
            toast['error'](error.message);
            await sendExceptionEmail(error);
            setProcess(false);
        }
    }

    return (
        <>
            <Header />
            <Spinner isShow={isProcess} />
            <Container className="mt--9 container-vh-100 manage-draw-line-item" fluid>
                <Card className="shadow">
                    <CardBody className="" >
                        <AvForm ref={(el) => myFormRef = el} >
                        <Row>
                                <DrawLineItem onHandleData={onSetActiveLineItemData} reportData={{ inspection_order_id, type }} otherData={{ class: "full-height" }} />
                        </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" className="mt-2">
                                    <div className="text-center">
                                        <Button className="float-right mr-3" outline size="sm" color="primary" onClick={() => handleFinalize(true)}>
                                            Save Changes
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
}
export default ManageDrawLineItem;