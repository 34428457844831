import { createSlice } from '@reduxjs/toolkit'

export const CompsPerformance = createSlice({
    name: 'compsPerformance',
    initialState: {
        pageData: {
            outstandingToReconcileProcessing: true,
            outstandingToReconcile: {},

            newAndReconciledCompsProcessing: true,
            newAndReconciledComps: {},

            reconciledByTeamMember: {},
            newOrders: {},
        },
        filterOptionsToShow: ['custom', 'tomorrow', 'today', 'yesterday', 'thisWeek', 'thisMonth', 'pastThirtyDays'],
        defaultDateFilter: { paramsKey: 'today' },
        currentDateFilter: { paramsKey: 'today' },
        otherFilters: { timePeriod: 'day', excludeWeekends: 'N' },
    },
    reducers: {
        setDateFilter: (state, params) => {
            state.currentDateFilter = params.payload.value;
        },
        setPageData: (state, params) => {
            state.pageData[params.payload.key] = params.payload.value;
        },
        setOtherFilters: (state, params) => {
            state.otherFilters[params.payload.key] = params.payload.value;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setDateFilter, setPageData, setOtherFilters } = CompsPerformance.actions

export default CompsPerformance.reducer
