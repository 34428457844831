import React, { useState, useEffect } from "react";
import { Col, Table, Row, CardFooter, Modal } from "reactstrap";
import { PostData } from "../../../services/PostData";
import { number_format, formatDate } from "components/Common/Helpers.js";
import { Map as GMap, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-tabs/style/react-tabs.css';
import { Carousel } from 'react-responsive-carousel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Spinner from "components/Common/Spinner";
import { loggedInUser } from "components/Common/Helpers";
import CompList from "../Common/CompLinks/CompList";
import { handleFlags } from "components/Common/Helpers";
import { googleMapCustomMarker } from "components/Common/Helpers";
const userData = loggedInUser();

const CompsList = ({ compList, subjectPropertyData, google, otherData, final_comps_ids }) => {
    const [isProcess, setProcess] = useState(false);
    let [subjectPropertyComp, setSubjectPropertyComp] = useState([]);
    let [myCompMarker, setMyCompMarker] = useState([]);
    let [mapStyles] = useState({ width: '100%', height: '350px' });
    let [google_prop, setGoogleProps] = useState(null);
    let [mapData, setMapData] = useState({ activeMarker: {}, selectedPlace: {}, showingInfoWindow: false });
    let [isLoadMap, setIsLoadMap] = useState(false);
    let [compsList, setCompsList] = useState([]);
    const [isOpenDiscModal, setIsOpenDiscModal] = useState(false);
    let [propertyComps, setPropertyComps] = useState([]);
    let [carouselSlider, setCarouselSlider] = useState(null);
    let [otherSite, setOtherSite] = useState(null);
    let [buttonYellow, setButtonYellow] = useState("btn bg-yellow active");
    let [buttonGreen, setButtonGreen] = useState("btn bg-green active");
    let [buttonBlue, setButtonBlue] = useState("btn bg-blue active");
    let propertyCompData = {};
    const [checkedState, setCheckedState] = useState(
        new Array(3).fill(true)
    );

    useEffect(() => {
        if (compList) {
            if (compList && compList.length > 0) {
                let filterType = null;
                if (otherData?.category_type === "comp-selection") {
                    filterType = 'all';
                }
                handleCompsArray(compList, subjectPropertyData, filterType)
                setGoogleProps(google);
            }
        }
        //eslint-disable-next-line
    }, [compList]);

    let handleCompsArray = (list, subjectProperty, type = null) => {
        let myCompObj = [];
        let compDistance = [];
        let sbjMarker = [];

        list.forEach(element => {
            compDistance.push(element.distance);
        });

        let maxDistance = Math.max(...compDistance);
        sbjMarker.zoom_level = 18;
        if (maxDistance >= 2) {
            sbjMarker.zoom_level = 12;
        } else if (maxDistance >= 1.5 && maxDistance <= 2) {
            sbjMarker.zoom_level = 13;
        } else if (maxDistance >= 1 && maxDistance <= 1.5) {
            sbjMarker.zoom_level = 14;
        } else if (maxDistance >= 0.5 && maxDistance <= 1) {
            sbjMarker.zoom_level = 15;
        } else if (maxDistance >= 0 && maxDistance <= 0.5) {
            sbjMarker.zoom_level = 16;
        }
        //console.log("sbjMarker.zoom_level", sbjMarker.zoom_level, maxDistance)
        // Subject property
        let sbjRowCom = [];

        if (subjectProperty) {
            let basement_sqft = 0;
            if (subjectProperty.finished_basement && subjectProperty.finished_basement > 0) {
                basement_sqft = subjectProperty.finished_basement;
            }

            let beds_val = number_format(subjectProperty.bedrooms_total);
            let baths_val = number_format(subjectProperty.bathrooms_total, 2);
            let stories_total = subjectProperty.stories_total;

            let lot_size = subjectProperty.lot_size_square_feet;
            if (lot_size > 0) {
                lot_size = lot_size / 43560;
                lot_size = parseFloat(lot_size, 2);
            }

            let formated_lot_size_square_feet = lot_size;
            if (lot_size != null) {
                formated_lot_size_square_feet = number_format(lot_size, 2);
            }

            let total = subjectProperty.living_area_square_feet + basement_sqft;
            let dist = subjectProperty.distance ? subjectProperty.distance : 0;

            sbjRowCom.id = subjectProperty.id;
            sbjRowCom.address = subjectProperty.address;
            sbjRowCom.above = number_format(subjectProperty.living_area_square_feet);
            sbjRowCom.below = number_format(basement_sqft);
            sbjRowCom.total = number_format(total);
            sbjRowCom.beds = beds_val;
            sbjRowCom.baths = baths_val;
            sbjRowCom.year_built = subjectProperty.year_built;
            sbjRowCom.stories = stories_total;
            sbjRowCom.lot = formated_lot_size_square_feet;
            sbjRowCom.dist = dist;
            sbjRowCom.flags = null;
            sbjRowCom.coe = null;
            sbjRowCom.sp = null;
            sbjRowCom.pricePerSqFt = null;
            sbjRowCom.tts = null;
            sbjRowCom.condition_rating = subjectProperty.condition_rating;
            sbjRowCom.apex_prop_type = parseInt(subjectProperty.apex_prop_type);
            sbjRowCom.isSubjectProperty = 1

            // Prepare Markers Data
            sbjMarker.id = -1;
            sbjMarker.name = subjectProperty.address;
            sbjMarker.position = { lat: subjectProperty.latitude, lng: subjectProperty.longitude };
            sbjMarker.is_comp_entry_for_property = 1;
            sbjMarker.label = 'S';
            sbjMarker.color = 'DD4B3E';
            setSubjectPropertyComp(sbjMarker);

        }

        // comps list

        let tempComp = [];
        let index = 0;
        let myCompMarkers = [];
        tempComp[0] = [];
        tempComp[0].push(sbjRowCom);
        myCompMarkers.push(sbjMarker);

        list.forEach(element => {
            let rowCom = [];
            let tempMarker = [];
            if (!Array.isArray(tempComp[`${element.condition_rating}`])) {
                tempComp[element.condition_rating] = [];
            }

            let basement_sqft = 0;
            if (element.finished_basement && element.finished_basement > 0) {
                basement_sqft = element.finished_basement;
            }

            let beds_val = number_format(element.bedrooms_total);
            let baths_val = number_format(element.bathrooms_total, 2);
            let stories_total = element.stories_total;

            let lot_size = element.lot_size_square_feet;
            if (lot_size > 0) {
                lot_size = lot_size / 43560;
                lot_size = parseFloat(lot_size, 2);
            }
            let formated_lot_size_square_feet = lot_size;
            if (lot_size != null) {
                formated_lot_size_square_feet = number_format(lot_size, 2);
            }

            let total = element.living_area_square_feet + basement_sqft;
            let dist = element.distance ? element.distance : 0;
            let units = element.units ? element.units : "";

            let formattedDate = 'N/A';
            if (element.close_date != null) {
                formattedDate = formatDate('', element.close_date, 'M/D/YY');
            }

            let pricePerSqFt = (element.living_area_square_feet === 0) ? 'N/A' : "$" + number_format(element.close_price / total);
            let price = "$" + number_format((element.close_price > 0) ? (element.close_price) : (element.list_price));
            let tts = element.si_days_on_market && element.si_days_on_market != null ? element.si_days_on_market : '';

            let flagsArray = [];
            if (parseInt(element.key_flags_golf_course) === 1) {
                flagsArray["golf"] = 'assets/img/flags/golf.png';
            }
            if (parseInt(element.key_flags_water_front) === 1) {
                flagsArray["water"] = 'assets/img/flags/water.png';
            }

            rowCom.id = element.id;
            rowCom.address = element.address;
            rowCom.above = number_format(element.living_area_square_feet);
            rowCom.below = number_format(basement_sqft);
            rowCom.total = number_format(total);
            rowCom.beds = beds_val;
            rowCom.baths = baths_val;
            rowCom.year_built = element.year_built;
            rowCom.stories = stories_total;
            rowCom.lot = formated_lot_size_square_feet;
            rowCom.dist = dist;
            rowCom.flags = flagsArray;
            rowCom.coe = formattedDate;
            rowCom.sp = price;
            rowCom.pricePerSqFt = pricePerSqFt;
            rowCom.tts = tts;
            rowCom.condition_rating = element.condition_rating;
            rowCom.apex_prop_type = parseInt(element.apex_prop_type);
            rowCom.units = units;
            rowCom.colorType = type;
            rowCom.isFinal = element.isFinal ? element.isFinal : false


            if (element.condition_rating) {
                if (tempComp[element.condition_rating] == null) {
                    tempComp[element.condition_rating] = rowCom;
                } else {
                    tempComp[element.condition_rating].push(rowCom)
                }
            }
            myCompObj.push(rowCom);

            // Prepare Markers Data
            tempMarker.id = element.id;
            tempMarker.name = element.address;
            tempMarker.position = { lat: element.latitude, lng: element.longitude };
            tempMarker.is_comp_entry_for_property = 0;
            tempMarker.label = `${index}`;
            tempMarker.condition_rating = element.condition_rating;
            tempMarker.sp = price;
            tempMarker.pricePerSqFt = pricePerSqFt;
            myCompMarkers.push(tempMarker);
            index++;
        });

        // Set label to markers
        let myCompsIds = otherData.myCompsIds;
        let bothCompsIds = otherData.bothCompsIds;

        myCompMarkers.sort((a, b) => b.condition_rating - a.condition_rating);
        for (let i = 0; i < myCompMarkers.length; i++) {
            if (i === 0) {
                continue;
            }

            myCompMarkers[i].label = `${i}`;
            if (myCompsIds.includes(`${myCompMarkers[i].id}`)) {
                myCompMarkers[i].color = `ccab00`;
            } else if (final_comps_ids.includes(`${myCompMarkers[i].id}`)) {
                myCompMarkers[i].color = `324cdd`;
            } else if (bothCompsIds.includes(`${myCompMarkers[i].id}`)) {
                myCompMarkers[i].color = `24a46d`;
            }
        }
        setMyCompMarker(myCompMarkers);
        setIsLoadMap(true);
        setCompsList(tempComp);
    }

    let myIndex = 0;
    let finalIndex = 0
    let handleSerialNumber = (type) => {
        if (type === 1) {
            if (myIndex === 0) {
                let firstCol = 'S';
                myIndex++;
                return firstCol;
            } else {
                myIndex++;
            }
            return myIndex - 1;
        } else if (type === 2) {
            if (finalIndex === 0) {
                let firstCol = 'S';
                finalIndex++;
                return firstCol;
            } else {
                finalIndex++;
            }
            return finalIndex - 1;
        }
    }

    let handleNull = (value) => {
        return (
            <>
                {value ? (
                    value
                ) : (<div>&nbsp;</div>)}
            </>
        )
    }

    let subjectProperty = (condition_id) => {
        let subjectName = '';
        if (condition_id === 8) {
            subjectName = 'Newly Built';
        } else if (condition_id === 7) {
            subjectName = 'Full Remodel';
        } else if (condition_id === 6) {
            subjectName = 'Partial Remodel';
        } else if (condition_id === 5) {
            subjectName = 'Maintained';
        } else if (condition_id === 4) {
            subjectName = 'Moderate';
        } else if (condition_id === 3) {
            subjectName = 'Poor';
        } else if (condition_id === 2) {
            subjectName = 'Very Poor';
        } else if (condition_id === 1) {
            subjectName = 'Unsalvageable';
        } else if (condition_id === 0) {
            subjectName = 'Subject Property';
        }
        return subjectName;
    }

    // On Click Marker MY Comp
    let onMarkerClick = (props, marker) => {
        setMapData({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    const markerLoadHandler = (label, color = '22caab') => {
        const icon = googleMapCustomMarker(label, color)
        return icon;
    };

    //  Map box close
    let onClose = () => {
        if (mapData.showingInfoWindow) {
            setMapData({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    /*
    | Handle Modal
    */
    let toggleDiscrepancyModal = (e, row) => {
        let newState = !isOpenDiscModal;
        if (newState) {
            setProcess(true);
            let postData = { property_comp_id: row.id, isFinal: row.isFinal };
            PostData("analysis-comparison/getCompDetails", postData).then(result => {
                let responseJson = result.data.data;

                propertyCompData.latitude = responseJson.latitude;
                propertyCompData.longitude = responseJson.longitude;
                propertyCompData.public_remarks = responseJson.public_remarks;
                propertyCompData.address = responseJson.address;
                propertyCompData.city = responseJson.city;
                propertyCompData.state = responseJson.state;
                propertyCompData.postal_code = responseJson.postal_code;
                propertyCompData.image_urls = responseJson.image_urls;
                let propertyImgs = responseJson.image_urls;
                if (propertyImgs) {
                    propertyImgs = propertyImgs.replace("[", "");
                    propertyImgs = propertyImgs.replace("]", "");
                    let isQoute = propertyImgs.indexOf('"');
                    if (isQoute !== -1) {
                        propertyImgs = JSON.parse("[" + propertyImgs + "]");
                    } else {
                        propertyImgs = propertyImgs.split(",");
                    }
                    propertyImgs = propertyImgs.filter(element => element !== "\"\"" && element !== "");

                    carouselSlider = (
                        <>
                            {propertyImgs.length > 0 ?
                                <Carousel >
                                    {propertyImgs.map((imageUrl, index) =>
                                        <div key={index}>
                                            <img src={imageUrl} alt={"img_" + index} loading="lazy" />
                                        </div>
                                    )}
                                </Carousel>
                                : <h2 className="not-authorized-msg">NO IMAGE DATA.</h2>}
                            {responseJson.public_remarks ? <p className="text-left">{responseJson.public_remarks}</p> : null}
                        </>
                    )
                } else {
                    carouselSlider = (
                        <>
                            <h2 className="not-authorized-msg">NO IMAGE DATA.</h2>
                            {responseJson.public_remarks ? <p className="text-left">{responseJson.public_remarks}</p> : null}
                        </>
                    );
                }

                if (propertyCompData.address) {
                    let zlinkData = propertyCompData.address + " " + propertyCompData.city + " " + propertyCompData.state + " " + propertyCompData.postal_code;
                    zlinkData = zlinkData.replace(/ /g, '-');
                    let zlink = "https://www.zillow.com/homes/" + zlinkData + "_rb/";
                    let redfin = "https://www.redfin.com/";
                    let realtor = "https://www.realtor.com/";
                    otherSite = (
                        <>
                            <div className="others-site">
                                <Row>
                                    <Col md="12">
                                        <a href={zlink} target="_blank" rel="noreferrer"> <img
                                            alt="Zillow"
                                            src={
                                                require("../../../assets/img/marketplace/zillow.png")
                                                    .default
                                            }
                                        /></a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <CopyToClipboard text={propertyCompData.address + ", " + propertyCompData.city + ", " + propertyCompData.state + ", " + propertyCompData.postal_code}>
                                            <a href={realtor} target="_blank" rel="noreferrer"> <img
                                                alt="realtor"
                                                src={
                                                    require("../../../assets/img/marketplace/realtor.png")
                                                        .default
                                                }
                                            /></a>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <CopyToClipboard text={propertyCompData.address + " " + propertyCompData.city + " " + propertyCompData.state + " " + propertyCompData.postal_code}>
                                            <a href={redfin} target="_blank" rel="noreferrer"> <img
                                                alt="realtor"
                                                src={
                                                    require("../../../assets/img/marketplace/redfin.png")
                                                        .default
                                                }
                                            /></a>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )
                    setOtherSite(otherSite);
                }
                setCarouselSlider(carouselSlider);
                setPropertyComps(propertyCompData);
                setIsLoadMap(true);
                setProcess(false);
            });
        }
        setIsOpenDiscModal(newState);
    };

    let handleChange = (e, type, position) => {
        let isChecked = e.target.checked;
        const updatedCheckedState = checkedState.map((item, index) => {
            if (index === position && isChecked) {
                return true
            } else if (index === position && !isChecked) {
                return false
            } else {
                return item;
            }
        });
        setCheckedState(updatedCheckedState);
        let myCompsIds = otherData.myCompsIds;
        let bothCompsIds = otherData.bothCompsIds;

        setButtonBlue("btn bg-blue");
        setButtonYellow("btn bg-yellow");
        setButtonGreen("btn bg-green");
        let compsArray = [];
        for (const [index, item] of updatedCheckedState.entries()) {
            if (index === 0 && item) {
                setButtonYellow("btn bg-yellow active");
                const myComps = compList.filter(item1 => myCompsIds.some(myCompsIds => parseInt(myCompsIds) === parseInt(item1.id)))
                compsArray = [...compsArray, ...myComps];
            }
            if (index === 2 && item) {
                setButtonBlue("btn bg-blue active");
                const finalComps = compList.filter(item1 => final_comps_ids.some(final_comps_ids => parseInt(final_comps_ids) === parseInt(item1.id)))
                compsArray = [...compsArray, ...finalComps];
            }
            if (index === 1 && item) {
                setButtonGreen("btn bg-green active");
                const bothComps = compList.filter(item1 => bothCompsIds.some(bothCompsIds => parseInt(bothCompsIds) === parseInt(item1.id)))
                handleCompsArray(bothComps, subjectPropertyData, type)
                compsArray = [...compsArray, ...bothComps];
            }
        }
        handleCompsArray(compsArray, subjectPropertyData, type)
    }

    let handleColorFlag = (val) => {
        let myCompsIds = otherData.myCompsIds;
        let bothCompsIds = otherData.bothCompsIds;
        if (myCompsIds.includes(`${val.id}`)) {
            return (
                <span className="span-yellow">&nbsp;</span>
            )
        } else if (final_comps_ids.includes(`${val.id}`)) {
            return (
                <span className="span-blue">&nbsp;</span>
            )
        } else if (bothCompsIds.includes(`${val.id}`)) {
            return (
                <span className="span-agree">&nbsp;</span>
            )
        } else if (val.isSubjectProperty === 1) {
            return (
                <span className="span-red"></span>
            )
        }
    }

    let handleTableRow = (conditionRating, type = 1, compsList) => {
        if (Array.isArray(compsList[conditionRating])) {
            const petList = Object.entries(compsList[conditionRating]).map(([key, val]) => {
                return (
                    <tr key={key}>
                        <td>{handleSerialNumber(type)}</td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.address)}</span></td>
                        <td>{handleColorFlag(val)}</td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.above)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.below)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.total)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.beds)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.baths)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.year_built)}</span></td>
                        {subjectPropertyComp.apex_prop_type !== 3 ? (
                            <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.stories)}</span></td>
                        ) : (null)}
                        {subjectPropertyComp.apex_prop_type !== 3 ? (
                            <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.lot)}</span></td>
                        ) : (null)}
                        {subjectPropertyComp.apex_prop_type === 4 ? (
                            <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.units)}</span></td>
                        ) : (null)}
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.dist)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleFlags(val.flags)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.coe)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.sp)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.pricePerSqFt)}</span></td>
                        <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{handleNull(val.tts)}</span></td>
                    </tr>
                );

            });
            return petList;
        }
    };

    let ratingKeys = [0, 8, 7, 6, 5, 4, 3, 2, 1];
    let myCompTable = Object.entries(ratingKeys).map(([key, val]) => (
        <Table striped key={key}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>{subjectProperty(parseInt(val))}</th>
                    <th>&nbsp;</th>
                    <th>Above</th>
                    <th>Below</th>
                    <th>Total</th>
                    <th>Beds</th>
                    <th>Baths</th>
                    <th>Year</th>
                    {subjectPropertyComp.apex_prop_type !== 3 ? (
                        <th>Stories</th>
                    ) : (null)}
                    {subjectPropertyComp.apex_prop_type !== 3 ? (
                        <th>Lot</th>
                    ) : (null)}
                    {subjectPropertyComp.apex_prop_type === 4 ? (
                        <th>Units</th>
                    ) : (null)}
                    <th>Dist</th>
                    <th>Flags</th>
                    <th>COE</th>
                    <th>SP</th>
                    <th>$/sqft</th>
                    <th>TTS</th>
                </tr>
            </thead>
            <tbody>
                {handleTableRow(val, 1, compsList)}
            </tbody>
        </Table >
    ));
    let propDataForCompList = {
        propertyImgs: propertyComps.image_urls,
        image_urls: propertyComps.image_urls,
        public_remarks: propertyComps.public_remarks,
        address: propertyComps.address,
        city: propertyComps.city,
        state: propertyComps.state,
        postal_code: propertyComps.postal_code,
        customTabListStyle: { padding: "6px 26px" },
        contextMenu: true,
        google: google_prop,
        zoom: subjectPropertyComp.zoom_level,
        mapStyles,
        latitude: propertyComps.latitude,
        longitude: propertyComps.longitude,
        onMarkerClick: onMarkerClick,
        markerName: propertyComps.address + ", " + propertyComps.city + ", " + propertyComps.state + ", " + propertyComps.postal_code,
        infoWindowMarker: mapData.activeMarker,
        infoWindowVisible: mapData.showingInfoWindow,
        infoWindowOnClose: onClose,
        selectedPlace: mapData.selectedPlace,
        openModel: isLoadMap
    }
    return (
        <>
            <Spinner isShow={isProcess} />
            <Row>
                {compsList.length > 0 ? (
                    <div className="col">
                        <div className="mt-3 comp-list">
                            {(otherData.searchParams.submittedTabText || otherData.searchParams.finalTabText || otherData.mySearchCompTypes || otherData.finalSearchCompTypes) &&
                                <Col lg="12" xl="12">
                                    <Row>
                                        <Col className="md-6">
                                            <p><span className="h4">My Parameters:</span> {otherData.searchParams.submittedTabText} {otherData.mySearchCompTypes}</p>
                                        </Col>
                                        <Col className="md-6">
                                            <p><span className="h4">Final Parameters:</span> {otherData.searchParams.finalTabText} {otherData.finalSearchCompTypes}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col lg="12" xl="12">
                                <Row className="myMapDiv">
                                    {isLoadMap ? (
                                        <GMap
                                            className="custom-map-controls"
                                            contextMenu={true}
                                            google={google_prop}
                                            zoom={subjectPropertyComp.zoom_level}
                                            style={mapStyles}
                                            zoomControlOptions={{
                                                position: google_prop.maps.ControlPosition.BOTTOM_RIGHT
                                            }}
                                            initialCenter={
                                                { lat: subjectPropertyComp.position.lat, lng: subjectPropertyComp.position.lng }
                                            }
                                        >
                                            {myCompMarker.map(({ id, name, position, label, sp, pricePerSqFt, color }) => (
                                                <Marker
                                                    onClick={onMarkerClick}
                                                    key={id}
                                                    title={name}
                                                    position={position}
                                                    sp={sp}
                                                    pricePerSqFt={pricePerSqFt}
                                                    icon={markerLoadHandler(label, color)}
                                                    name={name} >
                                                </Marker>

                                            ))}
                                            <InfoWindow
                                                marker={mapData.activeMarker}
                                                visible={mapData.showingInfoWindow}
                                                onClose={onClose}>
                                                <div className="map-link">
                                                    {mapData.selectedPlace &&
                                                        <>
                                                            <a href={"https://www.google.com/maps/place/" + mapData.selectedPlace.name + "/@" + mapData.selectedPlace.position?.lat + "," + mapData.selectedPlace.position?.lng + ",20z"} target="_blank" rel="noreferrer">
                                                                <p>{mapData.selectedPlace.title}</p>
                                                            </a>
                                                            {mapData.selectedPlace.sp ? (
                                                                <>
                                                                    <p>{(mapData.selectedPlace.pricePerSqFt && mapData.selectedPlace.pricePerSqFt !== 'N/A') ? `${mapData.selectedPlace.pricePerSqFt}/sqft` : ''}</p>
                                                                    <p>{mapData.selectedPlace.sp ?? ''}</p>
                                                                </>
                                                            ) : (
                                                                null
                                                            )}
                                                        </>
                                                    }

                                                </div>
                                            </InfoWindow>
                                        </GMap>
                                    ) : (
                                        null
                                    )}
                                </Row>
                            </Col>
                            <Col lg="12" xl="12">
                                {otherData?.category_type === "comp-selection" &&
                                    <Row>
                                        <Col className="mb-3 filter-buttons">
                                            <input type="checkbox" className="btn-check" id="btn-check1" autoComplete="off" checked={checkedState[0]} onChange={e => handleChange(e, 'my', 0)} />
                                            <label className={buttonYellow} htmlFor="btn-check1"> Only Mine</label>

                                            <input type="checkbox" className="btn-check" id="btn-check2" autoComplete="off" checked={checkedState[1]} onChange={e => handleChange(e, 'both', 1)} />
                                            <label className={buttonGreen} htmlFor="btn-check2">Both Agree</label>

                                            <input type="checkbox" className="btn-check" id="btn-check3" autoComplete="off" checked={checkedState[2]} onChange={e => handleChange(e, 'final', 2)} />
                                            <label className={buttonBlue} htmlFor="btn-check3">Only Final</label>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    {myCompTable}
                                </Row>
                            </Col>
                        </div>
                        <CardFooter className="py-4">
                        </CardFooter>
                    </div>
                ) : (
                    <Col lg="12" xl="12">
                        <h2 className="not-authorized-msg">Insufficient data</h2>
                    </Col>
                )}
            </Row>

            <Modal className="modal-dialog-centered modal-lg condition-rating-modal" isOpen={isOpenDiscModal} toggle={() => toggleDiscrepancyModal(isOpenDiscModal)}>
                <div className="modal-header">
                    <h2 className="modal-title text-left w-100" id="discrepancy_comp">
                        Comp Photos: {propertyComps.address + ", " + propertyComps.city + ", " + propertyComps.state + ", " + propertyComps.postal_code}
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleDiscrepancyModal(isOpenDiscModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-center">
                    <div className="col-md-12">
                        <div className="others-column-body">
                            {isLoadMap ? (
                                <CompList props={propDataForCompList} />
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(CompsList);