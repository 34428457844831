// import eventBus from "../Common/EventBus";

// this.handleEvent = this.handleEvent.bind(this);


// /*
// | Call before component render on the dom
// */
// componentWillMount() { };

// /*
// | Call after component render on the dom
// */
// componentDidMount() {
//     eventBus.on("on_report_order_status_updated", this.handleEvent);
//     this.getOrderList();
// };

// /*
// | Call before component delete/leave from dom
// */
// componentWillUnmount() {
//     eventBus.remove("on_report_order_status_updated", this.handleEvent);
// };

// handleEvent() {
//     console.log("Cancelled Tab ======",);
//     this.getOrderList();
// };

// eventBus.dispatch("report_order_status_updated", { client_id: company_id });

import { loggedInUser } from "../../../components/Common/Helpers";
import socketIOClient from "socket.io-client";

const userData = loggedInUser();
let socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const eventBus = {
    on(event, callback) {
        document.addEventListener(event, (callback), true);
    },
    dispatch(event, data) {
        if (event === 'update_supplemental_status' || event === 'is_logout' || event === 'is_login') {
            socket.emit(event, data, (response) => { });
            document.dispatchEvent(new CustomEvent(event, { detail: data }));
        } else if (['report_order_status_updated', 'user_status_updated', 'inspection_order_status_updated', 'discrepancy_task_status_updated', 'invoice_status_updated'].includes(event)) {
            socket.emit(event, data, (response) => { });
            document.dispatchEvent(new CustomEvent("on_" + event, { detail: data }));
        } else {
            document.dispatchEvent(new CustomEvent(event, { detail: data }));
        }
    },
    remove(event, callback) {
        document.removeEventListener(event, callback, true);
    },
};

socket.on("get_status", (response) => {
    eventBus.dispatch("on_report_order_status_updated", response);
});

socket.on("get_supplemental_status", (response) => {
    eventBus.dispatch("get_supplemental_status", response);
});

socket.on("get_login", (response) => {
    eventBus.dispatch("get_login", response);
});

// Report order status updated event
let reportOrderStatusChangeEventName;
if (userData.is_RV_user === true) {
    // Checking if the logged in user is a RV user (user.company == setting.company), then listen all socket event
    reportOrderStatusChangeEventName = `on_report_order_status_updated`;
} else {
    // Otherwise only listen current companies socket event.
    reportOrderStatusChangeEventName = `on_report_order_status_updated_${userData.company_id}`;
}

socket.on(reportOrderStatusChangeEventName, (response) => {
    eventBus.dispatch('on_report_order_status_updated', response);
});

// Invoice status updated event
let invoiceStatusChangeEventName = userData.is_RV_user === true ? `on_invoice_status_updated` : `on_invoice_status_updated_${userData.company_id}`;

socket.on(invoiceStatusChangeEventName, (response) => {
    eventBus.dispatch('on_invoice_status_updated', response);
});

// User status updated event\
let userStatusChangeEventName;
if (userData.is_RV_user === true) {
    // Checking if the logged in user is a RV user (user.company == setting.company), then listen all socket event
    userStatusChangeEventName = `on_user_status_updated`;
} else {
    // Otherwise only listen current companies socket event.
    userStatusChangeEventName = `on_user_status_updated_${userData.company_id}`;
}

socket.on(userStatusChangeEventName, (response) => {
    eventBus.dispatch('on_user_status_updated', response);
});

// User status updated event\
let inspectionOrderStatusChangeEventName;
if (userData.is_RV_user === true) {
    // Checking if the logged in user is a RV user (user.company == setting.company), then listen all socket event
    inspectionOrderStatusChangeEventName = `on_inspection_order_status_updated`;
} else {
    // Otherwise only listen current companies socket event.
    inspectionOrderStatusChangeEventName = `on_inspection_order_status_updated_${userData.company_id}`;
}

socket.on(inspectionOrderStatusChangeEventName, (response) => {
    eventBus.dispatch('on_inspection_order_status_updated', response);
});

// Discrepancy task status updated event\
let discrepancyTaskStatusChangeEventName;
if (userData.is_RV_user === true) {
    // Checking if the logged in user is a RV user (user.company == setting.company), then listen all socket event
    discrepancyTaskStatusChangeEventName = `on_discrepancy_task_status_updated`;
} else {
    // Otherwise only listen current companies socket event.
    discrepancyTaskStatusChangeEventName = `on_discrepancy_task_status_updated_${userData.company_id}`;
}

socket.on(discrepancyTaskStatusChangeEventName, (response) => {
    eventBus.dispatch('on_discrepancy_task_status_updated', response);
});

// Budget extractions event for valuation
socket.on("budget_extraction_order_updated", (response) => {
    eventBus.dispatch('budget_extraction_order_updated', response);
});

// Budget extractions event for standalone
socket.on("budget_extraction_standalone_updated", (response) => {
    eventBus.dispatch('budget_extraction_standalone_updated', response);
});

export default eventBus;