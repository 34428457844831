import React from "react";

const TR = ({ propTdContent = [], customStyle = "" }) => {

    return (
        <>
            <tr className={customStyle}>
                {propTdContent.length > 0 && propTdContent.map((data, i) => (
                    <td key={i} >{data}</td>
                ))
                }
            </tr>
        </>
    );
}
export default TR;
