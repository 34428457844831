import { createSlice } from '@reduxjs/toolkit'

export const DisputeOrderReducer = createSlice({
    name: 'disputeOrder',
    initialState: {
        asIsComps: [],
        arvComps: [],
    },
    reducers: {
        setAsIsComps: (state, action) => {
            state.asIsComps = action.payload;
        },
        setArvComps: (state, action) => {
            state.arvComps = action.payload;
        },
        clearComps: (state, action) => {
            state.arvComps = [];
            state.asIsComps = []
        },
    },
})

// Action creators are generated for each case reducer function
export const { setAsIsComps, setArvComps, clearComps } = DisputeOrderReducer.actions

export default DisputeOrderReducer.reducer;
