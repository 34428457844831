export const getLabelName = function (grade, qualityRatings) {
    let label;
    if (qualityRatings.length) {
        const filter = qualityRatings.find(item => parseFloat(item.quality_rating_score) <= grade);
        if (filter) {
            label = filter.building_quality_rating;
        }
    }
    return label;
}

export const between = function (number, calc) {
    let [a, b] = calc;
    let min = Math.min(a, b), max = Math.max(a, b);
    return number >= min && number <= max;
}

export const getColorCode = (qualityRatings, gradeLabel) => {
    var item = qualityRatings.find(item => item.building_quality_rating === gradeLabel);
    if (item) {
        return item.perf_color_code;
    }
    return false;
}

export const average = function (array) {
    if (array.length) {
        return array.reduce((a, b) => a + b) / array.length;
    }
    return 0;
};

export const uniqueById = function (array) {
    let uniqueData = [];
    if (array.length) {
        uniqueData = [...array.reduce((mapObj, obj) => mapObj.set(obj.id, obj), new Map()).values()];
    }
    return uniqueData;
}

export const getConditionRatingName = function (ratingRecords, currentRating, image = true) {
    var item = ratingRecords.find(item => item.rate === parseInt(currentRating));
    let imageName = '';
    let imageSrc = '';
    if (item) {
        if (image) {
            if (item.rate === 8) {
                imageSrc = require("../../../assets/img/conditions/new-build-purple.png").default;
                imageName = `<img src="${imageSrc}" alt="Newly built" />`;
            } else if (item.rate === 7) {
                imageSrc = require("../../../assets/img/conditions/full-remodel.png").default;
                imageName = `<img src="${imageSrc}" alt="Fully Remodeled" />`
            } else if (item.rate === 6) {
                imageSrc = require("../../../assets/img/conditions/partial-remodel.png").default;
                imageName = `<img src="${imageSrc}" alt="Partially Remodeled"  />`
            } else if (item.rate === 5) {
                imageSrc = require("../../../assets/img/conditions/maintained.png").default;
                imageName = `<img src="${imageSrc}" alt="Maintained" />`
            } else if (item.rate === 4) {
                imageSrc = require("../../../assets/img/conditions/moderate.png").default;
                imageName = `<img src="${imageSrc}" alt="Moderate" />`
            } else if (item.rate === 3) {
                imageSrc = require("../../../assets/img/conditions/poor.png").default;
                imageName = `<img src="${imageSrc}" alt="Poor" />`
            } else if (item.rate === 2) {
                imageSrc = require("../../../assets/img/conditions/very-poor.png").default;
                imageName = `<img src="${imageSrc}" alt="Very Poor" />`
            } else if (item.rate === 1) {
                imageSrc = require("../../../assets/img/conditions/unsalvageable-grey.png").default;
                imageName = `<img src="${imageSrc}" alt="Unsalvageable" />`
            }
            return imageName;
        } else {
            return item.name;
        }

    }
    return '-';
}

export const getTargetCondition = function (subjectCondition, currentRating) {
    let labelsArray = []
    if (subjectCondition === 1) {
        labelsArray = [
            {
                label: 'As Is',
                value: 1
            }, {
                label: 'Low',
                value: 2
            }, {
                label: 'Med',
                value: 3
            }, {
                label: 'High',
                value: 4
            }];
    } else {
        labelsArray = [
            {
                label: 'As Is',
                value: 1
            }, {
                label: 'Min',
                value: 2
            }, {
                label: 'Partial',
                value: 3
            }, {
                label: 'Full',
                value: 4
            }, {
                label: 'Value add',
                value: 5
            }];
    }
    var item = labelsArray.find(item => item.value === parseInt(currentRating));
    if (item) {
        return item.label;
    }
    return '-';
}

export const handleTableHeader = function (condition_rating) {
    if (condition_rating !== 1) {
        return (
            <>
                <th>Min</th>
                <th>Partial</th>
                <th>Full</th>
            </>
        )
    } else {
        return (
            <>
                <th>Low</th>
                <th>Med</th>
                <th>High</th>
            </>
        )
    }
}