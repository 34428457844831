import { createSlice } from '@reduxjs/toolkit'

export const epoOrdersListReducer = createSlice({
    name: 'epoOrdersList',
    initialState: {
        counts: {
            singles: 0,
            batches: 0,
            active_payment: 0,
            inprogress: 0,
            completed: 0
        },
        currentTab: 'single',
        currentTabIndex: 0,
        subTab: 'active_payment',
        subTabIndex: 0,
    },
    reducers: {
        setCount: (state, params) => {
            state.counts = params.payload.value;
        },
        setCurrentTab: (state, params) => {
            state.currentTab = params.payload.value;
        },
        setCurrentTabIndex: (state, params) => {
            state.currentTabIndex = params.payload.value;
        },
        setSubTab: (state, params) => {
            state.subTab = params.payload.value;
        },
        setSubTabIndex: (state, params) => {
            state.subTabIndex = params.payload.value;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCount, setCurrentTab, setCurrentTabIndex, setSubTab, setSubTabIndex } = epoOrdersListReducer.actions

export default epoOrdersListReducer.reducer