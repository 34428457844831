import { checkRoles } from "components/Common/Helpers";
import { checkRolesWithLevel } from "components/Common/Helpers";
import { number_format } from "components/Common/Helpers";
import React, { useEffect, useState } from "react";
import { Col, Table, UncontrolledTooltip } from "reactstrap";
import { getTargetCondition } from "./AnalysisComparisonHelper";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import InspectionCheck from "./InspectionCheck";

const TargetCondition = ({ onData, ratingRecords, otherData, propWaivedResult }) => {
    const [targetConditionData, setTargetConditionData] = useState({});

    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "target-condition") {
                handleTargetCondition(onData.data);
            }
        }
        //eslint-disable-next-line
    }, [onData, ratingRecords]);

    let handleTargetCondition = (data) => {
        let other_details = JSON.parse(data.other_details);
        let details = JSON.parse(data.details);
        let dataArray = {
            my_condition: other_details.my_condition,
            final_condition: other_details.final_condition,
            subjectCondition: details.subjectCondition,
            grade: number_format(data.grade, 2),
        }
        setTargetConditionData(dataArray);
        otherData.category_type = onData.type;
    }

    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
    }

    const targetConditionTable = (
        <Table striped>
            <tbody>
                <tr>
                    <td>My Condition</td>
                    <td>
                        <span role="button" id="mySub_1" dangerouslySetInnerHTML={{ __html: getTargetCondition(parseFloat(targetConditionData.subjectCondition), parseFloat(targetConditionData.my_condition)) }}>
                        </span>
                        <UncontrolledTooltip delay={0} placement="auto" target="mySub_1" >{getTargetCondition(parseFloat(targetConditionData.subjectCondition), parseFloat(targetConditionData.my_condition))}</UncontrolledTooltip>
                    </td>
                </tr>
                <tr>
                    <td>Final Condition</td>
                    <td>
                        <span role="button" id="mySub_2" dangerouslySetInnerHTML={{ __html: getTargetCondition(parseFloat(targetConditionData.subjectCondition), parseFloat(targetConditionData.final_condition)) }}>
                        </span>
                        <UncontrolledTooltip delay={0} placement="auto" target="mySub_2" >{getTargetCondition(parseFloat(targetConditionData.subjectCondition), parseFloat(targetConditionData.final_condition))}</UncontrolledTooltip>
                    </td>
                </tr>
                <tr>
                    <td>Grade</td>
                    <td>{targetConditionData.grade}</td>
                </tr>
            </tbody>
        </Table>
    );

    return (
        <>
            {otherData.category_type === "target-condition" &&
                <Col xl="12">
                    <p className="h3 float-left w-100"><u>Target Condition</u></p>
                    <InspectionCheck otherData={otherData} />
                    {targetConditionTable}
                    {(checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col>
            }
        </>
    );
}
export default TargetCondition;