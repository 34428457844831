import React from "react";
import { } from "react-router-dom";
import {
  Navbar,
  Container
} from "reactstrap";

const AdminNavbar = () => {
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
