import React, { useState } from "react";
import { toast } from 'react-toastify';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { PostData } from "../../services/PostData";
import Spinner from "components/Common/Spinner.js";
import { Button, Card, CardBody, FormGroup, Col, Row } from "reactstrap";
const moment = require('moment'); 
moment.tz.setDefault("America/phoenix");

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [isProcess, setProcess] = useState(false);

  const emailChangeHandle = (e) => {
    let email = e.target.value;
    setEmail(email);
  };

  // Handle forget Password  Request
  const handleForgetPassword = () => {

    setProcess(true);
    let data = {
      email: email,
      date: moment().format()
    };

    PostData("auth/forget_password", { data: data }).then(result => {

      let responseJson = result.data;

      if (responseJson.status === 200) {
        setProcess(false);
        toast["success"](responseJson.message);
      } else {
        setProcess(false);
        toast["error"](responseJson.message);
      }
    }).catch(errors => {
      setProcess(false);
      toast["error"](errors.message);
    });
  };

    return (
      <>
        <Col lg="5" md="7">
          {/* Loader Spinner */}
          <Spinner isShow={isProcess} />

          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center mb-4">
                <b>Forgot Password?</b>
              </div>
              <AvForm  onValidSubmit={handleForgetPassword}>
                <AvField placeholder="Enter your email here to reset your password" onChange={(e) => emailChangeHandle(e)} required name="email" label="Email" type="text" validate={{email: true}} />
                  <div className="text-center">
                    <FormGroup>
                      <Button className="" color="primary">
                         Send Password Reset Link
                      </Button>
                    </FormGroup>
                </div>                          
              </AvForm>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="login"
              >
                <small> Login</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="retrieve-username"
              >
                <small>Retrieve Username</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  };
export default ForgetPassword;