import { createSlice } from '@reduxjs/toolkit'

export const ShowHideSpinnerReducer = createSlice({
    name: 'ShowHideSpinner',
    initialState: {
        currentStat: false
    },
    reducers: {
        showSpinner: (state, params) => {
            state.currentStat = params.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { showSpinner } = ShowHideSpinnerReducer.actions

export default ShowHideSpinnerReducer.reducer