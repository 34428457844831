import React from 'react';
import { loggedInUser } from "components/Common/Helpers.js"
import { checkRolesWithLevel, checkRoles } from 'components/Common/Helpers';
/* Client Routes */

const Dashboard = React.lazy(() => import('views/modules/ClientPortal/Dashboard/Index.js'));

const OrderListLanding = React.lazy(() => import('views/modules/ClientPortal/OrderManagement/OrderListLanding'));

const OrderManagementSearch = React.lazy(() => import('views/modules/ClientPortal/OrderManagementSearch/SearchResult'));
const Team = React.lazy(() => import('views/modules/ClientPortal/Team/Team.js'));
const PaymentMethod = React.lazy(() => import('views/modules/ClientPortal/PaymentMethod/Index.js'));
const BorrowerPublicPaymentPage = React.lazy(() => import('views/modules/ClientPortal/OrderIntake/BorrowerPublicPaymentPage'));
const ReportOrderPublicPaymentPage = React.lazy(() => import('views/modules/ClientPortal/OrderIntake/ReportOrderPublicPaymentPage'));
const ChangePassword = React.lazy(() => import('views/modules/ChangePassword/index.js'));
const TransferOrderPublicPaymentPage = React.lazy(() => import('views/modules/ClientPortal/OrderIntake/TransferOrderPublicPaymentPage.js'));
const InvoicePrint = React.lazy(() => import('views/modules/Public/InvoicePrint.js'));
const Invoice = React.lazy(() => import('views/modules/ClientPortal/Accounting/Index.js'));

const PlaceNewOrderLanding = React.lazy(() => import('views/modules/PlaceNewOrder/Landing'));
const OrderIntake = React.lazy(() => import('views/modules/ClientPortal/OrderIntake/Index'));
const InspectionOrderIntake = React.lazy(() => import('views/modules/InspectionOrderIntake/InspectionOrderIntake'));
const EpoOrderIntake = React.lazy(() => import('views/modules/EpoOrderIntake/EpoOrderIntake'));
const EditEpoOrderIntake = React.lazy(() => import('views/modules/EpoOrderIntake/EditEpoOrderIntake'));
const EditInspectionOrderIntake = React.lazy(() => import('views/modules/InspectionOrderIntake/EditInspectionOrderIntake'));
const InspectionPaymentPublicPage = React.lazy(() => import('views/modules/InspectionOrderIntake/InspectionPaymentPublicPage'));

const userData = loggedInUser();


var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    // icon: "ni ni-tv-2 text-primary",
    iconImage: require('./assets/img/img-icons/business-report.png').default,
    component: Dashboard,
    layout: "/client",
    is_visible: true,
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-bullet-list-67 text-info",
    component: OrderListLanding,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
  },
  {
    path: "/invoicing",
    name: "Accounting",
    icon: "fas fa-calculator text-warning",
    component: Invoice,
    layout: "/client",
    is_visible: (userData && userData.user_id && userData.company.report_invoicing === "1" && checkRoles(['CUSTOMER_ACCOUNTING', 'MODERATOR']) ? true : false)
  },
  {
    path: "/team",
    name: "Team",
    // icon: "ni ni-circle-08 text-success",
    iconImage: require('./assets/img/img-icons/team.png').default,
    component: Team,
    layout: "/client",
    is_visible: (userData && userData.user_id && checkRolesWithLevel(['MODERATOR']) ? true : false)
  },
  {
    path: "/place-new-order",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    // iconImage: require('./assets/img/img-icons/order.png').default,
    component: PlaceNewOrderLanding,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
  },
  {
    path: "/order-intake/:intake_batch_id",
    name: "Place New Order",
    component: OrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/order-intake",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: OrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/inspection-order",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: InspectionOrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/:inspection_intake_batch_token/:company_id/edit-intake-data",
    name: "Inspection Order",
    icon: "ni ni-basket text-success",
    component: EditInspectionOrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/payment-methods",
    name: "Payment Methods",
    icon: "ni ni-credit-card text-danger",
    component: PaymentMethod,
    layout: "/client",
    is_visible: (userData.oms_access === 1 && checkRolesWithLevel(['MODERATOR'])) ? true : false,
  },
  {
    path: "/search",
    name: "Search Results",
    icon: "ni ni-key-25 text-info",
    component: OrderManagementSearch,
    layout: "/client",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/report-payment/:id",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: BorrowerPublicPaymentPage,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/transfer-order-payment/:id",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: TransferOrderPublicPaymentPage,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/report-order-payment/:intake_token/:company_token",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: ReportOrderPublicPaymentPage,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/inspection-payment/:intake_token",
    name: "Payment",
    icon: "ni ni-basket text-success",
    component: InspectionPaymentPublicPage,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/invoice-print/:id/:token_id",
    name: "Invoice",
    component: InvoicePrint,
    layout: "/public",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "ni ni-key-25 text-info",
    component: ChangePassword,
    layout: "/client",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/epo-order/:epoIntakeBatchToken/:companyId/edit-intake-data",
    name: "Edit Epo Order",
    icon: "ni ni-basket text-success",
    component: EditEpoOrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  },
  {
    path: "/epo-order",
    name: "Place Epo Order",
    icon: "ni ni-basket text-success",
    component: EpoOrderIntake,
    layout: "/client",
    is_visible: (userData.oms_access === 1) ? true : false,
    is_show_label: false
  }
];

export default routes;