import { checkRolesWithLevel } from "components/Common/Helpers";
import { checkRoles } from "components/Common/Helpers";
import { number_format } from "components/Common/Helpers";
import React, { useEffect, useState } from "react";
import { Col, Table } from "reactstrap";
import { handleTableHeader } from "./AnalysisComparisonHelper";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import InspectionCheck from "./InspectionCheck";

const RehabCurve = ({ onData, otherData, propWaivedResult }) => {
    const [rehabCurveData, setRehabCurveData] = useState({});
    const [extraColumns, setExtraColumns] = useState([]);

    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "rehab-curve") {
                handleRehabCurve(onData.data);
            }
        }
        //eslint-disable-next-line
    }, [onData]);

    let handleRehabCurve = (data) => {
        let other_details = JSON.parse(data.other_details);

        let min_diffrence = 0;
        let partial_diffrence = 0;
        let full_diffrence = 0;
        let valueAdd_diffrence = 0;
        let min_percentage = 0;
        let partial_percentage = 0;
        let full_percentage = 0
        let valueAdd_percentage = 0

        if (other_details) {
            if (other_details.my_valueadd_rehab || other_details.final_valueadd_rehab) {
                setExtraColumns({ valueAdd: true })
            }

            min_diffrence = Math.abs(other_details.final_min_rehab - other_details.my_min_rehab)
            partial_diffrence = Math.abs(other_details.final_partial_rehab - other_details.my_partial_rehab)
            full_diffrence = Math.abs(other_details.final_full_rehab - other_details.my_full_rehab)
            valueAdd_diffrence = Math.abs(other_details.final_valueadd_rehab - other_details.my_valueadd_rehab)

            other_details.min_diffrence = number_format(min_diffrence);
            other_details.partial_diffrence = number_format(partial_diffrence);
            other_details.full_diffrence = number_format(full_diffrence);
            other_details.valueAdd_diffrence = number_format(valueAdd_diffrence);

            min_percentage = (min_diffrence / other_details.final_min_rehab) * 100;
            partial_percentage = (partial_diffrence / other_details.final_partial_rehab) * 100;
            full_percentage = (full_diffrence / other_details.final_full_rehab) * 100;
            valueAdd_percentage = (valueAdd_diffrence / other_details.final_valueadd_rehab) * 100;

            other_details.min_percentage = number_format(min_percentage, 2);
            other_details.partial_percentage = number_format(partial_percentage, 2);
            other_details.full_percentage = number_format(full_percentage, 2);
            other_details.valueAdd_percentage = number_format(valueAdd_percentage, 2);
        }
        other_details.grade = data.grade;
        other_details.condition_rating = parseInt(other_details.condition_rating)
        setRehabCurveData(other_details);
        otherData.category_type = onData.type;
    }

    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
    }

    const rehabCurveTable = (
        <Table striped className="analysis-table">
            <thead>
                <tr>
                    <th>Metric</th>
                    {rehabCurveData && handleTableHeader(rehabCurveData.condition_rating)}
                    {extraColumns.valueAdd &&
                        <th>Value Add</th>
                    }
                </tr>
            </thead>
            <tbody>
                {rehabCurveData ? (
                    <>
                        <tr>
                            <td>My Values</td>
                            <td>${number_format(rehabCurveData.my_min_rehab, 2)}</td>
                            <td>${number_format(rehabCurveData.my_partial_rehab, 2)}</td>
                            <td>${number_format(rehabCurveData.my_full_rehab, 2)}</td>
                            {extraColumns.valueAdd &&
                                <td>${number_format(rehabCurveData.my_valueadd_rehab, 2)}</td>
                            }
                        </tr>
                        <tr>
                            <td>Final Values</td>
                            <td>${number_format(rehabCurveData.final_min_rehab)}</td>
                            <td>${number_format(rehabCurveData.final_partial_rehab)}</td>
                            <td>${number_format(rehabCurveData.final_full_rehab)}</td>
                            {extraColumns.valueAdd &&
                                <td>${number_format(rehabCurveData.final_valueadd_rehab)}</td>
                            }
                        </tr>
                        <tr>
                            <td>Difference</td>
                            <td>${rehabCurveData.min_diffrence}</td>
                            <td>${rehabCurveData.partial_diffrence}</td>
                            <td>${rehabCurveData.full_diffrence}</td>
                            {extraColumns.valueAdd &&
                                <td>${rehabCurveData.valueAdd_diffrence}</td>
                            }
                        </tr>
                        <tr>
                            <td>Percentage</td>
                            <td>{rehabCurveData.min_percentage}%</td>
                            <td>{rehabCurveData.partial_percentage}%</td>
                            <td>{rehabCurveData.full_percentage}%</td>
                            {extraColumns.valueAdd &&
                                <td>{rehabCurveData.valueAdd_percentage}%</td>
                            }
                        </tr>
                        <tr>
                            <td>Grade</td>
                            <td>{number_format(rehabCurveData.min_grade, 2)}</td>
                            <td>{number_format(rehabCurveData.partial_grade, 2)}</td>
                            <td>{number_format(rehabCurveData.full_grade, 2)}</td>
                            {extraColumns.valueAdd &&
                                <td>{number_format(rehabCurveData.valueAdd_grade, 2)}</td>
                            }
                        </tr>
                        <tr>
                            <td>Final Grade</td>
                            <td>{number_format(rehabCurveData.grade, 2)}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            {extraColumns.valueAdd &&
                                <td>&nbsp;</td>
                            }
                        </tr>
                    </>
                ) : (
                    <tr>
                        <td colSpan={5}>Insufficient data</td>
                    </tr>
                )}
            </tbody>
        </Table >
    );

    return (
        <>
            {otherData.category_type === "rehab-curve" &&
                <Col xl="12" >
                    <p className="h3 float-left w-100"><u>Rehab Curve</u></p>
                    <InspectionCheck otherData={otherData} />
                    {rehabCurveTable}
                    {(checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col>
            }
        </>
    );

}

export default RehabCurve;