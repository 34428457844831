// SlidingPanel.js
import React from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const TableSkeleton = ({ rows, cols }) => {

    return (
        <>
            <SkeletonTheme
                // baseColor="#5294e0"
                // highlightColor="#96c7ff"
                borderRadius="0.5rem"
                duration={4}
            >
                <table className="table">
                    <tbody>
                        {Array.from({ length: rows }, (_, i) => (
                            <tr key={i}>
                                {Array.from({ length: cols }, (_, index) => (
                                    <td key={index}>{<Skeleton />}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </SkeletonTheme>
        </>
    );
};

export default TableSkeleton;