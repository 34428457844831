import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";
import ClientLayout from "layouts/Client";
import PublicLayout from "layouts/Public";

import { Provider } from "react-redux";

import store from "./redux/store";

let systemSettings = localStorage.getItem('system-settings');
systemSettings = systemSettings ? JSON.parse(systemSettings) : { isCollapsedSideBar: false }

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ToastContainer position="bottom-left" />
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} systemSettings={systemSettings} />} />
        <Route path="/client" render={(props) => <ClientLayout {...props} systemSettings={systemSettings} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/public" render={(props) => <PublicLayout {...props} />} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);