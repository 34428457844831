import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { UncontrolledTooltip } from "reactstrap";
import "./style.css";

const RenderBootstrapTable = (props) => {

    let tableOptions = {
        sizePerPageList: [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            { text: "50", value: 50 },
            { text: "100", value: 100 }
        ],
        page: props.tableParams.currentPage,
        sizePerPage: props.tableParams.recordsPerPage,
        onPageChange: props.tableParams.onPageChangeEvent,
        onSortChange: props.tableParams.onSortChangeEvent,
        paginationShowsTotal: props.tableParams.paginationTotalText,
        paginationSize: 3,
        prePage: <i className="fas fa-angle-left btn-sm"></i>,
        nextPage: <i className="fas fa-angle-right btn-sm"></i>,
        firstPage: <i className="fas fa-angle-double-left btn-sm"></i>,
        lastPage: <i className="fas fa-angle-double-right btn-sm"></i>,
        alwaysShowAllBtns: true,
        defaultSortName: props.tableParams.sortFieldName,
        defaultSortOrder: props.tableParams.sortDirection,
    };

    let tableStyle = {};
    let headerContainerClass = ""
    let maxHeight = "300px"
    if (props.scroll) {
        tableStyle = { overflowX: 'hidden', overflowY: 'auto', maxHeight }
        headerContainerClass = "sticky shadow pb-1 pt-1 " + props.tableParams.headerContainerClass
        if (props.maxHeight) {
            maxHeight = props?.maxHeight?.includes('px') ? props.maxHeight : props.maxHeight + 'px';

            tableStyle = { ...tableStyle, maxHeight }
        }
    }
    return (
        <>
            <div className="table-responsive common-bootstrap-table" >
                <BootstrapTable responsive remote pagination={props?.pagination === false ? false : true} striped hover condensed
                    data={props.tableParams.tableData}
                    options={tableOptions}
                    fetchInfo={{ dataTotalSize: props.tableParams.totalRecordSize }}
                    trClassName={props.tableParams.trClassName}
                    trStyle={props.tableParams.trStyle}
                    headerContainerClass={headerContainerClass}
                    tableContainerClass={props.tableParams.tableContainerClass}
                    tableStyle={tableStyle}
                    className={"align-items-center table-flush table " + props.tableParams.extraClassName}>

                    {props.tableParams.columns.map((entry, index) => {
                        const SortIcon = () => props.tableParams.sortFieldName === entry.dataField ? <span className={props.tableParams.sortDirection === "asc" ? "fas fa-angle-up" : "fas fa-angle-down"}></span> : <></>;

                        return entry.dataField ?
                            <TableHeaderColumn dataSort={entry?.sortable} width={entry.width ? entry.width : ''} isKey={entry.isKey ? entry.isKey : false} key={index} dataField={entry.dataField} dataAlign={entry.align ? entry.align : "center"} tdStyle={{ wordBreak: 'all' }} hidden={entry.hidden ? true : false}>
                                <span className={entry?.sortable ? 'cursor-pointer' : ''}>
                                    <SortIcon />
                                    {entry.hasTooltip && entry.hasTooltip === true ? <><span id={"tooltip" + index} data-placement="auto">{entry.title}</span><UncontrolledTooltip delay={0} placement="auto" target={"tooltip" + index} >{entry.tooltipText}</UncontrolledTooltip></> : entry.title}
                                </span>
                            </TableHeaderColumn>
                            :
                            <TableHeaderColumn dataSort={entry?.sortable} width={entry.width ? entry.width : ''} isKey={entry.isKey ? entry.isKey : false} key={index} dataFormat={entry.dataFormat} dataAlign={entry.align ? entry.align : "center"} tdStyle={{ wordBreak: 'all' }}>
                                {/* <SortIcon /> */}
                                {entry.hasTooltip && entry.hasTooltip === true ? <><span id={"tooltip" + index} data-placement="auto">{entry.title}</span><UncontrolledTooltip delay={0} placement="auto" target={"tooltip" + index} >{entry.tooltipText}</UncontrolledTooltip></> : entry.title} {entry.required ? <span className="required"></span> : ''}</TableHeaderColumn>

                    })}

                </BootstrapTable>
            </div>
        </>
    );
};

export default RenderBootstrapTable;