import { number_format } from "components/Common/Helpers";
import React, { useEffect, useState } from "react";
import { Table, Row, Col } from "reactstrap";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import InspectionCheck from "./InspectionCheck";
import { checkRoles } from "components/Common/Helpers";
import { checkRolesWithLevel } from "components/Common/Helpers";
import { loggedInUser } from "components/Common/Helpers";
const userData = loggedInUser();

const LatitudeLongitude = ({ onData, google, otherData, propWaivedResult }) => {
    const [latLongData, setLatLongData] = useState({});
    let [mapStyles] = useState({ width: '93.5%', height: '350px' });
    let [google_prop, setGoogleProps] = useState(null);
    let [mapData, setMapData] = useState({ activeMarker: {}, selectedPlace: {}, showingInfoWindow: false });
    let [isLoadMap, setIsLoadMap] = useState(false);

    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "lat-long") {
                handleLetLong(onData.data, google);
            }
        }
        //eslint-disable-next-line
    }, [onData]);

    let handleLetLong = (data, googleProp) => {
        let details = JSON.parse(data.details);
        let other_details = JSON.parse(data.other_details);
        let percentage_diffrence = (details.distance / details.geo_parameters) * 100;
        let percentage_distance = number_format(percentage_diffrence, 2);

        let dataArray = {
            distance: details.distance,
            comp_distance: details.geo_parameters,
            percentage_distance: `${percentage_distance}%`,
            grade: data.grade,
            latLong: other_details
        }
        otherData.category_type = onData.type;
        setLatLongData(dataArray);
        setIsLoadMap(true);
        setGoogleProps(googleProp);
    }

    // On Click Marker MY Comp
    let onMarkerClick = (props, marker, e) => {
        setMapData({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    //  Map box close
    let onClose = props => {
        if (mapData.showingInfoWindow) {
            setMapData({
                showingInfoWindow: false,
                activeMarker: null,
                selectedPlace: null
            });
        }
    };

    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
    }

    const subjectPropertyTable = (
        <Table striped>
            <tbody>
                <tr>
                    <td>Distance</td>
                    <td>{number_format(latLongData.distance, 2)} mi</td>
                </tr>
                <tr>
                    <td>Comp Distance</td>
                    <td>{number_format(latLongData.comp_distance, 2)} mi</td>
                </tr>
                <tr>
                    <td>Percentage Difference</td>
                    <td>{latLongData.percentage_distance}</td>
                </tr>
                <tr>
                    <td>Grade</td>
                    <td>{number_format(latLongData.grade, 2)}</td>
                </tr>
            </tbody>
        </Table>
    );

    return (
        <>
            {otherData.category_type === "lat-long" &&
                <Col xl="12">
                    <p className="h3 float-left w-100"><u>Latitude - Longitude</u></p>
                    <InspectionCheck otherData={otherData} />
                    {subjectPropertyTable}

                    <hr />
                    <Row className="myMapDiv mr-0 ml-0">
                        {isLoadMap ? (
                            <Map
                                className="custom-map-controls"
                                contextMenu={true}
                                google={google_prop}
                                zoom={15}
                                style={mapStyles}
                                initialCenter={
                                    { lat: latLongData.latLong.myLat, lng: latLongData.latLong.myLong }
                                }
                                zoomControlOptions={{
                                    position: google_prop.maps.ControlPosition.BOTTOM_RIGHT
                                }}
                            >
                                <Marker
                                    onClick={onMarkerClick}
                                    key={1}
                                    title='My'
                                    position={{ lat: latLongData.latLong.myLat, lng: latLongData.latLong.myLong }}
                                    name='My' >
                                </Marker>
                                <Marker
                                    onClick={onMarkerClick}
                                    key={2}
                                    title='Final'
                                    position={{ lat: latLongData.latLong.finalLat, lng: latLongData.latLong.finalLong }}
                                    name='Final' >
                                </Marker>

                                <InfoWindow
                                    marker={mapData.activeMarker}
                                    visible={mapData.showingInfoWindow}
                                    onClose={onClose}>
                                    <div className="map-link">
                                        {mapData.selectedPlace &&
                                            <>
                                                <a href={"https://www.google.com/maps/place/" + mapData.selectedPlace.name + "/@" + mapData.selectedPlace.position?.lat + "," + mapData.selectedPlace.position?.lng + ",20z"} target="_blank" rel="noreferrer">
                                                    <p>{mapData.selectedPlace.title}</p>
                                                </a>
                                                {mapData.selectedPlace.sp ? (
                                                    <>
                                                        <p>{(mapData.selectedPlace.pricePerSqFt && mapData.selectedPlace.pricePerSqFt !== 'N/A') ? `${mapData.selectedPlace.pricePerSqFt}/sqft` : ''}</p>
                                                        <p>{mapData.selectedPlace.sp ?? ''}</p>
                                                    </>
                                                ) : (
                                                    null
                                                )}
                                            </>
                                        }
                                    </div>
                                </InfoWindow>
                            </Map>
                        ) : (
                            null
                        )}
                    </Row>
                    {
                        (checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col >
            }
        </>
    );
}
export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(LatitudeLongitude);