import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header.js";
import { Container, Row, Card, CardBody, Button, Col, Label, UncontrolledTooltip } from "reactstrap";

import { PostData } from "services/Api.service";
import Spinner from "components/Common/Spinner.js";
import { toast } from 'react-toastify';
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import { sendExceptionEmail } from "components/Common/Helpers";
import { formatDate } from "components/Common/Helpers";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import './style.css';
import { truncate } from "components/Common/Helpers";
import { number_format } from "components/Common/Helpers";
import { PostDataFullUrl } from "services/PostData";
import { RO_CLIENT_INQUIRY_RESOLVED, RO_REPORT_DELIVERED } from "../Common/Constants";

const MasterFiles = () => {
    let [isProcess, setProcess] = useState(false);
    let [sortFieldName, setSortFieldName] = useState('id');
    let [sortOrder, setSortOrder] = useState('desc');
    let [currentPage, setCurrentPage] = useState(1);
    let [recordsPerPage, setRecordsPerPage] = useState(10);
    let [totalRecordSize, setTotalRecordSize] = useState(0);
    let [tableData, setTableData] = useState([]);
    let [selectedOrders, setSelectedOrders] = useState([]);
    let [numberOfCopies, setNumberOfCopies] = useState(null);

    useEffect(() => {
        getMasterFiles();
    }, [currentPage, recordsPerPage, sortFieldName, sortOrder]);// eslint-disable-line react-hooks/exhaustive-deps

    /* Get files from production server */
    let getMasterFiles = async () => {
        try {
            let tableData = [];
            let offset = (currentPage - 1) * recordsPerPage;
            let data = {
                offset,
                limit: recordsPerPage,
                sort_field: sortFieldName,
                sort_order: sortOrder,
            };

            let training_headers = { 'authorization': process.env.REACT_APP_TRAINING_SERVER_SECRET_KEY }

            setProcess(true);
            const response = await PostDataFullUrl(`${process.env.REACT_APP_LIVE_API_ENDPOINT}public/get-master-files`, data, training_headers);
            const training_files = response.data.data.training_files;
            if (training_files) {
                for (const training_file of training_files) {
                    let finalOrderObj = {};
                    let property = training_file.property;
                    let report_types = training_file.report_order.report_type;
                    let arv_data = training_file.report_order.property_arv;
                    let analysis_tracking = training_file.report_order.analysis_tracking;

                    finalOrderObj.id = training_file.id
                    finalOrderObj.description_reason = training_file.description_reason
                    let propertyAddress = [property.address, property.city, property.state, property.postal_code].filter(Boolean).join(", ");
                    finalOrderObj.address = propertyAddress;
                    finalOrderObj.order_date = formatDate('', training_file.report_order.order_date, "MMM D, h:mm a")
                    let new_analysts = training_file.new_analysts === "1" ? "New Analysts" : "";
                    let new_reviewers = training_file.new_reviewers === "1" ? "New Reviewers" : "";
                    let existing_analysts = training_file.existing_analysts === "1" ? "Existing Analysts" : "";
                    let existing_reviewers = training_file.existing_reviewers === "1" ? "Existing Reviewers" : "";
                    let bestFor = [new_analysts, new_reviewers, existing_analysts, existing_reviewers].filter(Boolean).join(", ");
                    finalOrderObj.bestFor = bestFor;
                    finalOrderObj.report_type = report_types.nickname;
                    finalOrderObj.market_type = property.zip_code ? property.zip_code.category : '-';
                    finalOrderObj.effective_date = arv_data ? formatDate('', arv_data.effective_date, "MMM D, H:MM A") : '-';
                    finalOrderObj.commentary = analysis_tracking ? analysis_tracking.underwriter_summary_comment : '-';
                    finalOrderObj.as_is_value = training_file.asIsValue ? "$" + number_format(training_file.asIsValue, 2) : '-';
                    finalOrderObj.full_arv = training_file.fullArv ? "$" + number_format(training_file.fullArv, 2) : '-';
                    finalOrderObj.property_stat = training_file.property_stat ? training_file.property_stat : '-';
                    finalOrderObj.order_status = training_file.report_order.order_status;
                    tableData.push(finalOrderObj);
                }
            }
            setTableData(tableData);
            setTotalRecordSize(response.data.data.count);
            setProcess(false);
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
            setProcess(false);
        }
    };

    /* Handle selected order */
    let handleSelectedOrders = (value, id) => {
        if (value) {
            selectedOrders.push(id);
        } else {
            selectedOrders = selectedOrders.filter(x => x !== id);
        }
        setSelectedOrders(selectedOrders);
    }

    /* Handle action for checkbox */
    let actions = (cell, row) => {
        let disabled = false;
        if (![RO_REPORT_DELIVERED, RO_CLIENT_INQUIRY_RESOLVED].includes(row.order_status)) {
            disabled = true;
        }
        return (
            <>
                <div key={row.id} className="custom-control custom-control-alternative custom-checkbox zoning-checkbox" sm="2">
                    <input
                        className="custom-control-input"
                        name={"require_zoning_" + row.id}
                        id={"zoning_checkbox_" + row.id}
                        type="checkbox"
                        disabled={disabled}
                        value='1'
                        onChange={(event) => handleSelectedOrders(event.target.checked, row.id)}
                    />
                    <label className="custom-control-label" htmlFor={"zoning_checkbox_" + row.id}>
                        {/* Intended For Me */}
                    </label>
                </div>
            </>
        );
    };

    /* Create a clone of order, order will be added to queue */
    let handleSubmit = async () => {
        try {
            let data = {
                numberOfCopies,
                selectedOrders,
            };
            if (selectedOrders.length === 0) {
                toast['error']("Please select order");
                return false;
            }
            setProcess(true);
            const response = await PostData(`orders/order-training-add-queue`, data);
            setProcess(false);
            toast['success'](response.message);
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
            setProcess(false);
        }
    }


    // TABLE EVENTS
    // Handle  page change show record in table
    let onPageChangeEvent = (currentPageTemp, recordsPerPageTemp) => {
        setCurrentPage(currentPageTemp > 0 ? currentPageTemp : 1);
        setRecordsPerPage(recordsPerPageTemp);
    };

    // On sorting filter
    let onSortChangeEvent = (fieldName, order) => {
        setSortFieldName(fieldName);
        setSortOrder(order);
    };

    // Show current page number and total record
    let paginationTotalText = (start, to, total) => {
        return (
            <p>From {start} to {to}, totals is {total}</p>
        );
    };

    /* Reason column tooltip */
    let cloneReason = (cell, row) => {
        const row_id = `notes_text_${row.id}`;
        return (
            <div>
                <span id={row_id}>{truncate(row.description_reason, 65)}</span>
                <UncontrolledTooltip
                    delay={0}
                    placement="auto"
                    target={row_id}
                >
                    {row.description_reason}
                </UncontrolledTooltip>
            </div>

        );
    };

    let columns = [
        { width: "4%", title: '', dataFormat: actions, align: 'center' },
        { width: "14%", title: `Property Address`, dataField: 'address', align: 'center', isKey: true },
        { width: "8%", title: `Property Stats`, dataField: 'property_stat', align: 'center' },
        { width: "8%", title: 'Order type', dataField: 'report_type', align: 'center' },
        { width: "7%", title: 'Market type', dataField: 'market_type', align: 'center' },
        { width: "7%", title: 'AIV', dataField: 'as_is_value', align: 'center' },
        { width: "7%", title: 'ARV', dataField: 'full_arv', align: 'center' },
        { width: "8%", title: 'Effective date', dataField: 'effective_date', align: 'center' },
        { width: "14%", title: 'Reason', dataFormat: cloneReason, align: 'center' },
        { width: "8%", title: 'Best For', dataField: 'bestFor', align: 'center' },
        { width: "15%", title: 'Commentary', dataField: 'commentary', align: 'center' },
    ];

    const tableParams = {
        currentPage,
        recordsPerPage,
        onPageChangeEvent,
        onSortChangeEvent,
        paginationTotalText,
        tableData,
        totalRecordSize,
        columns,
    };

    return (
        <>
            <Header />
            <Spinner isShow={isProcess} />
            <Container className="mt--9 container-vh-100 master-files-list" fluid>
                <Card className="shadow">

                    <CardBody className="training-master-files" >
                        <Spinner isShow={isProcess} />
                        <Row>
                            <Col lg="12" >
                                <AvForm onValidSubmit={() => handleSubmit('flood')} className="float-right" >
                                    <Row className="text-left">
                                        <Col md="5">
                                            <Label className="copies-label">How many copies you want create?</Label>
                                        </Col>
                                        <Col md="4">
                                            <AvGroup>
                                                <AvField
                                                    name="flood_map_number"
                                                    className="form-control-sm"
                                                    placeholder="Enter number"
                                                    maxLength="2"
                                                    onChange={(event) => setNumberOfCopies(event.target.value)}
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        min: {
                                                            value: 1,
                                                            errorMessage: "Please enter valid values."
                                                        },
                                                        max: {
                                                            value: 50,
                                                            errorMessage: "Enter value less than 50."
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                        <Col md="3">
                                            <AvGroup>
                                                <Button size="sm" color="primary">
                                                    Generate Copies
                                                </Button>
                                            </AvGroup>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </Col>
                            <RenderBootstrapTable tableParams={tableParams} />
                        </Row>
                    </CardBody>
                    <p className="h5 p-2 text-muted">
                        <i className="fa fa-info-circle" aria-hidden="true"></i> Orders with a disabled checkbox are not completed yet so you can't create a clone.
                    </p>
                </Card>

            </Container>
        </>
    );
};

export default MasterFiles;