import ScaleLoader from "react-spinners/ScaleLoader";

const DivSpinner = ({ isShow }) => {

  return (
    <>
      {isShow ? (
        <div className="loading-custom text-center" >
          <ScaleLoader color={'#11cdef'} loading={true} size={200} />
        </div>
      ) : null}
    </>
  )
}

export default DivSpinner;