import { combineReducers } from "redux";
import InspectionOrderIntakeReducer from '../../views/modules/InspectionOrderIntake/inspectionOrderIntake.reducer'
import ReportOrderIntakeReducer from './../../views/modules/ClientPortal/OrderIntake/ReportOrderIntake.reducer'
import InspectionOrderListReducer from "../../views/modules/ClientPortal/Inspections/inspectionOrderList.reducer";
import ShowHideSpinnerReducer from "../../ShowHideSpinner.reducer";
import compsPerformanceReducer from "../../views/modules/Performance/Comps/CompsPerformance.reducer";
import DisputeOrderReducer from "../../views/modules/Common/DisputeOrder/DisputeOrder.reducer";
import taskIdsListReducer from "../../views/modules/taskIdsList.reducer";
import EpoOrderIntakeReducer from '../../views/modules/EpoOrderIntake/epoOrderIntake.reducer'
import EpoOrdersListReducer from "../../views/modules/ClientPortal/EpoOrders/epoOrdersList.reducer";
import EpoOrdersReducer from "../../views/modules/EpoOrderManagement/epoOrders.reducer";
import ValDetailsReducer from "views/modules/Deals/Details/ValDetails.reducer";
import TrainingMVPReducer from "../../views/modules/TrainingMVP/TrainingMVP.reducer";


const rootReducer = combineReducers({
    // Include Reducers by comma separate
    inspectionOrderIntake: InspectionOrderIntakeReducer,
    epoOrderIntake: EpoOrderIntakeReducer,
    reportOrderIntake: ReportOrderIntakeReducer,
    inspectionOrderList: InspectionOrderListReducer,
    epoOrdersList: EpoOrdersListReducer,
    epoOrders: EpoOrdersReducer,
    showHideSpinner: ShowHideSpinnerReducer,
    compsPerformance: compsPerformanceReducer,
    taskIdsList: taskIdsListReducer,
    disputeOrder: DisputeOrderReducer,
    trainingMVP: TrainingMVPReducer,
    valDetails: ValDetailsReducer,
});

export default rootReducer;