import { XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar, Cell, CartesianGrid, LabelList } from "recharts";
import React, { useEffect, useState } from "react";
import { getColorCode, getLabelName } from "./AnalysisComparisonHelper";
import { number_format } from "components/Common/Helpers";

const SubCategoriesChart = ({ onData, propCategoryData, qualityRatings }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (onData && onData.length) {
            handleChartData(onData);
        }
        //eslint-disable-next-line   
    }, [onData]);

    let handleChartData = (data) => {
        let categoriesArray = [];
        data.forEach(element => {
            element.grade = parseFloat(number_format(element.grade, 2));
            categoriesArray.push({ name: element.category, grade: element.grade, slug: element.slug, id: element.id, waived: element.waived });
        });
        setChartData(categoriesArray);
    }

    let handleClick = (data, index) => {
        if ('slug' in data) {
            propCategoryData(data);
        } else {
            data = chartData[data.index];
            propCategoryData(data);
        }
    };

    let handleColorCode = (data, qualityRatings) => {
        let grade_label = getLabelName(parseFloat(data.grade), qualityRatings);
        let colorCode = getColorCode(qualityRatings, grade_label)
        if (data.waived === '1') {
            colorCode = '#CCCCCC';
        }
        return colorCode;
    }

    const renderCustomizedLabel = (props) => {
        let { x, y, width, value } = props;
        const radius = 15;
        var randomNum = Math.floor(Math.random() * 100) + 1;
        return (
            value > 0 &&
            <g>
                {value >= 25 ? (
                    <text key={`text-${randomNum}`} x={x + width / 2} y={(y + 31) - radius} fill="#000" textAnchor="middle" style={{ fontWeight: "bold" }} dominantBaseline="middle">
                        {number_format(value, 2)}
                    </text>
                ) : (
                    <text key={`text-${randomNum}`} x={x + width + 18} y={(y + 31) - radius} fill="#000" textAnchor="middle" style={{ fontWeight: "bold" }} dominantBaseline="middle">
                        {number_format(value, 2)}
                    </text>
                )}
            </g>
        );
    };

    return (
        <>
            <div className="pt-4 sub-category-chart">
                <p className="h3"><u>Sub-Categories</u></p>
                {chartData.length > 0 &&
                    <ResponsiveContainer width='90%' aspect={4.0 / 3.0}>
                        <BarChart data={chartData} layout="vertical" margin={{ top: 0, left: 0, right: 0, bottom: 0 }} >
                            <CartesianGrid strokeDasharray="3 0" horizontal={false} vertical={true} />
                            <XAxis type="number" />
                            <YAxis dataKey="name" width={130} reversed type="category" onClick={handleClick} />
                            <Tooltip />
                            <Bar dataKey="grade" fill="#ff6f31" onClick={handleClick} >
                                <LabelList dataKey="grade" content={renderCustomizedLabel} />
                                {
                                    chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={`${handleColorCode(entry, qualityRatings)}`} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                }
            </div>
        </>
    );
}
export default SubCategoriesChart;