import { checkRoles } from "components/Common/Helpers";
import { checkRolesWithLevel } from "components/Common/Helpers";
import { number_format } from "components/Common/Helpers";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Modal, Col } from "reactstrap";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import ConditionRatings from "./ConditionRatings";
import InspectionCheck from "./InspectionCheck";

const CompConditions = ({ onData, otherData, propWaivedResult }) => {
    const [compConditionData, setCompConditionData] = useState({});
    const [isCompModal, setIsCompModal] = useState(false);

    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "condition-ratings") {
                handleCompConditions(onData.data);
            }
        }
        //eslint-disable-next-line
    }, [onData]);

    let handleCompConditions = (data) => {
        let details = JSON.parse(data.details);
        let other_details = JSON.parse(data.other_details);

        let all_comp_per = details.total_differences > 0 ? (details.total_differences / details.total_comps) * 100 : 100;
        all_comp_per = `${number_format(all_comp_per, 2)}%`;
        let selected_comp_per = details.selected_differences > 0 ? (details.selected_differences / details.selected_comps) * 100 : 100;
        selected_comp_per = `${number_format(selected_comp_per, 2)}%`;

        let dataArray = {
            total_comps: details.total_comps,
            selected_comps: details.selected_comps,
            total_differences: details.total_differences,
            selected_differences: details.selected_differences,
            all_comp_percentage: all_comp_per,
            selected_comp_percentage: selected_comp_per,
            all_comp_grade: number_format(details.average_comp_grade, 2),
            selected_comps_grade: number_format(details.average_selected_comp_grade, 2),
            grade: number_format(data.grade, 2),
            allComps: JSON.parse(other_details.allComps),
            all_selected_comps: JSON.parse(other_details.selected_comps),
            allDifferentComps: JSON.parse(other_details.allDifferentComps),
            selectedDifferentComps: JSON.parse(other_details.selectedDifferentComps),
            condition_acceptance_grade: details.condition_acceptance_grade,
            selected_condition_acceptance_grade: details.selected_condition_acceptance_grade,
            adjusted_condition_grade: details.adjusted_condition_grade,
            selected_adjusted_condition_grade: details.selected_adjusted_condition_grade
        }
        otherData.category_type = onData.type;
        setCompConditionData(dataArray);
    }
    let handleCompModal = (state, type = 'all') => {
        setIsCompModal(state);
        if (state) {
            otherData.comp_type = type;
        }
    };

    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
    }

    const subjectPropertyTable = (
        <Table striped className="analysis-table">
            <thead>
                <tr>
                    <th>Metric</th>
                    <th>All Comps</th>
                    <th>Selected Comps</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Number of Comps</td>
                    <td>{compConditionData.total_comps}</td>
                    <td>{compConditionData.selected_comps}</td>
                </tr>
                <tr>
                    <td>Number w Differences</td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, 'all')} to="#" >{compConditionData.total_differences}</Link>
                    </td>
                    <td>
                        <Link className="text-primary custom-link" onClick={e => handleCompModal(true, 'selected')} to="#" >{compConditionData.selected_differences}</Link>
                    </td>
                </tr>
                <tr>
                    <td>Percentage</td>
                    <td>{compConditionData.all_comp_percentage}</td>
                    <td>{compConditionData.selected_comp_percentage}</td>
                </tr>
                <tr>
                    <td>Average Condition Rating Grade</td>
                    <td>{compConditionData.all_comp_grade}</td>
                    <td>{compConditionData.selected_comps_grade}</td>
                </tr>
                <tr>
                    <td>Final Grade</td>
                    <td>{compConditionData.grade}</td>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        </Table>
    );

    return (
        <>
            {otherData.category_type === "condition-ratings" &&
                <Col xl="12">
                    <p className="h3 float-left w-100"><u>Condition Ratings</u></p>
                    <InspectionCheck otherData={otherData} />
                    {subjectPropertyTable}
                    {(checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col>
            }

            <Modal className="modal-dialog-centered modal-xl compensation-modal" style={{ maxWidth: "50%" }} isOpen={isCompModal} toggle={() => handleCompModal(false)}>
                <div className="modal-header">
                    <h2 className="modal-title w-100 text-center">Comp Conditions</h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleCompModal(false)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <ConditionRatings onData={otherData} compConditionData={compConditionData} />
                </div>
            </Modal>
        </>
    );

}

export default CompConditions;