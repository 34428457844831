import React from "react";
import { Container } from "reactstrap";
import Spinner from "components/Common/Spinner";
import { useSelector } from "react-redux";

const Header = () => {
    // Use useState hook to manage state
    const spinnerCurrentStat = useSelector((state) => state.showHideSpinner.currentStat)

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Spinner isShow={spinnerCurrentStat} />
                <Container fluid>
                    <div className="header-body"></div>
                </Container>
            </div>
        </>
    );
};

export default Header;