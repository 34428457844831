import { checkRoles } from "components/Common/Helpers";
import { checkRolesWithLevel } from "components/Common/Helpers";
import { number_format } from "components/Common/Helpers";
import React, { useEffect, useState } from "react";
import { Table, Col, UncontrolledTooltip } from "reactstrap";
import CategoryWaivedToggle from "./CategoryWaivedToggle";
import InspectionCheck from "./InspectionCheck";
import { getConditionRatingName } from "components/Common/Helpers";

const SubjectCondition = ({ onData, ratingRecords, otherData, propWaivedResult }) => {
    const [subjectConditionData, setSubjectConditionData] = useState({});

    useEffect(() => {
        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            if (onData.type === "subject-condition") {
                handleSubjectCondition(onData.data);
            }
        }
        //eslint-disable-next-line
    }, [onData, ratingRecords]);

    let handleSubjectCondition = (data) => {
        let other_details = JSON.parse(data.other_details);
        let dataArray = {
            my_condition: other_details.my_condition,
            final_condition: other_details.final_condition,
            grade: number_format(data.grade, 2)
        }
        setSubjectConditionData(dataArray);
        otherData.category_type = onData.type;
    }

    let getCategoryWaivedResult = (data) => {
        propWaivedResult(data);
    }
    const subjectPropertyTable = (
        <Table striped>
            <tbody>
                <tr>
                    <td>My Condition</td>
                    <td>
                        <span role="button" id="mySub_1" dangerouslySetInnerHTML={{ __html: getConditionRatingName(subjectConditionData.my_condition, ratingRecords) }}>
                        </span>
                        <UncontrolledTooltip delay={0} placement="auto" target="mySub_1" >{getConditionRatingName(subjectConditionData.my_condition, ratingRecords, false)}</UncontrolledTooltip>
                    </td>
                </tr>
                <tr>
                    <td>Final Condition</td>
                    <td>
                        <span role="button" id="finalSub_1" dangerouslySetInnerHTML={{ __html: getConditionRatingName(subjectConditionData.final_condition, ratingRecords) }}>
                        </span>
                        <UncontrolledTooltip delay={0} placement="auto" target="finalSub_1" >{getConditionRatingName(subjectConditionData.final_condition, ratingRecords, false)}</UncontrolledTooltip>
                    </td>
                </tr>
                <tr>
                    <td>Grade</td>
                    <td>{subjectConditionData.grade}</td>
                </tr>
            </tbody>
        </Table>
    );

    return (
        <>
            {otherData.category_type === "subject-condition" &&
                <Col xl="12">
                    <p className="h3 float-left w-100"><u>Subject Condition</u></p>
                    <InspectionCheck otherData={otherData} />
                    {subjectPropertyTable}
                    {(checkRoles(['ADMINISTRATOR', 'MANAGER', 'TRAINING_ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                        <CategoryWaivedToggle onData={onData.data} otherData={otherData} propResult={getCategoryWaivedResult} />
                    }
                </Col>
            }
        </>
    );
}
export default SubjectCondition;