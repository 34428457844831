import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Legend } from "recharts";
import React, { useEffect } from "react";
import { moneyFormat } from "components/Common/Helpers";

const ColumnChartComp = ({ chartData }) => {

    useEffect(() => {

        //eslint-disable-next-line        
    }, [chartData]);

    let kFormatter = (num) => {
        return '$' + moneyFormat(num, 0);
    };

    return (
        <>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                        top: 30,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" tick={true} />
                    <YAxis tickFormatter={kFormatter} />
                    <Legend iconType="square" layout="horizontal" className="legend-label" verticalAlign="bottom" align="center" wrapperStyle={{ marginTop: "0px" }} />
                    <Tooltip formatter={kFormatter} />
                    <Bar name="Submitted" dataKey="submitted" barSize={50} fill="#16291d" />
                    <Bar name="Final" dataKey="final" barSize={50} fill="#f0b20a" />
                </BarChart>
            </ResponsiveContainer>
        </>
    );

}

export default ColumnChartComp;