import React, { Suspense, useEffect, useState } from "react";
import { useLocation, Route, Switch, useHistory, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import ClientNavbar from "components/Navbars/ClientNavbar.js";
import ClientFooter from "components/Footers/ClientFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "clientRoutes.js";
import { loggedInUser } from "components/Common/Helpers.js"
import Spinner from "components/Common/Spinner";
import { useSelector } from 'react-redux'

const Client = (props) => {
  const spinnerCurrentStat = useSelector((state) => state.showHideSpinner.currentStat)

  const mainContent = React.useRef(null);
  const location = useLocation();
  const userData = loggedInUser();
  let history = useHistory();

  let [isCollapsedSideBar, setIsCollapsedSideBat] = useState(props?.systemSettings?.isCollapsedSideBar ? props?.systemSettings?.isCollapsedSideBar : false);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    let layout = ["", "client"];
    if (location) {
      layout = location.pathname.split("/");
    }

    if (!userData || Object.keys(userData).length === 0) {
      history.push("/auth/login");
    }

    if (userData.report_order_company_id === userData.company_id) {
      if (layout[1] !== "admin") {
        history.push('/admin/dashboard');
      }
    } else if (userData.oms_access === 1) {
      if (layout[1] !== "client") {
        history.push('/client/dashboard');
      }
    }
  }, [history, userData, location]);

  const getRoutes = (routes) => {
    let navMenu = [];
    routes.forEach(menu => {
      navMenu.push(menu);
      if (menu.children) {
        menu.children.forEach(subMenu => {
          navMenu.push(subMenu);
        });
      }
    });

    return navMenu.map((prop, key) => {
      if (prop.layout === "/client" && prop.is_visible === true) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
      if (typeof (routes[i].children) != 'undefined') {
        for (let j = 0; j < routes[i].children.length; j++) {
          if (props.location.pathname.indexOf(routes[i].children[j].layout + routes[i].children[j].path) !== -1) {
            return routes[i].children[j].name;
          }
        }
      }
    }

    return "";
  };

  // For Nav bat toggle
  const handleToggleSidebar = (status) => {
    status = status === isCollapsedSideBar && status === false ? true : status;
    setIsCollapsedSideBat(status)
    setSystemSettings(status);
  }

  // For Nav bat toggle
  const navToggle = (status) => {
    setIsCollapsedSideBat(status);
    setSystemSettings(status);
  }

  const setSystemSettings = (status) => {
    let systemSettings = localStorage.getItem('system-settings');
    systemSettings = systemSettings ? JSON.parse(systemSettings) : { isCollapsedSideBar: false }
    systemSettings.isCollapsedSideBar = status
    localStorage.setItem('system-settings', JSON.stringify(systemSettings))
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        screenNavType={isCollapsedSideBar ? "full-screen-nav" : ""} // For Nav bat toggle
        handleNavToggle={navToggle}
        logo={{
          innerLink: "/client/dashboard",
          imgSrc: isCollapsedSideBar ? require("../assets/img/brand/small-logo-blue.png").default : require("../assets/img/brand/white-logo.png").default,// For Nav bat toggle
          imgAlt: "...",
        }}
      />

      <div className={isCollapsedSideBar ? "main-content full-screen" : "main-content"} ref={mainContent}>
        <Spinner isShow={spinnerCurrentStat} />
        <ClientNavbar
          {...props}
          isOpen={handleToggleSidebar}
          isCollapsedSideBar={isCollapsedSideBar}
          brandText={getBrandText(props.location.pathname)}
        />
        <Suspense fallback={null}>
          <Switch>
            {getRoutes(routes)}
            {/* <Redirect from="*" to="/admin/dashboard" /> */}
            <Redirect exact={true} from="*" to="/client/dashboard" />
          </Switch>
        </Suspense>
        <Container fluid>
          <ClientFooter />
        </Container>
      </div>
    </>
  );
};

export default Client;