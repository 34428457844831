
/*
### Example:

Import : import TabsComponent from "components/Common/TabsComponent.js";

### Component:

<TabsComponent
customTabsStyle={{}} //Ex. textAlign:"left"
customTabListStyle={{}} //Ex. padding:"6px 25px"
tabs={['one','two']}
tabPanel={['one data','tow data']}                
/>

*/
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const TabsComponent = ({ tabs, tabPanel, customTabsStyle, customTabListStyle, onSubjectPropertyConditionGoogleTab }) => {
  return (
    <>
      <Tabs forceRenderTabPanel defaultIndex={0} style={customTabsStyle}>
        <TabList style={customTabsStyle}>
          {tabs.map((tab, idx) => (
            <Tab key={idx} style={customTabListStyle} onClick={onSubjectPropertyConditionGoogleTab && idx === 1 ? onSubjectPropertyConditionGoogleTab : undefined}>{tab}</Tab>
          ))}
        </TabList>
        {tabPanel.map((tabPaneldata, idx) => (
          <TabPanel key={idx}>
            {tabPaneldata}
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
}
export default TabsComponent;
