import React, { Suspense } from "react";
import { useLocation, Route, Switch, useHistory } from "react-router-dom";
import { Col, Container } from "reactstrap";
import routes from "clientRoutes.js";
import { loggedInUser } from "components/Common/Helpers.js"
const Client = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const userData = loggedInUser();
  let history = useHistory();

  React.useEffect(() => {

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  React.useEffect(() => {

  }, [history, location, userData]);

  const getRoutes = (routes) => {
    let navMenu = [];
    routes.forEach(menu => {
      navMenu.push(menu);
      if (menu.children) {
        menu.children.forEach(subMenu => {
          navMenu.push(subMenu);
        });
      }
    });

    return navMenu.map((prop, key) => {
      if (prop.layout === "/public" && prop.is_visible === true) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}

          />
        );
      } else {
        return null;
      }
    });
  };


  return (
    <>

      <div className="main-content" ref={mainContent}>

        <div className="text-center mt-2">
          <img src={require("../assets/img/brand/white-logo.png").default} width="20%" alt="logo" />
        </div>

        <Suspense fallback={null}>
          <Switch>
            {getRoutes(routes)}
          </Switch>
        </Suspense>
        <Container fluid>
          <footer className="footer public-footer">
            <Col className="align-items-center justify-content-xl-between">
              <div className="copyright text-center text-muted">
                © Copyright 2019 - {new Date().getFullYear()}{" "}

                RicherValues - All Rights Reserved
              </div>
            </Col>
          </footer>
        </Container>
      </div>
    </>
  );
};

export default Client;