import { priceNumber } from "components/Common/Helpers";

const { GetData } = require("services/Api.service");

export const defaultValues = () => {
    return {
        compFilterParams: {
            compStatus: 'sold',
            mapsDisplayType: 'sp',
            showHiddenComps: true,
            mapDrawings: [],
            mapShapeFilteredComps: []
        },
        adjustmentFactorSliderValue: {
            condition: 0,
            above: 0,
            lot: 0,
            yearBuilt: 0,
        }
    }
};

export const retrievePageData = async (params) => {
    try {
        const resp = await GetData("api/v1/deals/page-data", params);
        const reportOrders = resp?.data?.reportOrders;
        return { reportOrders };
    } catch (error) {
        throw error;
    }
};

export const retrieveCompData = async (params) => {
    try {
        const resp = await GetData("api/v1/deals/comp-data", params);
        const comps = resp?.data?.soldComps;
        const adjustmentFactors = resp?.data?.adjustmentFactors;
        const adjustmentValues = resp?.data?.adjustmentValues;

        let subjectPropertyComp = resp?.data?.subjectPropertyComp;
        const address = subjectPropertyComp.address + ", " + subjectPropertyComp.city + ", " + subjectPropertyComp.state + ", " + subjectPropertyComp.postal_code;
        subjectPropertyComp.fullAddress = address;

        subjectPropertyComp.totalSqft = parseFloat(subjectPropertyComp.living_area_square_feet) + parseFloat(subjectPropertyComp.finished_basement);
        subjectPropertyComp.dollarPerSqft = subjectPropertyComp.totalSqft === 0 ? 'N/A' : priceNumber(parseFloat(subjectPropertyComp?.close_price) / subjectPropertyComp.totalSqft);

        return { subjectPropertyComp, comps, adjustmentFactors, adjustmentValues };
    } catch (error) {
        throw error;
    }
};

export const retrieveConfidenceScoreData = async (params) => {
    try {
        const resp = await GetData("api/v1/deals/confidence-score-data", params);
        let confidenceScoreData = resp?.data?.confidenceScoreData;

        confidenceScoreData.r2_blended = parseFloat(confidenceScoreData?.r2_blended * 100).toFixed(2);
        confidenceScoreData.precision_score = parseFloat(confidenceScoreData?.precision_score * 100).toFixed(2);
        confidenceScoreData.percent_high_quality = parseFloat(confidenceScoreData?.percent_high_quality * 100).toFixed(1);
        confidenceScoreData.influence_core = parseFloat(confidenceScoreData?.influence_core * 100).toFixed(1);
        confidenceScoreData.confidence_score = parseFloat(confidenceScoreData?.confidence_score * 100).toFixed(0);

        return { confidenceScoreData };
    } catch (error) {
        throw error;
    }
};

export const retrievePropertyComparisonData = async (params) => {
    try {
        const resp = await GetData("api/v1/deals/property-comparison-data", params);
        const propertyComparisonData = resp?.data?.propertyComparisonData;

        return { propertyComparisonData };
    } catch (error) {
        throw error;
    }
};

export const retrieveCompLocationHistory = async (params) => {
    try {
        const resp = await GetData("api/v1/comps/location-history", params);
        const compLocationHistory = resp?.data?.compLocationHistory;

        return { compLocationHistory }
    } catch (error) {
        throw error;
    }
};

export const retrievePropertyFiles = async (propertyId) => {
    try {
        const resp = await GetData(`property/${propertyId}/property-files`);
        const propertyFiles = resp?.data?.propertyFiles;

        return { propertyFiles }
    } catch (error) {
        throw error;
    }
};