import { loggedInUser } from "components/Common/Helpers";
import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Button } from "reactstrap";
import ToggleSwitch from "../Common/ToggleSwitch";
import { PostData } from "../../../services/PostData";
import { toast } from 'react-toastify';
import { formatDate } from "components/Common/Helpers";
import Spinner from "components/Common/Spinner";
import { number_format } from "components/Common/Helpers";
import { checkRoles } from "components/Common/Helpers";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { PostData as PostDataNew } from "../../../services/Api.service";
import { confirmAlert } from "react-confirm-alert";

const CategoryWaivedToggle = ({ onData, otherData, propResult }) => {
    let labelBannedArray = ['Yes', 'No'];
    let [banned, setBanned] = useState(false);
    let [performanceId, setPerformanceId] = useState(null);
    let userData = loggedInUser();
    let [isProcess, setProcess] = useState(false);
    let [categoryWaivedData, setCategoryWaivedData] = useState(null);
    let [recipientEmail, setRecipientEmail] = useState(null);


    useEffect(() => {

        if (typeof onData === 'object' && onData !== null && Object.keys(onData).length > 0) {
            let waived;
            let performanceIds;
            if (onData.asIsValue) {
                waived = onData.valueCurve.waived === "1" ? true : false;
                performanceIds = [onData.asIsValue.id, onData.recommendedArv.id, onData.targetArv.id, onData.valueCurve.id]
            } else if (otherData.category_type === "comp-selection") {
                waived = onData.compSelection.waived === "1" ? true : false;
                performanceIds = onData.compSelection.id;
            } else {
                waived = onData.waived === "1" ? true : false;
                performanceIds = onData.id
            }
            setBanned(waived);
            setPerformanceId(performanceIds);

            if (otherData.category_type === "values") {
                setCategoryWaivedData({ waived_datetime: onData.asIsValue.waived_datetime, user: onData.asIsValue.user });
            } else {
                setCategoryWaivedData({ waived_datetime: onData.waived_datetime, user: onData.user });
            }

        }
    }, [onData, otherData]);

    const onBannedStatusChange = (checked) => {
        let waived = checked ? '1' : '0'
        let updateData = {
            waived,
            user_id: userData.user_id,
            id: performanceId
        }
        setBanned(checked);

        setProcess(true);
        PostData("analysis-comparison/save-category-waived", updateData).then(result => {
            let responseJson = result.data;
            setCategoryWaivedData(responseJson.data);
            let reportAmount = otherData.reportAmount;
            let sendData = {
                user_id: reportAmount.user_id,
                report_id: reportAmount.report_id,
                report_comp_amt_id: reportAmount.id,
                property_id: otherData.property_id,
                waived,
                recalculate: true,
                slug: otherData.category_type
            }
            if (process.env.REACT_APP_SERVER_ENVIRONMENT === "training") {
                sendData.server_type = "training";
            }
            if (responseJson.status === 200) {
                reCalculate(sendData);
            } else {
                toast["error"](responseJson.message);
                setProcess(false);
            }
        }).catch(errors => {
            toast["error"](errors.message);
            setProcess(false);
        });
    };

    let reCalculate = (updateData) => {
        setProcess(true);
        PostData("performance-rating/calculate-performance", updateData).then(result => {
            let responseJson = result.data;
            if (responseJson.status === 200) {
                propResult({ total_grade: number_format(responseJson.data.total_grade, 2), performance_categories: responseJson.data.performance_categories })
                toast["success"](responseJson.message);
            } else {
                toast["error"](responseJson.message);
            }
            setProcess(false);

        }).catch(errors => {
            setProcess(false);
            toast["error"](errors.message);
        });
    }

    let resendEmail = async (updateData) => {
        confirmAlert({
            title: 'Are you sure want to send email?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => sendEmail(),
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'No',
                    onClick: () => { },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });
    }

    let sendEmail = async () => {
        try {
            setProcess(true);
            let reportAmount = otherData.reportAmount;
            let sendData = {
                report_id: reportAmount.report_id,
                amts_id: reportAmount.id,
                email: recipientEmail ? recipientEmail : null
            }
            let responseData = await PostDataNew("performance-rating/resend-email-recipients", sendData);
            setProcess(false);
            toast["success"](responseData.message);
        } catch (error) {
            setProcess(false);
            toast["error"](error.error && error.errors.length ? error.error[0].message : error.message);
        }
    }
    let categoryId = `category-${performanceId}`;

    return (
        <>
            <Spinner isShow={isProcess} />
            <hr />
            <Row>
                <Col>
                    <p className="h2">Category Waived?</p>
                </Col>
                <Col>
                    <ToggleSwitch
                        id={categoryId}
                        className="userBanned category-waived"
                        checked={banned}
                        onChange={(e) => onBannedStatusChange(e)}
                        optionLabels={labelBannedArray}
                    />
                </Col>
            </Row>
            {categoryWaivedData && categoryWaivedData.user &&
                <Row>
                    <Col>
                        <p className="h4">By Whom?</p>
                    </Col>
                    <Col>
                        <p className="h4">{categoryWaivedData && categoryWaivedData.user.full_name}</p>
                    </Col>
                    <Col>
                        <p className="h4">{categoryWaivedData && categoryWaivedData ? formatDate('', categoryWaivedData.waived_datetime, 'MMM D, YY h:mm a') : "-"}</p>
                    </Col>
                </Row>
            }
            {checkRoles(['ADMINISTRATOR']) &&
                <>
                    <hr />
                    <Row>
                        <Col>
                            <p className="h3 float-left w-100">Resend Performance Email</p>
                        </Col>
                    </Row>
                    <Row>
                        <AvForm className="col-md-12" onValidSubmit={(e) => resendEmail(e)}>
                            <CardBody className="pl-0 pt-0">
                                <Row>
                                    <Col className="col-md-7">
                                        <AvField value={otherData.submit_email} name="recipient_email" onChange={(e) => setRecipientEmail(e.target.value)} validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please enter email"
                                            },
                                            maxLength: { value: 60, errorMessage: "Max length must be 60." },
                                            email: {
                                                value: true,
                                                errorMessage: "Please enter valid email address"
                                            }
                                        }} placeholder="Enter Recipient Email" />
                                    </Col>
                                    <Col className="col-md-5">
                                        <Button color="info">
                                            <span className="btn-inner--text">Send Email</span>&nbsp;&nbsp;
                                            <span className="btn-inner--icon">
                                                <i className="fa fa-paper-plane" />
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </AvForm>
                    </Row>
                </>
            }
        </>
    );

}

export default CategoryWaivedToggle;