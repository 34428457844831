import React, { useState, useEffect } from "react";
import Header from "components/Headers/Header.js";
import { Card, Container, Row, CardFooter } from "reactstrap";
import './style.css';
import { PostData } from "../../../services/PostData";
import { loggedInUser } from "components/Common/Helpers.js";
import { useParams } from 'react-router';
import Overview from "views/modules/AnalysisComparison/Overview";
import Spinner from "components/Common/Spinner.js";

const AnalysisComparison = () => {
    const { report_id, amts_id } = useParams();
    const [isProcess, setProcess] = useState(true);
    const userData = loggedInUser();
    const [orderData, setOrderData] = useState(null);

    useEffect(() => {
        getReportData();
        //eslint-disable-next-line
    }, []);

    let getReportData = () => {
        let data = {
            report_id,
            amts_id,
            user_id: userData.user_id
        };
        if (process.env.REACT_APP_SERVER_ENVIRONMENT === "training") {
            data.server_type = "training";
        }
        setProcess(true);
        PostData("analysis-comparison/getValueCurve", data).then(result => {
            let responseJson = result.data.data;
            setOrderData(responseJson);
            setProcess(false);
        });
    }
    return (
        <>
            <Header />
            <Spinner isShow={isProcess} />
            <Container className="mt--9 container-vh-100" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <div className="analysis-compration mt-3">
                                <Overview onData={orderData} />
                            </div>
                            <CardFooter className="py-4">
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default AnalysisComparison;
