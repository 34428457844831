import axios from 'axios';
import { loggedInUser } from "../components/Common/Helpers.js"

const BaseUrl = process.env.REACT_APP_API_ENDPOINT;
const userData = loggedInUser();

export async function GetData(api, data) {
    return new Promise((resolve, reject) => {
        let params = new URLSearchParams();

        for (let key in data) {
            if (data[key]) {
                params.set(key, data[key]);
            }
        }

        axios({
            url: BaseUrl + api + (params ? "?" + params : ""),
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${userData.token}`
            }
        }).then(response => {
            resolve(response.data);
        }).catch(errors => {
            reject(errors.response.data);
            if (errors && errors.response && errors.response.status === 403) {
                window.location = "/auth/login";
            }
        })

    });
}

export function PostData(api, data) {
    return new Promise((resolve, reject) => {
        axios({
            url: BaseUrl + api,
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${userData.token}`
            }
        }).then(response => {
            resolve(response.data);
        }).catch(errors => {
            reject(errors.response.data);
            if (errors && errors.response && errors.response.status === 403) {
                window.location = "/auth/login";
            }
        })
    });
}

export function PutData(api, data) {
    return new Promise((resolve, reject) => {
        axios({
            url: BaseUrl + api,
            method: 'put',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${userData.token}`
            }
        }).then(response => {
            resolve(response.data);
        }).catch(errors => {
            reject(errors.response.data);
            if (errors && errors.response && errors.response.status === 403) {
                window.location = "/auth/login";
            }
        })
    });
}

export function DeleteData(api, data) {
    return new Promise((resolve, reject) => {
        let params = new URLSearchParams();

        for (let key in data) {
            if (data[key]) {
                params.set(key, data[key]);
            }
        }

        axios({
            url: BaseUrl + api + (params ? "?" + params : ""),
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${userData.token}`
            }
        }).then(response => {
            resolve(response.data);
        }).catch(errors => {
            reject(errors.response.data);
            if (errors && errors.response && errors.response.status === 403) {
                window.location = "/auth/login";
            }
        })
    });
}