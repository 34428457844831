import React, { useState, useEffect } from "react";
import OtherLinks from "./OtherLinks";
import Google from "./Google";
import { TASK_SLUG } from "../Constants/TimeTracking.constant";

import TabsComponent from "components/Common/TabsComponent";
import CarouselSlider from "components/CarouselSlider/CarouselSlider";
import { periodObservance } from 'components/Common/TimeTrackingHelpers';
import { loggedInUser } from "components/Common/Helpers.js"

const userData = loggedInUser();

const CompList = (props) => {
    let [data, setData] = useState([]);
    useEffect(() => {
        setData(props.props)
    }, [props]);

    let carouselSliderData = {
        image_urls: data.image_urls,
        public_remarks: data.public_remarks,
        displayImageSlider: true,
        propertyImgs: data.propertyImgs
    }
    let otherLinksData = {
        address: data.address,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        isFromSubjectPropertyCondition: data.isFromSubjectPropertyCondition,
        taskData: data.taskData,
        orderData: data.orderData,
    }
    let googleData = {
        contextMenu: data.contextMenu,
        google: data.google,
        zoom: data.zoom,
        mapStyles: data.mapStyles,
        latitude: data.latitude,
        longitude: data.longitude,
        onMarkerClick: data.onMarkerClick,
        markerName: data.markerName,
        infoWindowMarker: data.infoWindowMarker,
        infoWindowVisible: data.infoWindowVisible,
        infoWindowOnClose: data.infoWindowOnClose,
        selectedPlace: data.selectedPlace,
        isFromSubjectPropertyCondition: data.isFromSubjectPropertyCondition,
        orderData: data.orderData,
        taskData: data.taskData,
    }

    let googleTabClicked = async () => {
        await periodObservance({
            taskData: data.taskData,
            slug: TASK_SLUG.SUBJECT_CONDITION_GOOGLE,
            orderData: data.orderData,
            userData: userData,
            closingTask: 0,
            newTabCheck: 0
        })
    }

    return (
        <>
            {data.openModel &&
                <>
                    <div className="others-column-body">
                        <TabsComponent
                            customTabsStyle={data.customTabsStyle}
                            customTabListStyle={data.customTabListStyle}
                            tabs={['Listing', 'Google', 'Other Sites']}
                            tabPanel={[
                                <CarouselSlider propData={carouselSliderData} />,
                                <Google props={googleData} />,
                                <OtherLinks props={otherLinksData} />
                            ]}
                            onSubjectPropertyConditionGoogleTab={data.isFromSubjectPropertyCondition ? googleTabClicked : ''}
                        />
                    </div>
                </>
            }
        </>
    );
};

export default CompList;
