import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { PostData } from "../../services/PostData";
import Spinner from "components/Common/Spinner.js";
import { Button, Card, CardBody, FormGroup, Col } from "reactstrap";

const NewPassword = (props) => {

  const token = props.match.params.token;

  const [key] = useState(token);
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [isAllOk, seIsAllOk] = useState(true);
  const [isProcess, setProcess] = useState(false);
  let history = useHistory();

  // Handle Password Renew Request
  const handleNewPassword = () => {

    setProcess(true);
    let data = {
      token: key,
      newpassword: newPassword,
    };

    PostData("auth/new_password", { data: data }).then(result => {
      let responseJson = result.data;
      if (responseJson.status === 200) {
        setProcess(false);
        history.push("/auth/login");
        toast["success"](responseJson.message);
      } else {
        setProcess(false);
        toast["error"](responseJson.message);
      }
    }).catch(errors => {
      setProcess(false);
      history.push("/auth/login");
      toast["error"](errors);
    });
  };


  const confirmPasswordHandle = (e) => {
    let confirmNewPassword = e.target.value;
    setConfirmNewPassword(confirmNewPassword);
  }

  const newPasswordHandle = (e) => {
    let newPassword = e.target.value;
    setNewPassword(newPassword);
    seIsAllOk(false);
  }

  const check_token = () => {

    let data = {
      token: key,
    };

    PostData("auth/token_verification", { data: data }).then(result => {
      setProcess(true);
      let responseJson = result.data;
      if (responseJson.status === 200) {
        setProcess(false);
      } else {
        setProcess(false);
        history.push("/auth/login");
        toast["error"](responseJson.message);
      }
    }).catch(errors => {
      setProcess(false);
      history.push("/auth/login");
      toast["error"](errors.message);
    });
  }

  useEffect(() => {
    if (newPassword === confirmNewPassword) {
      seIsAllOk(true);
    } else {
      seIsAllOk(false);
    }

    check_token();

  }, [newPassword, confirmNewPassword, key]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Col lg="5" md="7">
        {/* Loader Spinner */}
        <Spinner isShow={isProcess} />

        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center mb-4">
              <b>Create New Password</b>
            </div>
            <AvForm onValidSubmit={handleNewPassword}>
              <AvField onChange={(e) => newPasswordHandle(e)} value={newPassword} placeholder="Please enter your new password here" autoComplete="off" name="newpassword" label="New Password" type="password"
                validate={{
                  required: { value: true, errorMessage: ('Please enter your new password') },
                  pattern: { value: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])', errorMessage: ('Your password must be composed with at-least with one upper and lower case letter, one number and one special character') },
                  minLength: { value: 9, errorMessage: ('Your new password must be between 9 and 255 characters') },
                  maxLength: { value: 255, errorMessage: ('Your new password must be between 9 and 255 characters') }
                }} />

              <AvField onChange={(e) => confirmPasswordHandle(e)} value={confirmNewPassword} placeholder="Please enter your confirm password here" autoComplete="off" name="confirmnewpassword" label="Confirm New Password" type="password"
                validate={{
                  required: { value: true, errorMessage: ('Please enter your confirm password') },
                  pattern: { value: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])', errorMessage: ('Your password must be composed with at-least with one upper and lower case letter, one number and one special character') },
                  minLength: { value: 9, errorMessage: ('Your new password must be between 9 and 255 characters') },
                  maxLength: { value: 255, errorMessage: ('Your new password must be between 9 and 255 characters') },
                  match: { value: 'newpassword' }
                }} />

              <div className="text-center">
                <FormGroup>
                  <Button className="" color="primary" disabled={!isAllOk}>
                    Create New Password
                  </Button>
                </FormGroup>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default NewPassword;