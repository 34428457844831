import React, { useState, useEffect } from "react";
import { formatPropertyImagesArray } from "components/Common/Helpers";
import ImageSlider from "../../views/modules/Common/Image-Slider/ImageSlider";
import './CarouselSlider.scss';


const CarouselSlider = ({ propData }) => {
    let isImageModal = React.useRef(false);
    let [data, setData] = useState([]);
    let [imageIndex, setImageIndex] = useState(0);
    let [propertyImagesForModal, setPropertyImagesForModal] = useState([]);

    useEffect(() => {
        setData(propData)
    }, [propData]);

    let openImageSlider = (index) => {
        let propertyImgs = data.image_urls;

        if (propertyImgs) {
            propertyImgs = formatPropertyImagesArray(propertyImgs);
            setPropertyImagesForModal(propertyImgs);
        }
        setImageIndex(index);
        isImageModal.current = data.displayImageSlider;
    }

    let carouselSlider = "";
    let propertyImgs;

    if (data.propertyImgs) {
        propertyImgs = formatPropertyImagesArray(data.propertyImgs);

        carouselSlider = (
            <>

                <div className="d-flex flex-row flex-wrap justify-content-center grid-image carousel-custom">
                    {propertyImgs.length > 0 ?
                        < >
                            {propertyImgs.map((imageUrl, index) =>
                                <div key={index} className="col-md-3 d-flex flex-column img-area">
                                    <img src={imageUrl} alt={"img_" + index} loading="lazy" height={150} padding={5} onClick={() => openImageSlider(index)} />
                                </div>
                            )}
                        </>
                        : <h2 className="not-authorized-msg">NO IMAGE DATA.</h2>}
                    <hr></hr>
                </div>
                {data.public_remarks ? <p className="text-left">{data.public_remarks}</p> : null}
            </>
        )
    } else {
        carouselSlider = (
            <>
                <h2 className="not-authorized-msg">NO IMAGE DATA.</h2>
                {data.public_remarks ? <p className="text-left thumbs-wrapper axis-vertical" style={{ overflowX: 'hidden', maxHeight: '150px' }}>{data.public_remarks}</p> : null}
            </>
        );
    }

    return (
        <>
            {carouselSlider}
            <ImageSlider propHandleModal={isImageModal} imageArray={propertyImagesForModal} selectedItem={imageIndex} />
        </>
    );
};

export default CarouselSlider;
