import { createSlice } from '@reduxjs/toolkit'

export const TrainingMVPReducer = createSlice({
    name: 'trainingMVP',
    initialState: {
        module: [],
        filterSelection: [],
        backToHome: [],
        remainingTime: 0,
        isTimeOver: null,
        allowAccess: true,
        allowUpdate: false,
    },
    reducers: {
        setModule: (state, action) => {
            state.module = action.payload;
        },
        setFilterSelection: (state, action) => {
            state.filterSelection = action.payload;
        },
        setBackToHome: (state, action) => {
            state.backToHome = action.payload;
        },
        setRemainingTime: (state, action) => {
            state.remainingTime = action.payload;
        },
        setIsTimeOver: (state, action) => {
            state.isTimeOver = action.payload;
        },
        setAllowAccess: (state, action) => {
            state.allowAccess = action.payload;
        },
        setAllowUpdate: (state, action) => {
            state.allowUpdate = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setModule, setFilterSelection, setBackToHome, setRemainingTime, setIsTimeOver, setAllowAccess, setAllowUpdate } = TrainingMVPReducer.actions

export default TrainingMVPReducer.reducer;