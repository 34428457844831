import { createSlice } from '@reduxjs/toolkit'

export const inspectionOrderListReducer = createSlice({
    name: 'inspectionOrderList',
    initialState: {
        counts: {
            payment: 0,
            active_payment: 0,
            dismissed_payment: 0,
            inprogress: 0,
            onHold: 0,
            completed: 0,
            inRevision: 0,
            cancelled: 0,
            payment_completed: 0,
        },
        currentTab: 'payments',
        currentTabIndex: 0,
        paymentSubTab: 'active_payment',
        paymentSubTabIndex: 0,
    },
    reducers: {
        setCount: (state, params) => {
            state.counts = params.payload.value;
        },
        setCurrentTab: (state, params) => {
            state.currentTab = params.payload.value;
        },
        setCurrentTabIndex: (state, params) => {
            state.currentTabIndex = params.payload.value;
        },
        setPaymentSubTab: (state, params) => {
            state.paymentSubTab = params.payload.value;
        },
        setPaymentTabIndex: (state, params) => {
            state.paymentSubTabIndex = params.payload.value;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCount, setCurrentTab, setCurrentTabIndex, setPaymentSubTab, setPaymentTabIndex } = inspectionOrderListReducer.actions

export default inspectionOrderListReducer.reducer