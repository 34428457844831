const PROPERTY_TYPES = [
    { name: "Single-Family Residential (SFR) - Detached", slug: "sfr", units: 1 },
    { name: "Townhouse/Rowhouse (Single-Family Attached)", slug: "townhouse", units: 1 },
    { name: "Condominium", slug: "condo", units: 1 },
    { name: "Duplex (Both Units - Single Ownership)", slug: "duplex", units: 2 },
    { name: "Triplex", slug: "triplex", units: 3 },
    { name: "Quadruplex", slug: "quadruplex", units: 4 },
    { name: "Mobile", slug: "mobile", units: 1 },
    { name: "Multi Family", slug: "mfr", units: null },
];

const CONDITION = {
    UNSALVAGE_MIN: 1,
    UNSALVAGE_MAX: 1.64,
    VERY_POOR_MIN: 1.65,
    VERY_POOR_MAX: 2.64,
    POOR_MIN: 2.65,
    POOR_MAX: 3.64,
    MODERATE_MIN: 3.65,
    MODERATE_MAX: 4.64,
    MAINTAINED_MIN: 4.65,
    MAINTAINED_MAX: 5.64,
    PARTIAL_REMODEL_MIN: 5.65,
    PARTIAL_REMODEL_MAX: 6.64,
    FULLY_REMODEL_MIN: 6.65,
    FULLY_REMODEL_MAX: 7.49,
    NEW_BUILT_MIN: 7.5,
    NEW_BUILT_MAX: 8
};

const PROP_CONDITION_MAP_CONTROLS = {
    'condition_1': {
        home: require('../../../../assets/img/conditions/unsalvageable-grey.png').default
    },
    'condition_2': {
        home: require('../../../../assets/img/conditions/very-poor.png').default
    },
    'condition_3': {
        home: require('../../../../assets/img/conditions/poor.png').default
    },
    'condition_4': {
        home: require('../../../../assets/img/conditions/moderate.png').default
    },
    'condition_5': {
        home: require('../../../../assets/img/conditions/maintained.png').default
    },
    'condition_6': {
        home: require('../../../../assets/img/conditions/partial-remodel.png').default
    },
    'condition_7': {
        home: require('../../../../assets/img/conditions/full-remodel.png').default
    },
    'condition_8': {
        home: require('../../../../assets/img/conditions/new-build-purple.png').default
    },
};

const CLUSTER_MAP_CONTROLS = {
    'cluster_n/a': {
        home: require('../../../../assets/img/cluster-houses/cluster_na.png').default
    },
    'cluster_0': {
        home: require('../../../../assets/img/cluster-houses/cluster_0.png').default
    },
    'cluster_1': {
        home: require('../../../../assets/img/cluster-houses/cluster_1.png').default
    },
    'cluster_2': {
        home: require('../../../../assets/img/cluster-houses/cluster_2.png').default
    },
    'cluster_3': {
        home: require('../../../../assets/img/cluster-houses/cluster_3.png').default
    },
    'cluster_4': {
        home: require('../../../../assets/img/cluster-houses/cluster_4.png').default
    },
    'cluster_5': {
        home: require('../../../../assets/img/cluster-houses/cluster_5.png').default
    },
    'cluster_6': {
        home: require('../../../../assets/img/cluster-houses/cluster_6.png').default
    }
};

module.exports = {
    PROPERTY_TYPES,
    CONDITION,
    PROP_CONDITION_MAP_CONTROLS,
    CLUSTER_MAP_CONTROLS
};