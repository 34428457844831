/* ---------------------------------------COMPONENT INSTRUCTION---------------------------------------
--Import Component--
import MakeAdjustmentsModal from "../Common/MakeAdjustmentsModal";
--------------------
-- Refresh listing table 
 let onAdjustmentMade = () => {
    //call the appropriate function
};
--------------------
--Include Component--
<MakeAdjustmentsModal isOpenModal={isOpenAdjustmentsModal} adjustmentData={adjustmentData} onAdjustment={onAdjustmentMade} />

------------------------------------------------------------------------------------------------------*/

import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Label } from "reactstrap";
import "./style.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as moment from "moment";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { loggedInUser } from "components/Common/Helpers.js"
import { PostData } from "../../../services/Api.service";


const MakeAdjustmentsModal = ({ isOpenModal, adjustmentData, onAdjustment }) => {
    const userData = loggedInUser();
    let [isProcess, setProcess] = useState(false);
    let [isOpenAdjustmentsModal, setOpenAdjustmentsModal] = useState(false);
    let [update, setUpdate] = useState(moment());

    let [rowData, setRowData] = useState('');
    let [adjustmentsAmount, setAdjustmentsAmount] = useState('');

    useEffect(() => {
        if (isOpenModal.current) {
            setOpenAdjustmentsModal(isOpenModal.current);
        }
        setRowData(adjustmentData)
    }, [isOpenModal.current, adjustmentData]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]);

    let handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenAdjustmentsModal(state);
            isOpenModal.current = false;
            setRowData(adjustmentData)
            setUpdate(moment());
        }
    };

    let onHandleChange = (e) => {
        let value = e.target.value;
        setAdjustmentsAmount(value);
        setUpdate(moment());
    }

    let handleSubmit = (e) => {

        confirmAlert({
            title: '',
            message: "Would you like to make the adjustments, Are you sure?",
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => makeAdjustments(),
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-outline-danger btn-sm"
                }
            ]
        });
        setUpdate(moment());
    }

    let makeAdjustments = async () => {
        let status = rowData.report_payment_status;

        let data = {
            report_payment_id: (status === 'Pending Invoice' || status === 'Pending Refund' ? rowData.report_payment_id : rowData.report_payment_id),
            adjustments_amount: parseFloat(adjustmentsAmount),
            report_payment_ids: (status === 'Pending Invoice' || status === 'Pending Refund' ? rowData.report_payment_ids : rowData.report_payment_ids),
            report_payment_status: rowData.report_payment_status,
            user_email: userData.email,
            user_id: userData.user_id
        };

        setProcess(true);
        try {
            let result = await PostData("accounting/save-invoice-adjustments", data);
            toast['success'](result.message);
            handleToggleModal(isOpenAdjustmentsModal)
            setProcess(false);
            onAdjustment();
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            setProcess(false);
        };
        setUpdate(moment());
    }

    return (
        <>
            <Modal isOpen={isOpenAdjustmentsModal} className="modal-dialog-centered" toggle={() => handleToggleModal(isOpenModal.current)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title w-100 text-center">
                        Make Adjustment
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenAdjustmentsModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleSubmit(e)}>
                        <Row>
                            <Col className="col-md-12">
                                <Label className="required"><b>Amount</b></Label>
                                <AvField value={adjustmentsAmount} name="trip_charge" placeholder="Please enter adjustments amount" onChange={e => onHandleChange(e)}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "This field is required."
                                        },
                                        number: {
                                            value: true,
                                            errorMessage: "Enter only number value."
                                        },
                                        pattern: { value: /^[0-9-]\d*(\.\d{1,2})?%*?$/, errorMessage: "Valid amount includes 0-9, -(minus) , .(dot) and 2 digit after the dot, EX: 123, -10, 20.25, -70.45]" },
                                        maxLength: {
                                            value: 12,
                                            errorMessage: 'Max length is 12'
                                        }
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col md="12" className="text-right">
                                {isProcess ?
                                    <Button type="button" color="warning" >
                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                    </Button>
                                    : <>
                                        <Button color="danger" outline className="w-30 " onClick={() => { handleToggleModal(isOpenAdjustmentsModal); }} >
                                            Cancel
                                        </Button>
                                        <Button color="info" outline className="w-30">
                                            Save
                                        </Button>
                                    </>
                                }
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
        </>
    );
};

export default MakeAdjustmentsModal;